import {
  List,
  TextField,
  Pagination,
  DateField,
  ReferenceField,
  SelectField,
  useTranslate,
  useGetIdentity,
} from 'react-admin';
import {
  Datagrid,
  ListCreator,
  CreateButton,
  ListToolbar,
  Empty,
  BoxStatus,
} from '../../components/list';
import Filter from '../../components/filter';
import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions';
import UrlField from '../../components/common/UrlField';
import checkPermission from '../../utils/checkPermission';
import CustomerList from './CustomerList';
import { useMediaQuery } from '@material-ui/core';

const LicenseTopActions = [<CreateButton key={1} />];

const LicenseList = (props) => {
  const translate = useTranslate();
  const { identity } = useGetIdentity();
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const root = 'resources.licenses.fields';
  const role = identity?.role;
  const isAdmin = role === 'Admin' || role === 'Super Admin';

  return (
    <List
      actions={false}
      pagination={
        <Pagination actions={TablePaginationActions} rowsPerPageOptions={[]} />
      }
      empty={
        <Empty
          actions={
            checkPermission({
              method: 'POST',
              url: 'licenses',
              permissions: JSON.parse(localStorage.getItem('permissions')),
            })
              ? LicenseTopActions
              : []
          }
          customTexts={
            isAdmin
              ? {}
              : {
                  title: translate('resources.licenses.noAvailable'),
                  message: '',
                }
          }
        />
      }
      {...props}
    >
      <>
        <ListCreator
          actions={
            checkPermission({
              method: 'POST',
              url: 'licenses',
              permissions: JSON.parse(localStorage.getItem('permissions')),
            })
              ? LicenseTopActions
              : []
          }
        />
        {!isXSmall ? (
          <>
            <ListToolbar
              actions={
                <Filter
                  filters={[
                    {
                      label: translate(root + '.status'),
                      source: 'status',
                      type: 'select',
                      choices: [
                        {
                          id: 'ACTIVE',
                          label: translate('choices.active'),
                        },
                        {
                          id: 'PENDING',
                          label: translate('choices.pending'),
                        },
                        {
                          id: 'EXPIRED',
                          label: translate('choices.expired'),
                        },
                      ],
                    },
                    {
                      label: translate(root + '.clientId'),
                      source: 'clientId',
                      type: 'reference',
                      reference: 'licenses',
                    },
                    {
                      label: translate(root + '.validFrom'),
                      source: 'validFrom__gte',
                      type: 'date',
                    },
                    {
                      label: translate(root + '.validUntil'),
                      source: 'validUntil__lte',
                      type: 'date',
                    },
                  ]}
                />
              }
            />
            <Datagrid>
              {isAdmin || !isXSmall ? (
                <UrlField source="id" urlPrefix="licenses" />
              ) : (
                <TextField source="id" />
              )}
              <ReferenceField
                source="clientId"
                reference="clients"
                link={isAdmin ? 'show' : false}
              >
                <TextField source="companyName" />
              </ReferenceField>
              <TextField source="lockCapacity" />
              <DateField source="validFrom" />
              <DateField source="validUntil" />
              <SelectField
                source="status"
                choices={[
                  { id: 'ACTIVE', name: translate('choices.active') },
                  {
                    id: 'EXPIRED',
                    name: translate('choices.expired'),
                    variant: 'negative',
                  },
                  {
                    id: 'PENDING',
                    name: translate('choices.pending'),
                    variant: 'warning',
                  },
                ]}
                optionText={<BoxStatus />}
              />
              <UrlField
                source="id"
                label=""
                sortable={false}
                transformTo={(record) => ({
                  pathname: 'devices',
                  search: `filter=%7B"LicenseId"%3A${record.id}%7D`,
                })}
                text={translate(root + '.viewDevices')}
                isButton
              />
            </Datagrid>
          </>
        ) : (
          <CustomerList />
        )}
      </>
    </List>
  );
};

export default LicenseList;
