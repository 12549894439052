import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import {
  List,
  MenuItem,
  ListItemIcon,
  Typography,
  Collapse,
  Tooltip,
  Divider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import classnames from 'classnames';

const useStyles = makeStyles((theme) => ({
  icon: {
    minWidth: theme.spacing(5),
    color: 'inherit',
  },
  expand: {
    position: 'absolute',
    right: 0,
  },
  divider: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    color: 'red',
    ...theme.overrides.RaMenuItemLink.root,
    height: 1,
    '&:hover': {},
  },
  menuItem: theme.overrides.RaMenuItemLink.root,
  active: {
    ...theme.overrides.RaMenuItemLink.active,
    backgroundColor: theme.palette.action.selected,
  },
}));

const SubMenu = ({ handleToggle, isOpen, name, icon, children, dense }) => {
  const classes = useStyles();
  const sidebarIsOpen = useSelector((state) => state.admin.ui.sidebarOpen);

  const className = isOpen
    ? classnames(classes.menuItem, classes.active)
    : classes.menuItem;

  const header = (
    <MenuItem dense={dense} button className={className} onClick={handleToggle}>
      <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
      {sidebarIsOpen && <Typography variant="body1">{name}</Typography>}
      {sidebarIsOpen && (
        <ListItemIcon className={classnames(classes.icon, classes.expand)}>
          {isOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemIcon>
      )}
    </MenuItem>
  );

  return (
    <Fragment>
      {sidebarIsOpen ? (
        header
      ) : (
        <Tooltip title={name} placement="right">
          {header}
        </Tooltip>
      )}
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List dense={dense} component="div" disablePadding>
          {children}
        </List>
        <Divider className={classes.divider} />
      </Collapse>
    </Fragment>
  );
};

export default SubMenu;
