import Switch from '@mui/material/Switch';

const BooleanFilter = ({ filter, onFilter }) => (
  <div style={{ display: 'flex', alignItems: 'center', marginRight: '12px' }}>
    <Switch onChange={(e) => onFilter(filter.source, e.target.checked)} />{' '}
    {filter.label}
  </div>
);

export default BooleanFilter;
