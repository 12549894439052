import { Button } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/GetApp';

export default function DownloadButton({ type, url, ...props }) {
  const download = async () => {
    try {
      const result = await fetch(url, {
        method: 'GET',
      });
      const data = await result.blob();
      const element = document.createElement('a');
      element.href = URL.createObjectURL(data);
      console.log(data);
      element.download = type + '.' + data.type.split('/')[1];
      document.body.appendChild(element);
      element.click();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Button color="primary" onClick={download} {...props}>
      <DownloadIcon /> {' ' + type}
    </Button>
  );
}
