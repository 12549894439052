import { Typography, Toolbar, Box } from '@material-ui/core';
import {
  useListContext,
  CreateContextProvider,
  useCreateController,
} from 'ra-core';
import { cloneElement } from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    backgroundColor: theme.palette.background.default,
    padding: 0,
    paddingBottom: 20,
    justifyContent: 'space-between',
  },
  box: {
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}));

export default function ListCreator({
  subMenu,
  actions = [],
  title = '',
  toolbarProps = {},
}) {
  const listContext = useListContext();
  const controllerProps = useCreateController(listContext);
  const classes = useStyles();
  return (
    <Toolbar className={classes.toolbar} {...toolbarProps}>
      <Box>
        <Typography variant="subtitle2">{subMenu}</Typography>
        <Typography variant="h5">
          {title === '' ? listContext.defaultTitle : title}
        </Typography>
      </Box>
      <Box className={classes.box}>
        <CreateContextProvider value={controllerProps}>
          {actions.map((filter, i) => cloneElement(filter, { key: i }))}
        </CreateContextProvider>
      </Box>
    </Toolbar>
  );
}
