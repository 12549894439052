import { useRedirect } from 'ra-core';
import React from 'react';
import { useTranslate } from 'react-admin';
import { CircularProgress } from '@material-ui/core';

// eslint-disable-next-line react/display-name
const InvisibleSaveButton = React.forwardRef((props, ref) => {
  const {
    basePath,
    redirect,
    onCancel,
    label,
    handleSubmitWithRedirect,
    saving,
    isSaving,
    ...rest
  } = props;
  const translate = useTranslate();
  const redirectTo = useRedirect();
  const handleClick = (e) => {
    handleSubmitWithRedirect?.();
    e.preventDefault();
    redirect && redirectTo(basePath);
    onCancel && onCancel();
  };

  return (
    <span
      /* eslint-disable-next-line */
      variant="contained"
      color="primary"
      onClick={handleClick}
      /* eslint-disable-next-line */
      disableElevation
      title={label || translate('buttons.save')}
      ref={ref}
      {...rest}
    >
      {(saving || isSaving) && <CircularProgress />}
    </span>
  );
});

export default InvisibleSaveButton;
