import { useEffect, useRef, useState } from 'react';
import {
  email,
  required,
  SimpleForm,
  TextInput,
  useAuthProvider,
  useTranslate,
  Toolbar,
  useFormContext,
} from 'react-admin';
import customTheme from '../../themes/customTheme';
import CustomAppBar from './CustomAppbar';
import { ThemeProvider, Box, Button } from '@material-ui/core';
import { CancelButton, InputContainer, TitleViews } from '../common';

export default function UserProfile(props) {
  return (
    <ThemeProvider theme={customTheme}>
      <CustomAppBar />
      <Profile {...props} id={1} basePath="myLicenses" />
    </ThemeProvider>
  );
}

function Profile(props) {
  const handleSubmitFunction = useRef(null);
  const [user, setUser] = useState({});
  const { me, updateProfile } = useAuthProvider();
  const data = useFormContext();
  useEffect(() => {
    if (typeof me === 'function') {
      me().then((user) => {
        setUser(user);
      });
    }
  }, []);
  const Component = (props) => {
    const { handleSubmitWithRedirect, handleSubmit } = props;
    handleSubmitFunction.current = handleSubmitWithRedirect || handleSubmit;
    return <></>;
  };

  const save = (values) => {
    console.log(data);
    updateProfile(values);
  };
  const translate = useTranslate();
  return (
    <SimpleForm
      variant="outlined"
      record={user}
      handleSubmit={save}
      handleSubmitWithRedirect={save}
      toolbar={<Component />}
      save={save}
      style={{ padding: 10 }}
      {...props}
    >
      <TitleViews
        title={translate('profile.edit')}
        subTitle={translate('profile.subtitle')}
        resource="profile"
        withoutBreadcrumb
      />
      <Box
        style={{
          padding: '10px',
          background: 'white',
          borderRadius: '5px',
          boxSizing: 'border-box',
        }}
      >
        <InputContainer columns={3}>
          <TextInput source="firstName" validate={[required()]} />
          <TextInput source="lastName" validate={[required()]} />
          <TextInput source="companyName" validate={[required()]} />
          <TextInput source="country" validate={[required()]} />
          <TextInput source="state" validate={[required()]} />
          <TextInput source="city" validate={[required()]} />
          <TextInput source="address" validate={[required()]} />
          <TextInput source="zipcode" validate={[required()]} />
          <TextInput source="email" validate={[required(), email()]} />
        </InputContainer>
        <Toolbar
          style={{ background: 'white', padding: 0, gap: 15, marginTop: 0 }}
        >
          <CancelButton redirect basePath="myLicenses" />
          <Button
            variant="contained"
            disableElevation
            color="secondary"
            href="/#/change-password"
            title={translate('buttons.changePassword')}
          >
            {translate('buttons.changePassword')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              handleSubmitFunction?.current?.();
            }}
            title={translate('buttons.save')}
          >
            {translate('buttons.save')}
          </Button>
        </Toolbar>
      </Box>
    </SimpleForm>
  );
}
