import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
import enLocaleTranslations from '../locale/enLocale';
const messages = {
  EN: { ...englishMessages, ...enLocaleTranslations },
};

const i18nProvider = polyglotI18nProvider((locale) => {
  if (messages[locale]) {
    return messages[locale];
  }
  return messages.EN;
}, 'EN');

export default i18nProvider;
