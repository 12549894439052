import { usePagination } from './usePagination';
import './pagination.scss';

import Grid from '@mui/material/Grid';

import { Button } from '@material-ui/core';
const Pagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <Button
          variant="contained"
          inline="false"
          disableElevation
          color="secondary"
          disabled={currentPage === 1 ? true : false}
          onClick={onPrevious}
        >
          Previus
        </Button>
      </Grid>
      <Grid item xs={5}>
        <Button
          variant="contained"
          inline="false"
          disableElevation
          color="secondary"
          disabled={currentPage === lastPage ? true : false}
          onClick={onNext}
        >
          Next
        </Button>
      </Grid>
    </Grid>
  );
};

export default Pagination;
