import React from 'react';
import { Box, useTheme } from '@material-ui/core';

export default function FormBox({
  children,
  transparent = false,
  style,
  className = '',
  ...props
}) {
  const theme = useTheme();
  const childrenWithProps = React.Children.map(children, (child) => {
    const { className: childClassName, ...childProps } = child.props;
    const childClass = (childClassName || '') + ' ' + className;
    const needsProps =
      !!childProps.source || !!child.key?.startsWith('needsProps');
    const newProps = needsProps
      ? { ...props, ...childProps, className: childClass }
      : {
          ...childProps,
          className: childClass,
        };
    if (React.isValidElement(child)) {
      return React.cloneElement(child, newProps);
    }
    return child;
  });

  return (
    <Box
      style={{
        backgroundColor: transparent
          ? 'transparent'
          : theme.palette.black.lighten[0] + '80',
        padding: '1rem',
        borderRadius: '4px',
        ...style,
      }}
      className={'fullWidth fillAvailable' + className}
    >
      {childrenWithProps}
    </Box>
  );
}
