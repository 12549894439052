import { useState } from 'react';
import {
  Create,
  SelectInput,
  TextInput,
  maxLength,
  required,
  useNotify,
  useRedirect,
  useRefresh,
  useTranslate,
} from 'react-admin';
import ApiKeyDialog from './ApiKeyDialog';
import { FormContainer } from '../../components/common';
import TitleViews from '../../components/common/TitleViews';
import { CreateForm } from '../../components/create';
import { httpClient } from '../../providers/dataProvider';

const ApiKeyCreate = (props) => {
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSave = async (payload) => {
    try {
      const response = await httpClient(
        `${process.env.REACT_APP_API}/apikeys`,
        {
          method: 'POST',
          body: JSON.stringify(payload),
        }
      );
      setData(response.json);
      setOpen(true);
    } catch (error) {
      const message =
        error.status === 400
          ? translate('resources.apikeys.error.exist')
          : error.message ?? translate('resources.apikeys.error.create');
      notify(message, { type: 'warning' });
    }
  };

  const handleSave = () => {
    notify('ra.notification.created');
    redirect(`/apikeys/${data.id}`, props.basePath, data.id);
    refresh();
  };

  return (
    <>
      <TitleViews
        title={`${translate('labels.create')} ${translate(
          'resources.apikeys.title'
        )}`}
        subsectionTitle={translate('resources.apikeys.breadcrumb.createTitle')}
      />
      <Create component={FormContainer} {...props}>
        <CreateForm toolbarProps={{ onSave }}>
          <TextInput
            source="name"
            label={translate('resources.apikeys.fields.name')}
            variant="outlined"
            resettable
            validate={[required(), maxLength(255)]}
          />

          <SelectInput
            source="roleId"
            choices={[
              { id: 1, name: 'choices.admin' },
              { id: 2, name: 'choices.superAdmin' },
              { id: 3, name: 'choices.customer' },
              { id: 4, name: 'choices.surveyContractor' },
              { id: 5, name: 'choices.connectWise' },
            ]}
            validate={[required()]}
          />

          <ApiKeyDialog
            apiKey={data.apiKey}
            onContinue={handleSave}
            open={open}
            setOpen={setOpen}
          />
        </CreateForm>
      </Create>
    </>
  );
};

export default ApiKeyCreate;
