const RaFileInput = () => ({
  dropZone: {
    background: 'none !important',
    flex: 1,
    height: '100%',
    boxSizing: 'border-box',
  },
  root: {
    '& > div': {
      padding: 0,
      width: '99%',
      border: '#ABABAB 1px dashed',
      borderRadius: 4,
      display: 'flex',
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
      overflow: 'hidden',
      alignItems: 'center',
    },
  },
  removeButton: {
    '& > div': {
      display: 'inline-block',
    },
  },
});

export default RaFileInput;
