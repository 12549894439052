import {
  DateInput,
  FormDataConsumer,
  ResourceContextProvider,
  useTranslate,
  WithPermissions,
} from 'react-admin';
import customTheme from '../../themes/customTheme';
import CustomAppBar from './CustomAppbar';
import { ThemeProvider, Divider, Typography, Box } from '@material-ui/core';
import { CustomEdit, EditForm } from '../edit';
import DownloadButton from './DownloadButton';

export default function ViewLicense(routeProps) {
  return (
    <ResourceContextProvider value="licenses">
      <WithPermissions
        component={ShowLicenseWithLayout}
        basePath="/myLicenses"
        id={+routeProps.match.params.id}
        resource="licenses"
      />
    </ResourceContextProvider>
  );
}

function ShowLicenseWithLayout(props) {
  return (
    <ThemeProvider theme={customTheme}>
      <CustomAppBar />
      <ShowLicense {...props} />
    </ThemeProvider>
  );
}

function ShowLicense(props) {
  const translate = useTranslate();
  return (
    <CustomEdit {...props}>
      <EditForm
        toolbarProps={{
          saveButton: <></>,
          withoutDelete: true,
          cancelButtonProps: {
            label: translate('buttons.back'),
            color: 'primary',
          },
        }}
        titleProps={{
          title: translate('resources.licenses.viewLicense'),
          subsectionTitle: translate('resources.licenses.viewLicense'),
          redirect: 'myLicenses',
        }}
      >
        <DateInput
          source="validFrom"
          label={translate('resources.licenses.fields.validFrom')}
          disabled
        />
        <DateInput
          source="validUntil"
          label={translate('resources.licenses.fields.validUntil')}
          disabled
        />
        <Divider className="fullWidth" />
        <Typography
          variant="body2"
          gutterBottom
          style={{ fontWeight: 600, padding: '1rem' }}
          className="fullWidth"
        >
          {translate('resources.licenses.fields.download')}
        </Typography>
        <Box className="fullWidth">
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              Object.keys(formData.files || {}).map((fileName) => (
                <DownloadButton
                  key={fileName}
                  type={fileName}
                  url={formData.files[fileName]}
                  {...rest}
                />
              ))
            }
          </FormDataConsumer>
        </Box>
      </EditForm>
    </CustomEdit>
  );
}
