import defaultMUITheme from '@material-ui/core/styles/defaultTheme';
const RaLayout = () => ({
  content: {
    padding: defaultMUITheme.spacing(2),
    [defaultMUITheme.breakpoints.up('xs')]: {
      marginTop: defaultMUITheme.spacing(6),
      paddingLeft: defaultMUITheme.spacing(2),
    },
    [defaultMUITheme.breakpoints.down('xs')]: {
      marginTop: defaultMUITheme.spacing(7),
      maxWidth: '90vw',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  appFrame: {
    [defaultMUITheme.breakpoints.up('xs')]: {
      marginTop: defaultMUITheme.spacing(0),
    },
    [defaultMUITheme.breakpoints.down('xs')]: {
      marginTop: defaultMUITheme.spacing(0),
    },
  },
});

export default RaLayout;
