import SurveyCreate from './create';
import SurveyList from './list';
import SurveyEdit from './edit';

const surveyResource = {
  list: SurveyList,
  create: SurveyCreate,
  edit: SurveyEdit,
};

export default surveyResource;
