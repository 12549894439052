import { Route } from 'react-router-dom';
import { RouteWithoutLayout } from 'react-admin';
import Register from './components/licensesLogin/register';
import ResetPasswordRequest from './components/licensesLogin/ResetPasswordRequest';
import ResetPassword from './components/licensesLogin/ResetPassword';
import CreatePassword from './components/licensesLogin/CreatePassword';
import LoginPage from './components/licensesLogin';
import adminuserResource from './resources/adminusers';
import MyLicenses from './components/customerSide/MyLicenses';
import ViewLicense from './components/customerSide/ViewLicense';
import UserProfile from './components/customerSide/UserProfile';
import ChangePassword from './components/customerSide/ChangePassword';
import FillSurvey from './components/survey/FillSurvey';

const customRoutes = [
  // eslint-disable-next-line react/jsx-key
  <Route
    exact
    path="/users/resetPassword/:id"
    component={adminuserResource.resetPassword}
  />,
  // eslint-disable-next-line react/jsx-key
  <RouteWithoutLayout path="/customerLogin" component={LoginPage} />,
  // eslint-disable-next-line react/jsx-key
  <RouteWithoutLayout exact path="/register" component={Register} />,
  // eslint-disable-next-line react/jsx-key
  <RouteWithoutLayout
    path="/resetPasswordRequest"
    component={ResetPasswordRequest}
  />,
  // eslint-disable-next-line react/jsx-key
  <RouteWithoutLayout path="/resetPassword/:code" component={ResetPassword} />,
  // eslint-disable-next-line react/jsx-key
  <RouteWithoutLayout
    path="/createPassword/:code"
    component={CreatePassword}
  />,
  // eslint-disable-next-line react/jsx-key
  <RouteWithoutLayout path="/myLicenses/:id" component={ViewLicense} />,
  // eslint-disable-next-line react/jsx-key
  <RouteWithoutLayout path="/myLicenses" component={MyLicenses} />,
  // eslint-disable-next-line react/jsx-key
  <RouteWithoutLayout path="/profile" component={UserProfile} />,
  // eslint-disable-next-line react/jsx-key
  <RouteWithoutLayout path="/change-password" component={ChangePassword} />,
  // eslint-disable-next-line react/jsx-key
  <Route path="/surveys/fill/:id" component={FillSurvey} />,
];

export default customRoutes;
