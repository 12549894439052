import { makeStyles } from '@material-ui/core/styles';
import { ListCreator } from '.';
import EmptyWidget from './EmptyWidget';

export default function Empty({
  actions = [],
  subMenu,
  title,
  customTexts,
  ...props
}) {
  const classes = useStyles(props);
  const emptyAllowedActions = actions.filter(
    (a) => a?.type.name !== 'ExportButton'
  );
  return (
    <div className={classes.container}>
      <ListCreator
        subMenu={subMenu}
        actions={emptyAllowedActions}
        title={title}
      />
      <EmptyWidget
        emptyClassName={classes.icon}
        showDescription
        customTexts={customTexts}
      />
    </div>
  );
}

const useStyles = makeStyles(
  ({ breakpoints }) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: '90%',
      [breakpoints.up('sm')]: {
        margin: '0 1em',
      },
    },
  }),
  { name: 'RaEmpty' }
);
