const MuiSwitch = (palette) => ({
  root: {
    width: 43,
    height: 24,
    padding: 0,
    margin: 8,
  },

  colorPrimary: {
    padding: 2,
    '&$checked': {
      transform: 'translateX(18px)',
      color: palette.primary.main,
      '& + $track': {
        backgroundColor: palette.primary.lighten[2],
        opacity: 1,
      },
    },
    '&$disabled': {
      color: palette.primary.contrastText,
      '& + $track': {
        backgroundColor: `${palette.black.lighten[1]} !important`,
        opacity: 1,
      },
    },
  },
  thumb: {
    width: 20,
    height: 20,
    boxShadow: 'none',
  },
  track: {
    borderRadius: 24 / 2,
    backgroundColor: palette.secondary.lighten[1],
    opacity: 1,
  },
});

export default MuiSwitch;
