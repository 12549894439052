import {
  List,
  ListItem,
  Box,
  Divider,
  Typography,
  IconButton,
} from '@material-ui/core';
import { useListContext, useTranslate } from 'react-admin';
import { BoxStatus } from '../../components/list';
import { Link } from 'react-admin';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';

const ResponsiveList = (props) => {
  const translate = useTranslate();
  const { data, ids, loaded } = useListContext(props);
  const variants = {
    CREATED: {
      id: 'CREATED',
      name: translate('choices.created'),
      variant: 'warning',
    },
    COMPLETED: {
      id: 'COMPLETED',
      name: translate('choices.completed'),
    },
    INPROGRESS: {
      id: 'INPROGRESS',
      name: translate('choices.inProgress'),
      variant: 'info1',
    },
  };
  if (!loaded) {
    return null;
  }
  return (
    <List>
      {ids.map((id) => {
        const record = data[id];
        return (
          <>
            <ListItem
              key={id}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                  gap: '.5em',
                }}
              >
                <Typography
                  style={{ display: 'inline-block', fontWeight: 700 }}
                >
                  {record?.Location?.name}
                </Typography>
                {
                  <Typography
                    style={{
                      fontSize: '0.9em',
                    }}
                  >
                    {record?.Location?.Client?.name}
                  </Typography>
                }
                <Typography
                  style={{ display: 'inline-block', fontWeight: 700 }}
                >
                  {record.progress} % {translate('choices.completed')}
                </Typography>
                <BoxStatus record={variants[record.status]} />
              </Box>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  gap: '1em',
                  color: 'black',
                }}
              >
                <IconButton
                  component={Link}
                  to={`/surveys/${record.id}`}
                  size="medium"
                >
                  <VisibilityOutlinedIcon
                    color="black"
                    style={{ color: 'black' }}
                  />
                </IconButton>
                <IconButton
                  component={Link}
                  to={`/surveys/fill/${record.id}`}
                  size="medium"
                >
                  <CreateOutlinedIcon
                    color="black"
                    style={{ color: 'black' }}
                  />
                </IconButton>
              </Box>
            </ListItem>
            <Divider />
          </>
        );
      })}
    </List>
  );
};

export default ResponsiveList;
