import { Typography } from '@material-ui/core';
import {
  ArrayInput,
  DateInput,
  FormDataConsumer,
  ReferenceInput,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  useTranslate,
} from 'react-admin';
import { FormBox, InputContainer } from '../../components/common';
import { CustomEdit, EditForm } from '../../components/edit';
import { Divider } from '../../components/list';

const ClientEdit = (props) => {
  const translate = useTranslate();

  return (
    <>
      <CustomEdit mutationMode="pessimistic" {...props}>
        <EditForm
          variant="outlined"
          ressetable
          toolbarProps={{
            saveButton: <></>,
            withoutDelete: true,
            cancelButtonProps: {
              label: translate('buttons.back'),
              color: 'primary',
            },
          }}
          titleProps={{
            subsectionTitle: translate(
              'resources.devices.breadcrumb.viewTitle'
            ),
            title: translate('resources.devices.breadcrumb.viewTitle'),
          }}
        >
          <TextInput source="name" inputProps={{ readOnly: true }} />

          <DateInput
            source="dateFirstActivation"
            inputProps={{ readOnly: true }}
          />
          <TextInput source="hardwareVersion" inputProps={{ readOnly: true }} />
          <TextInput source="softwareVersion" inputProps={{ readOnly: true }} />
          <Divider className="fullWidth" />
          <ReferenceInput source="LicenseId" reference="licenses">
            <SelectInput optionText="id" inputProps={{ readOnly: true }} />
          </ReferenceInput>
          <ReferenceInput
            source="LicenseId"
            reference="licenses"
            label={translate('resources.devices.fields.clientId')}
          >
            <ReferenceInput source="clientId" reference="clients">
              <SelectInput optionText="name" inputProps={{ readOnly: true }} />
            </ReferenceInput>
          </ReferenceInput>
          <FormBox>
            <Typography
              variant="body1"
              style={{
                fontWeight: 500,
                lineHeight: '24px',
              }}
            >
              {translate('resources.devices.fields.InstalationDetails')}
            </Typography>
            <Divider type="formBox" />
            <ArrayInput source="InstalationDetails" label="">
              <SimpleFormIterator
                disableRemove
                disableAdd
                className="instalationDetail"
              >
                <FormDataConsumer>
                  {({ getSource, scopedFormData }) => (
                    <>
                      <InputContainer>
                        <TextInput
                          source={getSource('location')}
                          record={scopedFormData}
                          label={translate('resources.devices.fields.location')}
                          inputProps={{ readOnly: 'true' }}
                        />
                        <TextInput
                          source={getSource('name')}
                          record={scopedFormData}
                          label={translate(
                            'resources.devices.fields.instalationName'
                          )}
                          inputProps={{ readOnly: true }}
                        />
                      </InputContainer>
                      <InputContainer>
                        <DateInput
                          source={getSource('dateActivation')}
                          record={scopedFormData}
                          label={translate(
                            'resources.devices.fields.dateActivation'
                          )}
                          inputProps={{ readOnly: true }}
                        />
                        <SelectInput
                          source={getSource('type')}
                          record={scopedFormData}
                          label={translate('resources.devices.fields.type')}
                          inputProps={{ readOnly: true }}
                          choices={[
                            {
                              id: 'INSTALLATION',
                              name: translate('choices.installation'),
                            },
                            {
                              id: 'UNINSTALLATION',
                              name: translate('choices.uninstallation'),
                            },
                          ]}
                        />
                      </InputContainer>
                    </>
                  )}
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>
          </FormBox>
        </EditForm>
      </CustomEdit>
    </>
  );
};

export default ClientEdit;
