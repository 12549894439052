import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLogin, useNotify, useSetLocale, useTranslate } from 'react-admin';

import { TextField as MuiTextField, useTheme } from '@material-ui/core';
import { Typography, Link, Divider } from '@mui/material';
import {
  Box,
  Button,
  InputAdornment,
  IconButton,
  ThemeProvider,
  styled,
  useMediaQuery,
} from '@material-ui/core';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import Notification from '../layout/notification';

import { useStyles } from './styles';
import { LogoWithTitle } from '../common';
import customTheme from '../../themes/customTheme';

const TextField = styled(MuiTextField)(({ theme }) => ({
  '& label.Mui-focused': {
    color: theme.palette.primary.main,
  },
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
      borderWidth: '1px',
    },
  },
}));

const translateErrors = {
  'No User': 'incorrect',
  'User or password incorrect': 'incorrect',
  'Password Expired': 'password_expired',
  'Max attempts reach': 'max_attempts',
};

function LoginPage() {
  const translate = useTranslate();
  const history = useHistory();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const setLocale = useSetLocale();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const login = useLogin();
  const notify = useNotify();
  const submit = async (e) => {
    e.preventDefault();
    const { username, password } = formData;
    const newErrors = {};
    if (!username) {
      newErrors.username = 'Username is required';
    }
    if (!password) {
      newErrors.password = 'Password is required';
    }
    if (newErrors.password || newErrors.username) {
      setErrors(newErrors);
      return;
    }
    setLoading(true);
    login(formData)
      .then((res) => {
        setLocale(res.user.language);
        history.push('/myLicenses');
      })
      .catch((err) => {
        const root = 'login.error.';
        notify(root + (translateErrors[err.message] || 'default'), 'error');
        if (err.message === 'Password Expired') {
          history.push('/resetPassword');
        }
      })
      .finally(() => setLoading(false));
  };

  const handleFormChange = (field) => (e) => {
    if (e.target.value) {
      setErrors({ ...errors, [field]: '' });
    }
    setFormData({ ...formData, [field]: e.target.value });
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.formContainer}>
        <form className={classes.form} onSubmit={submit}>
          <LogoWithTitle showTitle />
          <Typography variant="h3">
            {translate('login.customer.title')}
          </Typography>
          <Typography variant="body1" className={classes.subtitle}>
            {translate('login.customer.subtitle')}
          </Typography>
          <TextField
            id="username"
            name="username"
            label={translate('login.fields.username.label')}
            variant="outlined"
            error={!!errors.username}
            helperText={errors.username}
            value={formData.username}
            onChange={handleFormChange('username')}
            autoFocus
          />
          <TextField
            id="password"
            name="password"
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label={translate('login.fields.password.label')}
            variant="outlined"
            value={formData.password}
            error={!!errors.password}
            helperText={errors.password}
            onKeyPress={(e) => e.key === 'Enter' && submit(e)}
            onChange={handleFormChange('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={toggleShowPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <VisibilityOffOutlinedIcon />
                    ) : (
                      <VisibilityOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            sx={{ mt: 2 }}
            variant="contained"
            color="primary"
            disableElevation
            disabled={loading}
            onClick={submit}
          >
            {loading ? '...Loading' : 'Log in'}
          </Button>
          <Link href="/#/resetPasswordRequest" underline="none">
            <Typography
              variant="body1"
              color="#59D4F6"
              fontWeight={600}
              textAlign="center"
            >
              {translate('login.forgotPassword')}
            </Typography>
          </Link>
          <Divider sx={{ height: '0.5px' }} />
          <Typography variant="body1" textAlign="center" color="#000000">
            {translate('login.noAccount')}
          </Typography>
          <Button
            color="secondary.main"
            style={{
              backgroundColor: theme.palette.primary?.lighten?.[1],
            }}
            href="/#/register"
          >
            {translate('login.signUp')}
          </Button>
          <Notification contained />
        </form>
      </Box>
      {!isSmall && (
        <Box className={classes.imgContainer}>
          <img src="CustomerLogin.png" alt="login" />
        </Box>
      )}
    </Box>
  );
}

function LoginWithTheme(props) {
  return (
    <ThemeProvider theme={customTheme}>
      <LoginPage {...props} />
    </ThemeProvider>
  );
}

export default LoginWithTheme;
