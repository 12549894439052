import { useEffect, useState } from 'react';

import {
  Create,
  useTranslate,
  SelectInput,
  ReferenceInput,
  FormDataConsumer,
  useGetList,
  required,
} from 'react-admin';

import { useForm } from 'react-final-form';

import { FormContainer } from '../../components/common';
import TitleViews from '../../components/common/TitleViews';
import { CreateForm } from '../../components/create';
import { useLocation } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Grid } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';

const SurveyForm = () => {
  const form = useForm();

  const [locations, setLocations] = useState([]);
  const [LocalLocations, setLocalLocations] = useState([]);
  const [surveyTemplates, setSurveyTemplates] = useState([]);

  const [surveyTemplatesId, setSurveyTemplatesId] = useState('');
  let location = useLocation();

  const { data, ids, isLoading, error } = useGetList('locations');
  const {
    data: data1,
    ids: ids1,
    isLoading: isLoading1,
    error: error1,
  } = useGetList('surveytemplates');

  const handleLocation = (event) => {
    const newLocations = locations.filter(
      (location) => location.clientId === event.target.value
    );
    setLocalLocations(newLocations);
    setSurveyTemplatesId('');
  };

  useEffect(() => {
    const newLocations = ids.map((id) => {
      return {
        id,
        name: data[id].name,
        clientId: data[id].clientId,
      };
    });
    setLocations(newLocations);
    const newSurveytemplates = ids1.map((id) => {
      return {
        id,
        name: data1[id].name,
        status: data1[id].status,
      };
    });
    setSurveyTemplates(
      newSurveytemplates.filter(
        (surveyTemplate) => surveyTemplate.status === 'PUBLISHED'
      )
    );

    if (location?.state) {
      const locationsFiltered = newLocations.filter(
        (locationItem) => locationItem.clientId === location?.state.clientId
      );
      setLocalLocations(locationsFiltered);

      form.change('locationId', location?.state?.locationId);
    }
  }, [location, ids, ids1, data, data1]);

  if (isLoading) {
    return <p>Loading...</p>;
  }
  if (error) {
    return <p>ERROR</p>;
  }

  if (isLoading1) {
    return <p>Loading...</p>;
  }
  if (error1) {
    return <p>ERROR</p>;
  }
  console.log('local---locations', LocalLocations);
  return (
    <FormDataConsumer>
      {() => {
        return (
          <>
            <Grid item xs={6}>
              <ReferenceInput
                source="clientId"
                reference="clients"
                onChange={(event) => handleLocation(event)}
                defaultValue={
                  location.state?.clientId ? location.state?.clientId : ''
                }
              >
                <SelectInput
                  optionText="companyName"
                  validate={[required()]}
                  fullWidth
                  variant="outlined"
                />
              </ReferenceInput>
            </Grid>
            <Grid item xs={6}>
              <FormControl required fullWidth size="small">
                <InputLabel
                  id="demo-simple-location-label"
                  sx={{ paddingLeft: 1, paddingTop: 1.5 }}
                >
                  Location
                </InputLabel>
                <Select
                  labelId="demo-simple-location-label"
                  id="demo-simple-location"
                  fullWidth
                  variant="outlined"
                  sx={{ padding: 0.7, marginTop: 1 }}
                  label="Location"
                  onChange={(event) => {
                    setSurveyTemplatesId('');
                    form.change('locationId', event.target.value);
                  }}
                  defaultValue={
                    location?.state?.locationId
                      ? location?.state?.locationId
                      : ''
                  }
                >
                  {LocalLocations?.map((location) => (
                    <MenuItem value={location.id} key={location.id}>
                      {location.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl required fullWidth size="small">
                <InputLabel
                  id="demo-simple-surveyTemplate-label"
                  sx={{ paddingLeft: 1, paddingTop: 1.5 }}
                >
                  Survey Template
                </InputLabel>
                <Select
                  value={surveyTemplatesId}
                  labelId="demo-simple-surveyTemplate-label"
                  id="demo-simple-surveyTemplate"
                  fullWidth
                  variant="outlined"
                  sx={{ padding: 0.7, marginTop: 1 }}
                  label="Survey Template"
                  onChange={(event) => {
                    form.change('surveyTemplateId', event.target.value);
                    setSurveyTemplatesId(event.target.value);
                  }}
                >
                  {surveyTemplates?.map((surveyTemplate) => (
                    <MenuItem value={surveyTemplate.id} key={location.id}>
                      {surveyTemplate.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <SelectInput
                source="status"
                label="Status"
                value="Created"
                fullWidth
                variant="outlined"
                choices={[
                  { id: 'CREATED', name: 'Created' },
                  { id: 'INPROGRESS', name: 'In Progress' },
                  { id: 'COMPLETED', name: 'Completed' },
                ]}
                disabled
                defaultValue="CREATED"
                validate={[required()]}
              />
            </Grid>
          </>
        );
      }}
    </FormDataConsumer>
  );
};

const SurveyCreate = (props) => {
  const translate = useTranslate();

  const transform = (data) => {
    console.log('data--->', data);
    return data;
  };
  return (
    <>
      <TitleViews
        title={translate('resources.surveys.createSurvey')}
        subsectionTitle={translate('resources.surveys.breadcrumb.createTitle')}
      />
      <Create component={FormContainer} {...props} transform={transform}>
        <CreateForm variant="outlined" resettable type="survey">
          <SurveyForm />
        </CreateForm>
      </Create>
    </>
  );
};

export default SurveyCreate;
