import { useTranslate, useNotify } from 'react-admin';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { Typography } from '@mui/material';
import { TypographyTranslated } from '../../components/common';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export default function ApiKeyDialog({ apiKey, onContinue, open, setOpen }) {
  const notify = useNotify();
  const translate = useTranslate();

  const copyTextToClipboard = async () => {
    await navigator.clipboard.writeText(apiKey);
    notify(translate('resources.apikeys.copy'));
  };

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    setOpen(false);
  };

  const handleContinue = () => {
    setOpen(false);
    onContinue && onContinue();
  };

  return (
    <Dialog onClose={handleClose} open={open} disableEscapeKeyDown>
      <DialogTitle
        style={{ padding: 0, position: 'relative' }}
        disableTypography
      >
        <TypographyTranslated
          style={{ textAlign: 'center', paddingTop: 24 }}
          variant="h5"
          label="resources.apikeys.show"
        />
      </DialogTitle>
      <DialogContent
        style={{ paddingBottom: 0, display: 'flex', alignItems: 'center' }}
      >
        <Typography>{apiKey}</Typography>
        <IconButton aria-label="copy" onClick={copyTextToClipboard}>
          <ContentCopyIcon />
        </IconButton>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={handleContinue}
          disableElevation
          style={{ margin: 'auto' }}
        >
          {translate('buttons.continue')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
