import { defaultTheme } from 'react-admin';
import { createTheme } from '@material-ui/core/styles';
import { typography } from './typography';
import { palette } from './palette';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {
  MuiButton,
  MuiOutlinedInput,
  MuiSwitch,
  RaSidebar,
  RaMenuItemLink,
  RaLayout,
  RaFileInput,
  MuiAlert,
} from './overrides';
import defaultMUITheme from '@material-ui/core/styles/defaultTheme';

const sidebar = {
  width: 260,
  closedWidth: 88,
};
const customTheme = createTheme({
  ...defaultTheme,
  sidebar,
  ...{
    palette,
    typography: { ...typography },
    overrides: {
      MuiButton: MuiButton(palette),
      MuiOutlinedInput: MuiOutlinedInput(palette),
      MuiSwitch: MuiSwitch(palette),
      MuiAlert: MuiAlert(palette),
      MuiInputLabel: {
        outlined: {
          '&$marginDense': {
            transform: 'translate(16px, 17px) scale(1)',
          },
        },
      },
      MuiTabs: {
        scroller: {
          'overflow-x': 'scroll !important',
        },
      },
      MuiFormLabel: {
        filled: {
          color: palette.black.lighten[4],
          '& + .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: `${palette.black.lighten[4]}`,
          },
          '& + .MuiOutlinedInput-root .MuiSvgIcon-root': {
            color: palette.black.lighten[4],
          },
        },
      },
      MuiFormGroup: {
        root: {
          width: 'fit-content',
          justifyContent: 'center',
        },
      },
      MuiFormControl: {
        fullWidth: {
          width: '100%',
          gridColumn: '1/-1',
        },
      },
      MuiAppBar: {
        root: {
          boxShadow: 'none',
        },
      },
      RaToolbar: {
        mobileToolbar: {
          position: 'inherit',
        },
      },
      RaFormInput: {
        input: {
          width: '100%',
        },
      },
      RaMenuItemLink: RaMenuItemLink(palette),
      RaSidebar: RaSidebar(palette),
      RaLayout: RaLayout(),
      RaAppBar: {
        toolbar: {
          backgroundColor: '#f4f6f8',
          width: '100%',
        },
        appBarClose: {
          [defaultMUITheme.breakpoints.only('xs')]: {
            width: '100% !important',
          },
        },
        appBarOpen: {
          [defaultMUITheme.breakpoints.only('xs')]: {
            width: '100% !important',
          },
        },
      },
      RaSearchInput: {
        input: {
          width: 300,
        },
      },
      RaList: {
        root: {
          flex: 1,
        },
        main: {
          marginTop: 20,
        },
        content: {
          boxShadow: 'none',
        },
      },
      RaEdit: {
        main: {
          '& > *': {
            boxShadow: 'none',
          },
        },
      },
      RaFileInput: RaFileInput(palette),
      RaImageInput: {
        root: {
          '& > div': {
            display: 'flex',
            flexDirection: 'row-reverse',
            justifyContent: 'space-between',
            overflow: 'hidden',
            alignItems: 'center',
          },
        },
      },
      RaSimpleFormIterator: {
        line: {
          display: 'flex !important',
          '& > *:first-child': {
            display: 'none',
          },
        },
        action: {
          display: 'block !important',
        },
        form: {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '1rem',
          flexWrap: 'wrap',
          '& > *': {
            flex: 1,
            flexBasis: 200,
          },
        },
      },
    },
    props: {
      MuiButton: {
        disableElevation: true,
      },
    },
  },
});

export default customTheme;
