import { logout as logoutAuth0 } from '../utils/auth0';

const authProvider = {
  login: async (code) => {
    const request = new Request(`${process.env.REACT_APP_API}/auth/login`, {
      method: 'POST',
      body: JSON.stringify({ code }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    try {
      const response = await fetch(request);
      if (response.status === 402) {
        throw new Error('Password expired');
      }
      if (response.status < 200 || response.status >= 300) {
        const err = await response.text();
        const errParsed = JSON.parse(err);
        throw new Error(errParsed.message);
      }
      const auth = await response.json();

      localStorage.setItem('token', auth.token);
      localStorage.setItem('user', JSON.stringify(auth.user));
      localStorage.setItem('permissions', JSON.stringify(auth.permissions));
      localStorage.setItem('timezone', auth.timezone ? auth.timezone : 'UTC');
      localStorage.setItem('notifications', auth.hasNotifications);
      return auth;
    } catch (err) {
      throw new Error(err.message);
    }
  },
  register: async (data) => {
    const request = new Request(`${process.env.REACT_APP_API}/auth/register`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    try {
      const response = await fetch(request);
      if (response.status < 200 || response.status >= 300) {
        const err = await response.json();
        throw new Error(err.errors?.[0].message);
      }
      const auth = await response.json();
      return auth;
    } catch (err) {
      throw new Error(err.message);
    }
  },
  checkError: (error) => {
    if (!error.toString().toLowerCase().includes('unauthorized')) {
      return Promise.resolve();
    }
    return Promise.reject({ redirectTo: '/login' });
  },
  checkAuth: () =>
    localStorage.getItem('token')
      ? Promise.resolve()
      : Promise.reject({ redirectTo: '/login' }),
  logout: () => {
    const logo = localStorage.getItem('logo');
    const userExist = localStorage.getItem('user');
    localStorage.clear();
    localStorage.setItem('logo', logo);
    if (userExist) {
      return logoutAuth0();
    }
    return Promise.resolve();
  },
  getPermissions: () => {
    const permissions = JSON.parse(localStorage.getItem('permissions'));
    return permissions ? Promise.resolve(permissions) : Promise.reject();
  },
  getIdentity: () => {
    try {
      const avatar = null;
      const { id, firstName, lastName, email, language, role } = JSON.parse(
        localStorage.getItem('user')
      );
      return {
        id,
        firstName,
        lastName,
        email,
        language,
        avatar,
        role,
      };
    } catch (error) {
      return Promise.reject(error);
    }
  },
  resetPasswordRequest: async ({ email }) => {
    const request = new Request(
      `${process.env.REACT_APP_API}/auth/resetPasswordRequest`,
      {
        method: 'POST',
        body: JSON.stringify({ email }),
        headers: new Headers({ 'Content-Type': 'application/json' }),
      }
    );
    try {
      const response = await fetch(request);
      if (response.status < 200 || response.status >= 300) {
        const err = await response.text();
        const errParsed = JSON.parse(err);
        throw new Error(errParsed.message);
      }
      return response;
    } catch (err) {
      throw new Error(err.message);
    }
  },
  resetPassword: async ({ password, code }) => {
    const request = new Request(
      `${process.env.REACT_APP_API}/auth/resetPassword`,
      {
        method: 'POST',
        body: JSON.stringify({ password, code }),
        headers: new Headers({ 'Content-Type': 'application/json' }),
      }
    );
    try {
      const response = await fetch(request);
      if (response.status < 200 || response.status >= 300) {
        const err = await response.text();
        const errParsed = JSON.parse(err);
        throw new Error(errParsed.message);
      }
      return response;
    } catch (err) {
      throw new Error(err.message);
    }
  },
  me: async () => {
    const request = new Request(`${process.env.REACT_APP_API}/auth/me`, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
    });
    try {
      const response = await fetch(request);
      if (response.status < 200 || response.status >= 300) {
        const err = await response.text();
        const errParsed = JSON.parse(err);
        throw new Error(errParsed.message);
      }
      const auth = await response.json();
      return auth;
    } catch (err) {
      throw new Error(err.message);
    }
  },
  updateProfile: async (data) => {
    const request = new Request(`${process.env.REACT_APP_API}/auth/me`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
    });
    try {
      const response = await fetch(request);
      if (response.status < 200 || response.status >= 300) {
        const err = await response.text();
        const errParsed = JSON.parse(err);
        throw new Error(errParsed.message);
      }
      const auth = await response.json();
      return auth;
    } catch (err) {
      throw new Error(err.message);
    }
  },
  changePassword: async (data) => {
    const request = new Request(
      `${process.env.REACT_APP_API}/auth/changePassword`,
      {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }),
      }
    );
    try {
      const response = await fetch(request);
      if (response.status < 200 || response.status >= 300) {
        const err = await response.text();
        const errParsed = JSON.parse(err);
        throw new Error(errParsed.message);
      }
      const auth = await response.json();
      return auth;
    } catch (err) {
      throw new Error(err.message);
    }
  },
};

export default authProvider;
