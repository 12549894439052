import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  infoContainer: {
    width: 320,
    height: 320,
    display: 'flex',
    borderRadius: '50%',
    backgroundColor: theme.palette.secondary.lighten[0],
    placeContent: 'center',
    alignItems: 'center',
  },
  infoIcon: {
    fontSize: 200,
    color: theme.palette.primary.main,
  },
}));

export default function NoData({ icon: Icon, title }) {
  const classes = useStyles();
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 24,
        }}
      >
        <Box className={classes.infoContainer}>
          <Icon className={classes.infoIcon} />
        </Box>
        <Typography style={{ alignSelf: 'center' }} variant="h5">
          {title}
        </Typography>
      </Box>
    </Box>
  );
}
