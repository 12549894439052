import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100vh',
    backgroundColor: 'white',
  },
  formContainer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  subtitle: {
    color: theme.palette.text.subtitle,
  },
  form: {
    display: 'flex',
    width: '100%',
    maxWidth: '343px',
    flexDirection: 'column',
    gap: theme.spacing(3),
    '& > img': {
      objectFit: 'none',
      objectPosition: 'left',
    },
  },
  imgContainer: {
    height: '100%',
    '& > img': {
      height: 'inherit',
    },
  },
  title: {
    textAlign: 'center',
    marginBottom: 16,
  },
}));
