import LicenseList from './list';
import LicenseEdit from './edit';
import LicenseCreate from './create';

const licenseResource = {
  list: LicenseList,
  edit: LicenseEdit,
  create: LicenseCreate,
};

export default licenseResource;
