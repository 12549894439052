import { Children, cloneElement } from 'react';
import { ListToolbar as RaListToolbar } from 'react-admin';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingLeft: 20,
    paddingRight: 20,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  actions: {
    color: theme.palette.secondary.main,
  },
}));

export default function ListToolbar({ filters, actions }) {
  const classes = useStyles();
  const newFilters = Children.map(filters, (filter) =>
    cloneElement(filter, { ...filter.props, variant: 'outlined' })
  );
  return (
    <RaListToolbar
      classes={classes}
      filters={newFilters}
      actions={cloneElement(actions)}
    />
  );
}
