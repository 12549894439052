import {
  SelectInput,
  TextInput,
  maxLength,
  required,
  useTranslate,
} from 'react-admin';
import ResetApiKeyButton from './ResetApiKeyButton';
import { CustomEdit, EditForm } from '../../components/edit';
import checkPermission from '../../utils/checkPermission';

const ApiKeyEdit = (props) => {
  const translate = useTranslate();
  const deleteAccess = checkPermission({
    method: 'DELETE',
    url: 'apikeys',
    permissions: JSON.parse(localStorage.getItem('permissions')),
  });

  return (
    <CustomEdit {...props}>
      <EditForm
        variant="outlined"
        ressetable="true"
        toolbarProps={{
          customButton: <ResetApiKeyButton id={Number(props.id)} />,
          withoutDelete: !deleteAccess,
        }}
        titleProps={{
          subsectionTitle: translate('resources.apikeys.breadcrumb.editTitle'),
          sectionTitle: translate('resources.apikeys.breadcrumb.section'),
          title: translate('resources.apikeys.breadcrumb.editTitle'),
        }}
      >
        <TextInput
          source="name"
          label={translate('resources.apikeys.fields.name')}
          variant="outlined"
          resettable
          validate={[required(), maxLength(255)]}
        />

        <SelectInput
          source="roleId"
          choices={[
            { id: 1, name: 'choices.admin' },
            { id: 2, name: 'choices.superAdmin' },
            { id: 3, name: 'choices.customer' },
            { id: 4, name: 'choices.surveyContractor' },
            { id: 5, name: 'choices.connectWise' },
          ]}
          validate={[required()]}
        />
      </EditForm>
    </CustomEdit>
  );
};

export default ApiKeyEdit;
