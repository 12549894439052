import {
  ArrayInput,
  useTranslate,
  SimpleFormIterator,
  FormDataConsumer,
  SelectInput,
  TextInput,
  required,
  NumberInput,
} from 'react-admin';
import Grid from '@mui/material/Grid';
import { Button } from '@material-ui/core';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import SurveyTemplateQuestions from './SurveyTemplateQuestions';
import SurveyTemplateQuestionsEdit from './SurveyTemplateQuestionsEdit';

const TransitionProps = {
  classNames: '',
  addEndListener: () => undefined,
};

const SurveyTemplateTab = ({ ...props }) => {
  const translate = useTranslate();
  return (
    <Grid container sx={tabsStyles}>
      <Grid item xs={12} sx={tabsTitleStyle}>
        {translate('resources.surveytemplates.tabsTitle')}
      </Grid>
      <ArrayInput
        source={
          props.type === 'surveyTemplateEdit' ? 'SurveyTabTemplates' : 'tabs'
        }
        label=""
      >
        <SimpleFormIterator
          TransitionProps={TransitionProps}
          removeButton={
            <div
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <DeleteOutlineOutlinedIcon sx={{ marginRight: 2 }} />
            </div>
          }
          addButton={
            <span style={{ margin: 15 }}>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                size="small"
                inline="false"
                disableElevation
                color="secondary"
              >
                {translate('resources.surveytemplates.addTab')}
              </Button>
            </span>
          }
        >
          <FormDataConsumer>
            {({ getSource, scopedFormData }) => {
              return (
                <Grid
                  container
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  rowSpacing={0}
                  sx={{ paddingLeft: 2, paddingRight: 2, marginTop: 2 }}
                >
                  <Grid item xs={6}>
                    <TextInput
                      source={getSource('name')}
                      label="Name"
                      validate={[required()]}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SelectInput
                      source={getSource('status')}
                      label="Status"
                      choices={[
                        { id: 'DRAFT', name: 'Draft' },
                        { id: 'PUBLISHED', name: 'Published' },
                      ]}
                      validate={[required()]}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <NumberInput
                      source={getSource('order')}
                      label="Order"
                      validate={[required()]}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  {props.type === 'surveyTemplateEdit' ? (
                    <SurveyTemplateQuestionsEdit
                      getsource={getSource}
                      type="surveyTemplateEdit"
                      scopedFormData={scopedFormData}
                    />
                  ) : (
                    <SurveyTemplateQuestions
                      getsource={getSource}
                      type="surveyTemplateEdit"
                      scopedFormData={scopedFormData}
                    />
                  )}
                </Grid>
              );
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </Grid>
  );
};

export default SurveyTemplateTab;

const tabsStyles = {
  backgroundColor: '#FAFAFA',
  borderWidth: 'thin',
  borderRadius: 1,
};

const tabsTitleStyle = {
  padding: 2,
  borderBottomStyle: 'solid',
  borderWidth: 'thin',
  borderColor: '#DCDCDC',
  fontWeight: 'bold',
};
