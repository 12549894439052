import {
  TextInput,
  NumberInput,
  DateInput,
  ImageField,
  FormDataConsumer,
  useTranslate,
  FileInput,
  FileField,
  SelectInput,
  useRefresh,
} from 'react-admin';
import ReactMarkdown from 'react-markdown';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Tooltip } from '@material-ui/core';
import { Divider } from '../list';
import { FileInputPlaceholder, ImageInput } from '../common';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import AddDoorsButton from './AddDoorsButton';

export const questionTypes = {
  STRING: StringQuestion,
  TEXT: TextQuestion,
  NUMBER: NumberQuestion,
  DATE: DateQuestion,
  GROUPED: GroupedQuestion,
  PICTURE: ImageQuestion,
  'MULTIPLE PICTURES': GroupedImageQuestion,
  ATTACHMENT: FileQuestion,
  'MULTIPLE ATTACHMENT': MultipleFileQuestion,
  SELECTOR: SelectorQuestion,
  BUTTON: AddDoorsButton,
  ERROR: ErrorQuestion,
};

const useSaveWithDelay = (delay = 500) => {
  const [triggerSave, setTriggerSave] = useState(null);
  const saveFunc = useRef(null);
  useEffect(() => {
    if (triggerSave && saveFunc.current) {
      const timeout = setTimeout(() => {
        saveFunc.current();
        setTriggerSave(null);
      }, delay);
      return () => {
        console.log('clear');
        clearTimeout(timeout);
      };
    }
  }, [triggerSave]);

  const saveLater = (callback) => {
    saveFunc.current = callback;
    setTriggerSave((prev) => prev + 1);
  };
  const cancelSave = () => {
    saveFunc.current = null;
    setTriggerSave(null);
  };
  return { saveLater, cancelSave };
};
const Label = ({ children, style }) => {
  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        ...style,
      }}
    >
      {children}
    </Box>
  );
};

const Container = ({ isMultiple, children }) => {
  if (isMultiple) {
    return (
      <Box
        sx={{
          backgroundColor: '#f5f5f5',
          padding: '0px 20px 20px 20px',
          marginBottom: 14,
        }}
      >
        {children}
      </Box>
    );
  }
  return children;
};

function CustomTooltip(props) {
  const translate = useTranslate();
  const [open, setOpen] = React.useState();

  const tooltip = props.tooltip;
  const tooltipImage = props?.typeProperties?.tooltipImage;
  const title = tooltip ?? translate('tooltip.defaultTitle');

  const handleClick = () => {
    if (!tooltipImage) return;
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!tooltip && !tooltipImage) return null;

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <img alt="details" src={tooltipImage} style={{ maxWidth: '100%' }} />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            {translate('tooltip.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
      <Tooltip placement="right" title={title}>
        <InfoOutlinedIcon onClick={handleClick} fontSize="small" />
      </Tooltip>
    </>
  );
}

export function Question(props) {
  const { type, question, disabled, code, onBlur, saveGlobally } = props;
  const TypeQuestion = questionTypes[type];
  const isMultiple = type === 'GROUPED' || type === 'BUTTON';
  const answers = props?.record?.answers || {};
  const currentAnswer = answers[code];

  const handleBlur = saveGlobally ? props.updateAllSurvey : onBlur;

  if (disabled && !currentAnswer) return null;

  return (
    <>
      <Container isMultiple={isMultiple}>
        <Label
          style={type === 'ERROR' ? { color: 'red', fontWeight: 500 } : {}}
        >
          <ReactMarkdown>
            {isMultiple ? `**${question}**` : question}
          </ReactMarkdown>
          <CustomTooltip {...props} />
        </Label>
        {TypeQuestion && (
          <TypeQuestion {...props} code={code} onBlur={handleBlur} />
        )}
      </Container>
      <Divider />
    </>
  );
}

function StringQuestion(props) {
  const { saveLater, cancelSave } = useSaveWithDelay();
  return (
    <TextInput
      source={`answers.${props.code}`}
      variant="outlined"
      label=""
      disabled={props?.disabled}
      onBlur={(e) => {
        props.onBlur(e, props.code);
        cancelSave();
      }}
      onChange={(e) => {
        saveLater(() => props.onBlur(e, props.code));
      }}
      fullWidth
    />
  );
}
function TextQuestion(props) {
  const { saveLater, cancelSave } = useSaveWithDelay();
  return (
    <TextInput
      source={`answers.${props.code}`}
      variant="outlined"
      multiline={true}
      label=""
      disabled={props?.disabled}
      onBlur={(e) => {
        props.onBlur(e, props.code);
        cancelSave();
      }}
      onChange={(e) => {
        saveLater(() => props.onBlur(e, props.code));
      }}
      fullWidth
    />
  );
}

function NumberQuestion(props) {
  const { saveLater, cancelSave } = useSaveWithDelay();
  return (
    <NumberInput
      source={`answers.${props.code}`}
      variant="outlined"
      label={false}
      disabled={props?.disabled}
      onBlur={(e) => {
        props.onBlur(e, props.code);
        cancelSave();
      }}
      onChange={(e) => {
        saveLater(() => props.onBlur(e, props.code));
      }}
      fullWidth
    />
  );
}

function DateQuestion(props) {
  return (
    <DateInput
      source={`answers.${props.code}`}
      variant="outlined"
      label={false}
      disabled={props?.disabled}
      onChange={(e) => props.onBlur(e, props.code)}
      fullWidth
    />
  );
}

function GroupedQuestion(props) {
  const { saveLater } = useSaveWithDelay();
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {props.typeProperties?.questions?.map((data) => (
        <TextInput
          key={data.code}
          style={{
            marginTop: 0,
            marginBottom: 0,
          }}
          variant="outlined"
          source={`answers.${props.code}.${data.code}`}
          defaultValue={props?.initialAnswer?.[props.code]?.[data.code] ?? ''}
          label={data?.label ?? ''}
          disabled={props?.disabled}
          onChange={() => {
            saveLater(() => props.updateAllSurvey());
          }}
          fullWidth
        />
      ))}
    </Box>
  );
}

function ImageQuestion(props) {
  return (
    <FormDataConsumer>
      {({ formData }) => (
        <ImageInput
          placeholder={
            <FileInputPlaceholder
              disabled={!!_.get(formData, `answers.${props.code}`)?.src}
            />
          }
          label=""
          source={`answers.${props.code}`}
          accept="image/*"
          onChange={props.updateAllSurvey}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      )}
    </FormDataConsumer>
  );
}

function GroupedImageQuestion(props) {
  return (
    <FormDataConsumer>
      {({ formData }) => (
        <ImageInput
          placeholder={
            <FileInputPlaceholder
              disabled={!!_.get(formData, `answers.${props.code}`)?.length}
            />
          }
          label=""
          source={`answers.${props.code}`}
          accept="image/*"
          onChange={props.updateAllSurvey}
          multiple
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      )}
    </FormDataConsumer>
  );
}

function FileQuestion(props) {
  const refresh = useRefresh();
  return (
    <FormDataConsumer>
      {({ formData }) => (
        <FileInput
          placeholder={
            <FileInputPlaceholder
              disabled={!!_.get(formData, `answers.${props.code}`)?.src}
            />
          }
          label=""
          source={`answers.${props.code}`}
          onChange={async () => {
            await props.updateAllSurvey();
            refresh(true);
          }}
        >
          <FileField source="src" title="title" record={formData} />
        </FileInput>
      )}
    </FormDataConsumer>
  );
}

function MultipleFileQuestion(props) {
  const refresh = useRefresh();
  return (
    <FormDataConsumer>
      {({ formData }) => (
        <FileInput
          placeholder={
            <FileInputPlaceholder
              disabled={!!_.get(formData, `answers.${props.code}`)?.length}
            />
          }
          label=""
          source={`answers.${props.code}`}
          onChange={async () => {
            await props.updateAllSurvey();
            refresh(true);
          }}
          multiple
        >
          <FileField source="src" title="title" record={formData} />
        </FileInput>
      )}
    </FormDataConsumer>
  );
}

function SelectorQuestion(props) {
  const { typeProperties } = props;
  const choices = typeProperties?.choices || [];
  return (
    <SelectInput
      variant="outlined"
      label={false}
      source={`answers.${props.code}`}
      choices={choices}
      disabled={props.disabled}
      onChange={(e) => props.onBlur(e, props.code)}
      fullWidth
    />
  );
}

function ErrorQuestion() {
  return null;
}
