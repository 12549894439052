import merge from 'lodash/merge';
import { defaultTheme } from 'react-admin';
import { colors } from '@material-ui/core';
import createPalette from '@material-ui/core/styles/createPalette';

export const palette = createPalette(
  merge({}, defaultTheme.palette, {
    background: {
      default: '#F4F6F8',
      paper: colors.common.white,
    },
    primary: {
      contrastText: '#ffffff',
      main: '#35C4EC',
      lighten: ['#F8FDFF', '#ECFBFF', '#D2F5FF', '#98E9FF', '#59D4F6'],
      darken: ['#3AB3D4', '#36A2C0', '#2687A1', '#1F6E84', '#144C5B'],
    },
    textPrimary: {
      main: '#000000',
    },
    secondary: {
      contrastText: '#ffffff',
      main: '#255577',
      lighten: [
        '#EAF5FD',
        '#B9D7EC',
        '#8BBADB',
        '#699FC5',
        '#407AA4',
        '#BBF0FF',
      ],
    },
    black: {
      contrastText: '#ffffff',
      main: '#000000',
      lighten: ['#F5F5F5', '#DEDEDE', '#ABABAB', '#6A6A6A', '#363636'],
    },
    red: {
      contrastText: '#ffffff',
      main: '#D90000',
      lighten: ['#FFDDDD', '#FFC2CC', '#FF6178', '#FF3755'],
      darken: ['#C10000', '#A00000', '#770000', '#530000', '#310000'],
    },
    green: {
      contrastText: '#ffffff',
      main: '#45D900',
      lighten: ['#E6F9E6', '#C6F2C6', '#8CD98C', '#5CB85C', '#EEFFE7'],
    },
    text: {
      primary: '#172b4d',
      secondary: '#6b778c',
      subtitle: '#6A6A6A',
    },
    action: {
      selected: '#ECFBFF',
    },
    alert: {
      main: '#FF7A00',
      lighten: ['#FFF0E1', '#FFD7B2', '#FFB168', '#FF9533'],
    },
    success: {
      contrastText: '#45D900',
      main: '#EEFFE7',
    },
    danger: {
      contrastText: '#D90000',
      main: '#FFDDDD',
    },
  })
);
