import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  usePermissions,
  MenuItemLink,
  useTranslate,
  useGetIdentity,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

import checkPermission from '../../utils/checkPermission';
import {
  PlaceOutlined,
  PersonOutline,
  SettingsOutlined,
} from '@material-ui/icons';

import SubMenu from './SubMenu';
import { GroupsOutlined } from '@mui/icons-material';
import LicenseTypeIcon from '@material-ui/icons/WorkOutlineOutlined';
import VersionIcon from '@material-ui/icons/AccountTree';
import ProductIcon from '@material-ui/icons/AssignmentIndOutlined';
import DeviceIcon from '@mui/icons-material/SensorDoorOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import CollectionsBookmarkOutlinedIcon from '@mui/icons-material/CollectionsBookmarkOutlined';
import ApiIcon from '@mui/icons-material/Api';

const Menu = ({ dense = false }) => {
  const { permissions } = usePermissions();
  const { identity } = useGetIdentity();
  const [openSubmenu, setOpenSubmenu] = useState('');
  const translate = useTranslate();
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  useSelector((state) => state.theme); // force rerender on theme change
  const classes = useStyles();
  const isCustomer = identity?.role === 'Customer';

  const handleToggle = (menu) =>
    setOpenSubmenu((selectedMenu) => (selectedMenu === menu ? null : menu));

  const closeSubmenu = () => setOpenSubmenu({});

  const licensesAccess = checkPermission({ permissions, url: 'licenses' });
  const clientAccess =
    checkPermission({ permissions, url: 'clients' }) && !isCustomer;
  const surveyTemplateAccess = checkPermission({
    permissions,
    url: 'surveytemplates',
  });
  const adminUserAccess = checkPermission({ permissions, url: 'users' });
  const licenseTypesAccess = checkPermission({
    permissions,
    url: 'licensetypes',
  });
  const productAccess = checkPermission({ permissions, url: 'products' });
  const versionAccess = checkPermission({ permissions, url: 'versions' });
  const deviceAccess =
    checkPermission({ permissions, url: 'devices' }) && !isCustomer;
  const surveysAccess = checkPermission({ permissions, url: 'surveys' });
  const apiKeyAccess = checkPermission({ permissions, url: 'apikeys' });
  const configurationAccess =
    !isCustomer &&
    (adminUserAccess ||
      licenseTypesAccess ||
      productAccess ||
      versionAccess ||
      apiKeyAccess);

  return (
    <div
      className={classnames(classes.root, {
        [classes.open]: open,
        [classes.closed]: !open,
      })}
    >
      {clientAccess && (
        <MenuItemLink
          to={{
            pathname: '/clients',
            state: { _scrollToTop: true },
          }}
          primaryText={translate('resources.clients.title')}
          leftIcon={<PersonOutline />}
          exact
          onClick={closeSubmenu}
        />
      )}
      {surveysAccess && (
        <MenuItemLink
          to={{
            pathname: '/surveys',
            state: { _scrollToTop: true },
          }}
          primaryText={translate('resources.surveys.title')}
          leftIcon={<AssignmentOutlinedIcon />}
          dense={dense}
          onClick={closeSubmenu}
        />
      )}
      {licensesAccess && (
        <MenuItemLink
          to={{
            pathname: '/licenses',
            state: { _scrollToTop: true },
          }}
          primaryText={translate('resources.licenses.title')}
          leftIcon={<PlaceOutlined />}
          dense={dense}
          onClick={closeSubmenu}
        />
      )}
      {deviceAccess && (
        <MenuItemLink
          to={{
            pathname: '/devices',
            state: { _scrollToTop: true },
          }}
          primaryText={translate('resources.devices.title')}
          leftIcon={<DeviceIcon />}
          dense={dense}
          onClick={closeSubmenu}
        />
      )}
      {configurationAccess && (
        <SubMenu
          isOpen={openSubmenu === 'settings'}
          onClose={closeSubmenu}
          handleToggle={() => handleToggle('settings')}
          dense={dense}
          icon={<SettingsOutlined />}
          name={translate('resources.settings.title')}
        >
          {adminUserAccess && (
            <MenuItemLink
              to={{
                pathname: '/users',
                state: { _scrollToTop: true },
              }}
              primaryText={translate('resources.adminusers.title')}
              leftIcon={<GroupsOutlined />}
              dense={dense}
            />
          )}

          {licenseTypesAccess && (
            <MenuItemLink
              to={{
                pathname: '/licensetypes',
                state: { _scrollToTop: true },
              }}
              primaryText={translate('resources.licenseTypes.title')}
              leftIcon={<LicenseTypeIcon />}
              dense={dense}
            />
          )}

          {productAccess && (
            <MenuItemLink
              to={{
                pathname: '/products',
                state: { _scrollToTop: true },
              }}
              primaryText={translate('resources.products.title')}
              leftIcon={<ProductIcon />}
              dense={dense}
            />
          )}
          {versionAccess && (
            <MenuItemLink
              to={{
                pathname: '/versions',
                state: { _scrollToTop: true },
              }}
              primaryText={translate('resources.versions.title')}
              leftIcon={<VersionIcon />}
              dense={dense}
            />
          )}
          {surveyTemplateAccess && (
            <MenuItemLink
              to={{
                pathname: '/surveytemplates',
                state: { _scrollToTop: true },
              }}
              primaryText={translate('resources.surveytemplates.title')}
              leftIcon={<CollectionsBookmarkOutlinedIcon />}
              exact
              dense={dense}
            />
          )}
          {apiKeyAccess && (
            <MenuItemLink
              to={{
                pathname: '/apikeys',
                state: { _scrollToTop: true },
              }}
              primaryText={translate('resources.apikeys.title')}
              leftIcon={<ApiIcon />}
              exact
              dense={dense}
            />
          )}
        </SubMenu>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: 'border-box',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  open: {
    width: theme.sidebar.width,
  },
  closed: {
    width: theme.sidebar.closedWidth,
  },
  icon: {
    color: 'inherit',
  },
  divider: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    color: 'red',
    ...theme.overrides.RaMenuItemLink.root,
    height: 1,
    '&:hover': {},
  },
}));

export default Menu;
