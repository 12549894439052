import { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useAuthProvider, useNotify, useTranslate } from 'react-admin';

import { Typography } from '@mui/material';
import { Box, Button, ThemeProvider, useMediaQuery } from '@material-ui/core';
import Notification from '../layout/notification';

import { useStyles } from './styles';
import { LogoWithTitle } from '../common';
import customTheme from '../../themes/customTheme';
import ShowPasswordInput from './ShowPasswordInput';

const translateErrors = {
  'Invalid Code': 'invalid_code',
};

const strongPassword =
  /(?=[^_]*\d)(?=[^_]*[a-z])(?=[^_]*[A-Z])(?=.*[@$!%*#?&^_-]).{8,20}/;

function ResetPage() {
  const translate = useTranslate();
  const history = useHistory();
  const { code } = useParams();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: '',
  });
  const [errors, setErrors] = useState({});
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const authProvider = useAuthProvider();
  const notify = useNotify();
  const submit = async (e) => {
    e.preventDefault();
    const { password, confirmPassword } = formData;
    const newErrors = {};
    if (!password.match(strongPassword))
      newErrors.password = translate('register.error.password_invalid');
    if (password !== confirmPassword) {
      newErrors.confirmPassword = translate('register.error.password_match');
      newErrors.password = translate('register.error.password_match');
    }
    if (!confirmPassword) {
      newErrors.confirmPassword = translate('register.error.confirmPassword');
    }
    if (!password) {
      newErrors.password = translate('register.error.password');
    }
    if (newErrors.password || newErrors.username) {
      setErrors(newErrors);
      return;
    }
    setLoading(true);
    authProvider
      .resetPassword({ password, code })
      .then(history.push('/customerLogin'))
      .catch((err) => {
        const root = 'resetPassword.error.';
        notify(root + (translateErrors[err.message] || 'default'), 'error');
      })
      .finally(() => setLoading(false));
  };

  const handleFormChange = (field) => (e) => {
    if (e.target.value) {
      setErrors({ ...errors, [field]: '' });
    }
    setFormData({ ...formData, [field]: e.target.value });
  };

  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.formContainer}>
        <form className={classes.form} onSubmit={submit}>
          <LogoWithTitle showTitle />
          <Typography variant="h3">
            {translate('resetPassword.title')}
          </Typography>
          <Typography variant="body1" className={classes.subtitle}>
            {translate('resetPassword.resetSubtitle')}
          </Typography>
          <ShowPasswordInput
            label={translate('resetPassword.fields.password')}
            value={formData.password}
            onChange={handleFormChange('password')}
            error={errors.password}
            helperText={errors.password}
          />
          <ShowPasswordInput
            label={translate('resetPassword.fields.confirmPassword')}
            value={formData.confirmPassword}
            onChange={handleFormChange('confirmPassword')}
            error={errors.confirmPassword}
            helperText={errors.confirmPassword}
          />

          <Button
            style={{
              padding: 25,
            }}
            variant="contained"
            color="primary"
            disableElevation
            disabled={loading}
            onClick={submit}
          >
            {translate('resetPassword.updatePassword')}
          </Button>
          <Notification contained />
        </form>
      </Box>
      {!isSmall && (
        <Box className={classes.imgContainer}>
          <img src="CustomerLogin.png" alt="login" />
        </Box>
      )}
    </Box>
  );
}

function ResetPasswordWithTheme(props) {
  return (
    <ThemeProvider theme={customTheme}>
      <ResetPage {...props} />
    </ThemeProvider>
  );
}

export default ResetPasswordWithTheme;
