import { makeStyles } from '@material-ui/core';
import { Datagrid as RaDatagrid } from 'react-admin';

const useStyles = makeStyles((theme) => ({
  headerCell: {
    ...theme.typography.thead,
  },
}));

export default function Datagrid(props) {
  const classes = useStyles();

  return (
    <div style={{ padding: '0 20px' }}>
      <RaDatagrid classes={classes} {...props} />
    </div>
  );
}
