import PropTypes from 'prop-types';
import { Edit } from 'react-admin';

export default function CustomEdit({ children, ...props }) {
  const mutationMode = 'pessimistic';

  return (
    <Edit mutationMode={mutationMode} {...props}>
      {children}
    </Edit>
  );
}

CustomEdit.propTypes = {
  actions: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  aside: PropTypes.element,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  hascreate: PropTypes.bool,
  hasEdit: PropTypes.bool,
  hasshow: PropTypes.bool,
  hasList: PropTypes.bool,
  id: PropTypes.any.isRequired,
  mutationMode: PropTypes.oneOf(['pessimistic', 'optimistic', 'undoable']),
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  resource: PropTypes.string,
  successMessage: PropTypes.string,
  title: PropTypes.node,
  transform: PropTypes.func,
  undoable: PropTypes.bool,
};
