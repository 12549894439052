export function login() {
  const url =
    `${process.env.REACT_APP_AUTH0_DOMAIN}/authorize?` +
    `response_type=code&client_id=${process.env.REACT_APP_AUTH0_CLIENT_ID}` +
    '&scope=openid%20profile%20email' +
    `&audience=${process.env.REACT_APP_AUTH0_AUDIENCE}` +
    `&redirect_uri=${window.location.origin}/#/login`;
  window.location.assign(url);
}

export async function logout() {
  const url =
    `${process.env.REACT_APP_AUTH0_DOMAIN}/v2/logout?` +
    `&client_id=${process.env.REACT_APP_AUTH0_CLIENT_ID}` +
    `&returnTo=${window.location.origin}/#/login`;
  window.location.assign(url);
}
