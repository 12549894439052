import {
  Create,
  required,
  TextInput,
  useTranslate,
  SelectInput,
} from 'react-admin';
import { FormContainer } from '../../components/common';
import TitleViews from '../../components/common/TitleViews';
import { CreateForm } from '../../components/create';

const SurveyTemplateCreate = (props) => {
  const translate = useTranslate();

  const transform = (data) => {
    console.log('data--->', data);
    return data;
  };

  return (
    <>
      <TitleViews
        title={translate('resources.surveytemplates.createSurvey')}
        subsectionTitle={translate(
          'resources.surveytemplates.breadcrumb.section'
        )}
      />
      <Create component={FormContainer} {...props} transform={transform}>
        <CreateForm variant="outlined" resettable type="surveyTemplate">
          <TextInput source="name" label="Name" validate={[required()]} />
          <SelectInput
            source="status"
            label="Status"
            choices={[
              { id: 'DRAFT', name: 'Draft' },
              { id: 'PUBLISHED', name: 'Published' },
            ]}
            validate={[required()]}
          />
        </CreateForm>
      </Create>
    </>
  );
};

export default SurveyTemplateCreate;
