import {
  ReferenceArrayInput,
  required,
  SelectArrayInput,
  TextInput,
  useTranslate,
} from 'react-admin';
import { CustomEdit, EditForm } from '../../components/edit';

const LicenseTypeEdit = (props) => {
  const translate = useTranslate();
  const root = 'resources.licenseTypes';
  return (
    <>
      <CustomEdit mutationMode="pessimistic" {...props}>
        <EditForm
          variant="outlined"
          ressetable
          titleProps={{
            subsectionTitle: translate(`${root}.breadcrumb.editTitle`),
          }}
        >
          <TextInput source="name" validate={[required()]} fullWidth />
          <TextInput
            variant="outlined"
            source="description"
            options={{ multiline: true, minRows: 4 }}
            fullWidth={true}
            resettable
            validate={[required()]}
          />
          <ReferenceArrayInput source="productIds" reference="products">
            <SelectArrayInput optionText="name" validate={required()} />
          </ReferenceArrayInput>
          <SelectArrayInput
            choices={[
              {
                id: 'ONLINE_MODE',
                name: translate(`${root}.features.ONLINE_MODE`),
              },
              {
                id: 'MOBILE_KEYS',
                name: translate(`${root}.features.MOBILE_KEYS`),
              },
              {
                id: 'SERVICE_REQUEST',
                name: translate(`${root}.features.SERVICE_REQUEST`),
              },
              {
                id: 'APPLE_WALLET',
                name: translate(`${root}.features.APPLE_WALLET`),
              },
            ]}
            source="features"
          />
        </EditForm>
      </CustomEdit>
    </>
  );
};

export default LicenseTypeEdit;
