import SubtitlesOffOutlinedIcon from '@mui/icons-material/SubtitlesOffOutlined';
import { Box, styled, Typography, useTheme } from '@material-ui/core';
import { useTranslate } from 'react-admin';

const Container = styled(Box)({
  flex: 1,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  rowGap: 50,
  padding: '0 1em',
  textAlign: 'center',
});

export default function EmptyWidget({
  showDescription,
  isDashboard,
  customTexts = {},
}) {
  const { title, message } = customTexts;
  const theme = useTheme();
  const translate = useTranslate();
  return (
    <Container
      style={{
        background: theme.palette.background.paper,
      }}
    >
      <Box
        style={{
          background: theme.palette.primary.lighten[1],
          borderRadius: '50%',
        }}
      >
        <SubtitlesOffOutlinedIcon
          style={{
            fontSize: 99,
            fill: theme.palette.primary.main,
            ...(isDashboard ? {} : { width: '11rem', height: '11rem' }),
          }}
        />
      </Box>
      <Typography
        style={{ color: 'black' }}
        variant={isDashboard ? 'body2' : 'h4'}
      >
        {title ?? translate('empty.noData')}
      </Typography>
      {showDescription && (
        <Typography variant="body2">
          {message ?? translate('empty.clickButtons')}
        </Typography>
      )}
    </Container>
  );
}
