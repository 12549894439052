import { useRecordContext, useTranslate } from 'react-admin';
import UrlField from '../common/UrlField';

export default function CompleteSurveyButton() {
  const root = 'resources.surveys.fields';
  const translate = useTranslate();
  const record = useRecordContext();
  const viewText =
    record.status === 'COMPLETED'
      ? translate(`${root}.viewSurvey`)
      : record.status === 'INPROGRESS'
      ? translate(`${root}.continueSurvey`)
      : translate(`${root}.startSurvey`);

  return <UrlField source="id" urlPrefix="surveys/fill" text={viewText} />;
}
