import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Question } from './Question';

const AccordionSurveyTabs = ({ ...props }) => {
  const [surveysTemplate, setSurveysTemplate] = useState([]);
  const [expanded, setExpanded] = useState();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    setSurveysTemplate(props?.record?.SurveyTemplate?.SurveyTabTemplates);
  }, [props?.record?.SurveyTemplate?.SurveyTabTemplates]);

  return (
    <Grid container style={gridContainerStyles}>
      {surveysTemplate?.map((tab) => (
        <Accordion
          style={accordionStyle}
          key={tab.id}
          elevation={0}
          disableGutters
          onChange={handleChange(`panel${tab.id}`)}
          expanded={expanded === `panel${tab.id}`}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={accordionSummaryStyle}
          >
            <Typography>{tab.name}</Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              backgroundColor: '#FAFAFA',
              borderTopStyle: 'solid',
              borderTopColor: '#DCDCDC',
              borderTopWidth: 'thin',
            }}
          >
            {tab?.SurveyQuestionTemplates?.map((question) => (
              <Question
                {...question}
                record={props.record}
                disabled
                key={question.id}
              />
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </Grid>
  );
};

const gridContainerStyles = {
  marginTop: 20,
  borderWidth: 'thin',
  borderTopColor: '#DCDCDC',
};
const accordionStyle = {
  width: '100%',
  borderRadius: 5,
  marginBottom: 0,
};

const accordionSummaryStyle = {
  backgroundColor: '#F4F6F8',
  borderBottomColor: '#DCDCDC',
  borderBottomStyle: 'solid',
  borderRadius: 1,
  marginBottom: 0,
};

export default AccordionSurveyTabs;
