import {
  List,
  TextField,
  Pagination,
  useTranslate,
  FunctionField,
  SelectField,
  useGetIdentity,
} from 'react-admin';

import {
  Datagrid,
  ListCreator,
  CreateButton,
  ListToolbar,
  Empty,
  BoxStatus,
} from '../../components/list';
import Filter from '../../components/filter';
import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions';
import UrlField from '../../components/common/UrlField';
import CompleteSurveyButton from '../../components/survey/CompleteSurveyButton';
import { useMediaQuery } from '@material-ui/core';
import ResponsiveList from './ResponsiveList';

const SurveyList = (props) => {
  const translate = useTranslate();
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const { identity } = useGetIdentity();
  const role = identity?.role;
  const isAdmin = role === 'Admin' || role === 'Super Admin';
  // eslint-disable-next-line react/jsx-key
  const SurveyTopActions = isAdmin ? [<CreateButton />] : [];
  const root = 'resources.surveys.fields';
  return (
    <List
      actions={false}
      pagination={
        <Pagination actions={TablePaginationActions} rowsPerPageOptions={[]} />
      }
      empty={
        <Empty
          actions={SurveyTopActions}
          customTexts={
            isAdmin
              ? {}
              : {
                  title: translate('resources.surveys.noAvailable'),
                  message: '',
                }
          }
        />
      }
      {...props}
    >
      <>
        <ListCreator actions={SurveyTopActions} />
        <ListToolbar
          actions={
            <Filter
              filters={[
                {
                  label: translate(root + '.company'),
                  source: 'companyName',
                  type: 'text',
                },
              ]}
            />
          }
        />
        {isXSmall ? (
          <ResponsiveList />
        ) : (
          <Datagrid>
            <UrlField source="id" urlPrefix="surveys" />
            <TextField
              source="Location.Client.companyName"
              label={root + '.companyName'}
            />
            <TextField source="Location.name" label={root + '.locationName'} />
            <FunctionField
              render={(data) => `${data.progress} %`}
              source="progress"
            />
            <SelectField
              source="status"
              choices={[
                {
                  id: 'CREATED',
                  name: translate('choices.created'),
                  variant: 'warning',
                },
                {
                  id: 'INPROGRESS',
                  name: translate('choices.inProgress'),
                  variant: 'info1',
                },
                {
                  id: 'COMPLETED',
                  name: translate('choices.completed'),
                },
              ]}
              optionText={<BoxStatus />}
            />
            <CompleteSurveyButton />
          </Datagrid>
        )}
      </>
    </List>
  );
};

export default SurveyList;
