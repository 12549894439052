import ApiKeyList from './list';
import ApiKeyEdit from './edit';
import ApiKeyCreate from './create';

const apiKeyResource = {
  list: ApiKeyList,
  create: ApiKeyCreate,
  edit: ApiKeyEdit,
};

export default apiKeyResource;
