function arrayToString(arr) {
  return arr.map((item) => {
    if (typeof item === 'object') {
      if (Array.isArray(item)) {
        return arrayToString(item);
      }
      return objectToString(item);
    }
    return item;
  });
}

export default function objectToString(obj, ndeep) {
  if (obj == null) {
    return String(obj);
  }
  switch (typeof obj) {
    case 'string':
      return '"' + obj + '"';
    case 'function':
      return obj.name || obj.toString();
    case 'object':
      var indent = Array(ndeep || 1).join(''),
        isArray = Array.isArray(obj);
      return (
        '{['[+isArray] +
        (isArray
          ? arrayToString(obj)
          : Object.keys(obj)
              .map(function (key) {
                return (
                  '' +
                  indent +
                  key +
                  ': ' +
                  objectToString(obj[key], (ndeep || 1) + 1)
                );
              })
              .join(',')) +
        '' +
        indent +
        '}]'[+isArray]
      );
    default:
      return obj.toString();
  }
}
