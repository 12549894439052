import {
  List,
  TextField,
  Pagination,
  SelectField,
  useTranslate,
} from 'react-admin';
import {
  Datagrid,
  ListCreator,
  CreateButton,
  ListToolbar,
  Empty,
  BoxStatus,
} from '../../components/list';
import Filter from '../../components/filter';
import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions';
import UrlField from '../../components/common/UrlField';

const LicenseTopActions = [<CreateButton key={1} />];

const UserList = (props) => {
  const translate = useTranslate();
  return (
    <List
      actions={false}
      pagination={
        <Pagination actions={TablePaginationActions} rowsPerPageOptions={[]} />
      }
      empty={<Empty actions={LicenseTopActions} />}
      {...props}
    >
      <>
        <ListCreator actions={LicenseTopActions} />
        <ListToolbar
          actions={
            <Filter
              filters={[
                {
                  label: 'Status',
                  source: 'status',
                  type: 'select',
                  choices: [
                    {
                      id: 'ACTIVE',
                      label: translate('choices.active'),
                    },
                    {
                      id: 'BLOCKED',
                      label: translate('choices.blocked'),
                    },
                  ],
                },
                {
                  label: 'Roles',
                  source: 'rolesIds',
                  type: 'reference',
                  reference: 'roles',
                },
              ]}
            />
          }
        />
        <Datagrid>
          <UrlField source="name" urlPrefix="users" />
          <TextField source="role" />
          <SelectField
            source="status"
            choices={[
              { id: 'ACTIVE', name: translate('choices.active') },
              {
                id: 'BLOCKED',
                name: translate('choices.blocked'),
                variant: 'negative',
              },
            ]}
            optionText={<BoxStatus />}
          />
        </Datagrid>
      </>
    </List>
  );
};

export default UserList;
