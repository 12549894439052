import { useEffect, useState } from 'react';
import { useListContext, useTranslate, Button } from 'react-admin';
import { useLocation } from 'react-router-dom';
import { InputAdornment, TextField, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

import { ContainerBox, FilterBox, FilterItem, CloseIconButton } from './styles';
import BooleanFilter from './boolean';
import DateFilter from './date';
import ReferenceFilter from './reference';
import SelectFilter from './select';
import NumberFilter from './number';
import TextFilter from './text';

const Filter = ({ filters = [] }) => {
  const location = useLocation();
  const { setFilters, filterValues } = useListContext();
  const [referenceValue, setReference] = useState(null);
  const [value, setValue] = useState(
    filters.filter((a) => Object.keys(filterValues).includes(a.source))
  );
  const translate = useTranslate();

  const handleFilter = (source, filterValue) => {
    const items = [
      ...value,
      filters.find((filter) => filter.source === source),
    ];
    setFilters({ ...filterValues, [source]: filterValue });
    setValue([...new Map(items.map((item) => [item.source, item])).values()]);
  };

  const handleSearch = (value) => {
    setFilters({ ...filterValues, q: value });
  };

  const handleClearFilter = () => {
    setFilters({});
    setReference(null);
    setValue([]);
  };

  const onRemove = (source) => {
    const allFilters = filterValues;
    const allValues = value.filter((i) => i.source !== source);
    delete allFilters[source];
    setFilters(allFilters);
    setValue(allValues);
  };

  const onRemoveArray = (source, id) => {
    const items = filterValues[source].filter((i) => i !== id);
    setFilters({ ...filterValues, [source]: items });
    setReference(referenceValue.filter((i) => i.id !== id));
  };

  useEffect(() => {
    if (Object.keys(filterValues).length !== 0) {
      const currentFilters = JSON.parse(localStorage.getItem('filters'));
      localStorage.setItem(
        'filters',
        JSON.stringify({
          ...currentFilters,
          [location.pathname.split('/')[1]]: filterValues,
        })
      );
    }
  }, [filterValues, location.pathname]);

  return (
    <div style={{ width: '100%', marginTop: '12px' }}>
      <TextField
        label="Search"
        variant="outlined"
        size="small"
        defaultValue={filterValues.q ?? ''}
        fullWidth
        onChange={(e) => handleSearch(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <ContainerBox>
        {(value?.length === 0 || value === undefined) && (
          <Typography
            style={{ display: 'flex', marginRight: '10px', color: '#ABABAB' }}
          >
            {translate('buttons.noFilter')}
          </Typography>
        )}
        <div style={{ display: 'flex' }}>
          {value?.map((item) => {
            let text;
            item?.type === 'select'
              ? (text = item?.choices?.find(
                  (i) => i.id === filterValues[item.source]
                )?.label)
              : item?.type === 'date'
              ? (text = new Date(filterValues[item.source]).toDateString())
              : item?.type === 'reference'
              ? (text = referenceValue)
              : item?.type === 'boolean'
              ? (text = filterValues[item.source]?.toString())
              : item?.type === 'number'
              ? (text = filterValues[item.source])
              : item?.type === 'text'
              ? (text = filterValues[item.source])
              : (text = null);

            if (Array.isArray(text)) {
              return text.map((i) => (
                <FilterItem key={item.source}>
                  {item.label}: {i.label}
                  <CloseIconButton
                    onClick={() => onRemoveArray(item.source, i.id)}
                  />
                </FilterItem>
              ));
            } else {
              return (
                <FilterItem key={item.source}>
                  {item.label}: {text}
                  <CloseIconButton onClick={() => onRemove(item.source)} />
                </FilterItem>
              );
            }
          })}
        </div>
        <Button
          startIcon={<ClearIcon />}
          label={translate('buttons.clearFilter')}
          disabled={value?.length === 0 || value === undefined}
          onClick={handleClearFilter}
        />
      </ContainerBox>
      <FilterBox>
        {filters.map((filter) =>
          filter.type === 'select' ? (
            <SelectFilter
              key={filter.source}
              filter={filter}
              onFilter={handleFilter}
            />
          ) : filter.type === 'reference' ? (
            <ReferenceFilter
              key={filter.source}
              filter={filter}
              onFilter={handleFilter}
              values={referenceValue}
              setReference={setReference}
              defaultValues={filterValues[filter.source]}
            />
          ) : filter.type === 'date' ? (
            <DateFilter
              key={filter.source}
              filter={filter}
              onFilter={handleFilter}
            />
          ) : filter.type === 'boolean' ? (
            <BooleanFilter
              key={filter.source}
              filter={filter}
              onFilter={handleFilter}
            />
          ) : filter.type === 'number' ? (
            <NumberFilter
              key={filter.source}
              filter={filter}
              onFilter={handleFilter}
            />
          ) : filter.type === 'text' ? (
            <TextFilter
              key={filter.source}
              filter={filter}
              onFilter={handleFilter}
            />
          ) : null
        )}
      </FilterBox>
    </div>
  );
};

export default Filter;
