import { List, ListItem, Box, Divider, Typography } from '@material-ui/core';
import { useListContext, useTranslate } from 'react-admin';
import { BoxStatus } from '../../components/list';

// date to DD/MM/YYYY with leading zeros
const formatDate = (date) => {
  const d = new Date(date);
  const day = d.getDate();
  const month = d.getMonth() + 1;
  const year = d.getFullYear();
  return `${day < 10 ? '0' + day : day}/${
    month < 10 ? '0' + month : month
  }/${year}`;
};

const CustomerList = (props) => {
  const translate = useTranslate();
  const { data, ids, loaded } = useListContext(props);
  const variants = {
    ACTIVE: { id: 'ACTIVE', name: translate('choices.active') },
    EXPIRED: {
      id: 'EXPIRED',
      name: translate('choices.expired'),
      variant: 'negative',
    },
    PENDING: {
      id: 'PENDING',
      name: translate('choices.pending'),
      variant: 'warning',
    },
  };
  if (!loaded) {
    return null;
  }
  return (
    <List>
      {ids.map((id) => {
        const record = data[id];

        return (
          <>
            <ListItem
              key={id}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  flexWrap: 'wrap',
                  gap: '1rem',
                }}
              >
                <Box>
                  <Typography
                    style={{ display: 'inline-block', fontWeight: 700 }}
                  >
                    {translate('resources.licenses.fields.validFrom')}:{' '}
                  </Typography>
                  {' ' + formatDate(record.validFrom)}
                </Box>
                <Box>
                  <Typography
                    style={{ display: 'inline-block', fontWeight: 700 }}
                  >
                    {translate('resources.licenses.fields.validUntil')}:{' '}
                  </Typography>
                  {' ' + formatDate(record.validUntil)}
                </Box>
              </Box>
              <BoxStatus record={variants[record.status]} />
            </ListItem>
            <Divider />
          </>
        );
      })}
    </List>
  );
};

export default CustomerList;
