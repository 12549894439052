import { Typography } from '@material-ui/core';
import { useTranslate } from 'react-admin';

export default function TypographyTranslated({ label, ...props }) {
  const translate = useTranslate();

  const getLabel = () =>
    Array.isArray(label) ? translate(...label) : translate(label);

  return <Typography {...props}>{getLabel()}</Typography>;
}

TypographyTranslated.propTypes = Typography.propTypes;
