import { useRedirect, useTranslate } from 'react-admin';
import { useLocation, useParams } from 'react-router-dom';
import { Button } from '@material-ui/core';

const getindex = (pathname, id) => {
  if (pathname.endsWith('/')) pathname = pathname.slice(0, -1);

  const index = pathname.endsWith(id)
    ? pathname.split('/').shift().endsWith(id)
      ? parseInt(id)
      : 0
    : parseInt(pathname.split('/').pop());
  return index;
};

export const NextTabButton = ({ enabledTabs, numberOfTabs }) => {
  const redirect = useRedirect();
  const translate = useTranslate();
  const { pathname } = useLocation();
  const { id } = useParams();
  const index = getindex(pathname, id);
  return (
    <>
      {index < numberOfTabs - 1 && (
        <Button
          variant="contained"
          color="primary"
          disabled={index >= enabledTabs || index >= numberOfTabs - 1}
          onClick={() => {
            redirect(`/surveys/fill/${id}/${index + 1 || ''}`);
          }}
        >
          {translate('buttons.next')}
        </Button>
      )}
    </>
  );
};

export const PreviousTabButton = () => {
  const redirect = useRedirect();
  const translate = useTranslate();
  const { pathname } = useLocation();
  const { id } = useParams();

  const index = getindex(pathname, id);

  return (
    <Button
      variant="contained"
      color="secondary"
      disabled={index <= 0}
      onClick={() => {
        redirect(`/surveys/fill/${id}/${index - 1 || ''}`);
      }}
    >
      {translate('buttons.previous')}
    </Button>
  );
};
