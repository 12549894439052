import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import { useTranslate } from 'react-admin';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.primary.lighten[2],
  },
}));

export default function Placeholder({ horizontal, disabled }) {
  const classes = useStyles();
  const translate = useTranslate();

  const boxStyles = horizontal
    ? {
        display: 'flex',
        alignItems: 'center',
        placeContent: 'center',
        gap: 30,
        height: '100%',
      }
    : {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 5,
      };

  return (
    <Box sx={boxStyles}>
      {!disabled && (
        <>
          <CloudUploadOutlinedIcon
            style={{ fontSize: 60 }}
            className={classes.icon}
          />
          <Typography style={{ marginTop: 0, fontSize: 20, fontWeight: 700 }}>
            {translate('file.placeholder1')}
          </Typography>
          <Typography variant="button" color="primary" style={{ marginTop: 0 }}>
            {translate('file.placeholder2')}
          </Typography>
        </>
      )}
    </Box>
  );
}
