const MuiOutlinedInput = (palette) => ({
  root: {
    background: 'white',
    '& $notchedOutline': {
      borderColor: palette.black.lighten[2],
    },
    '&$focused $notchedOutline': {
      borderColor: palette.primary.main,
      borderWidth: 1,
    },
    '&$error $notchedOutline': {
      borderColor: palette.red.main,
      borderWidth: 1,
    },
    '&$disabled $notchedOutline': {
      borderColor: palette.black.lighten[2],
    },
  },
  inputMarginDense: {
    paddingTop: 16,
    paddingLeft: 16,
    paddingBottom: 16,
  },
});

export default MuiOutlinedInput;
