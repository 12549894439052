import {
  Create,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
  useTranslate,
} from 'react-admin';
import { FormContainer } from '../../components/common';
import TitleViews from '../../components/common/TitleViews';
import { CreateForm } from '../../components/create';

const ProductCreate = (props) => {
  const translate = useTranslate();
  const root = 'resources.products';
  return (
    <>
      <TitleViews
        subsectionTitle={translate(`${root}.breadcrumb.createTitle`)}
        title={`${translate('labels.create')} ${translate(`${root}.title`)}`}
      />
      <Create component={FormContainer} {...props}>
        <CreateForm variant="outlined" resettable>
          <TextInput source="name" validate={[required()]} />
          <ReferenceInput source="latestVersion" reference="versions">
            <SelectInput optionText="name" validate={required()} />
          </ReferenceInput>
          <TextInput
            variant="outlined"
            source="description"
            options={{ multiline: true, minRows: 4 }}
            fullWidth={true}
            validate={required()}
            resettable
          />
        </CreateForm>
      </Create>
    </>
  );
};

export default ProductCreate;
