import { Box as MuiBox, styled } from '@material-ui/core';
import { SimpleForm } from 'react-admin';
import { InputContainer, TitleViews } from '../common';
import Toolbar from './Toolbar';
import AssignedUsersForm from '../../components/survey/AssignedUsersForm';
import SurveyTemplateTab from '../../components/surveyTemplate/SurveyTemplateTab';
import AccordionSurveyTabs from '../survey/AccordionSurveyTabs';

const Box = styled(MuiBox)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  [theme.breakpoints.up('sm')]: {
    padding: 20,
  },
}));

const EditForm = ({
  children,
  toolbarProps = {},
  withoutBreadCrumb,
  titleProps = {},
  ...props
}) => (
  <Box>
    {!withoutBreadCrumb && <TitleViews {...titleProps} />}
    <SimpleForm
      toolbar={<Toolbar {...toolbarProps} />}
      style={{ background: 'white' }}
      {...props}
    >
      <InputContainer>{children}</InputContainer>
      {props.type === 'surveyEdit' && <AssignedUsersForm type="surveyEdit" />}
      {props.type === 'surveyEdit' && <AccordionSurveyTabs />}
      {props.type === 'surveyTemplateEdit' && (
        <SurveyTemplateTab type="surveyTemplateEdit" />
      )}
    </SimpleForm>
  </Box>
);

export default EditForm;
