import { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { SearchText } from './styles';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = (
  <CheckBoxIcon fontSize="small" style={{ color: '#35C4EC' }} />
);

const ReferenceFilter = ({
  filter,
  onFilter,
  setReference,
  values,
  defaultValues,
}) => {
  const [choices, setChoices] = useState([]);

  useState(() => {
    async function updateChoices() {
      const data = await fetch(
        `${process.env.REACT_APP_API}/${filter.reference}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      const jsonData = await data.json();
      const allChoices = jsonData.map((i) => ({
        id: i.id,
        label: i.name ? i.name : i.firstName || i.id,
      }));
      setChoices(allChoices);
      Array.isArray(defaultValues) &&
        setReference(allChoices.filter((i) => defaultValues.includes(i.id)));
    }

    updateChoices();
  }, []);

  return (
    <Autocomplete
      id="custom-input-demo"
      multiple={filter.multiple}
      disableCloseOnSelect={filter.multiple}
      options={choices}
      value={values ?? []}
      onChange={(_, n) => {
        onFilter(filter.source, filter.multiple ? n.map((i) => i.id) : n.id);
        setReference(filter.multiple ? n : n.label);
      }}
      renderInput={(props) => (
        <div
          style={{ display: 'flex', alignItems: 'center' }}
          ref={props.InputProps.ref}
        >
          <SearchText {...props.inputProps}>
            {filter.label}
            <KeyboardArrowDownIcon style={{ paddingLeft: '16px' }} />
          </SearchText>
        </div>
      )}
      renderOption={(props, option, { selected }) =>
        filter.multiple ? (
          <li {...props} style={{ padding: 0 }}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: '8px' }}
              checked={selected}
            />
            {option.label}
          </li>
        ) : (
          <li {...props}>{option.label}</li>
        )
      }
    />
  );
};

export default ReferenceFilter;
