/* eslint-disable no-unused-vars */
import { Button } from '@material-ui/core';
import { useRedirect } from 'ra-core';
import { useTranslate } from 'react-admin';

export default function CancelButton(props) {
  const { basePath, redirect, onCancel, label, ...rest } = sanitizeProps(props);
  const translate = useTranslate();
  const redirectTo = useRedirect();
  const handleClick = (e) => {
    e.preventDefault();
    redirect && redirectTo(basePath);
    onCancel && onCancel();
  };
  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={handleClick}
      disableElevation
      title={label || translate('buttons.cancel')}
      {...rest}
    >
      {label || translate('buttons.cancel')}
    </Button>
  );
}

const sanitizeProps = ({
  handleSubmit,
  submitOnEnter,
  handleSubmitWithRedirect,
  onSave,
  invalid,
  saving,
  pristine,
  ...rest
}) => rest;
