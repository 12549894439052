import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Box, makeStyles } from '@material-ui/core';
import MyUserMenu from '../layout/userMenu';
import Logout from './LogoutComponent';
import { LogoWithTitle } from '../common';

const useStyles = makeStyles(
  () => ({
    appBar: {
      position: 'inherit',
    },
    toolbar: {
      padding: '0 24px',
      flex: 1,
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
  { name: 'RaAppBar' }
);

const MyAppBar = (props) => {
  const classes = useStyles();

  return (
    <AppBar {...props} className={classes.appBar}>
      <Toolbar disableGutters variant="dense" className={classes.toolbar}>
        <LogoWithTitle showTitle height={60} />
        <Box sx={{ display: 'flex' }}>
          <MyUserMenu logout={<Logout redirectTo="/customerLogin" />} />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default MyAppBar;
