import {
  List,
  TextField,
  Pagination,
  DateField,
  ReferenceField,
  useTranslate,
} from 'react-admin';
import {
  Datagrid,
  ListCreator,
  ListToolbar,
  Empty,
} from '../../components/list';
import Filter from '../../components/filter';
import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions';
import UrlField from '../../components/common/UrlField';

const LicenseTopActions = [];

const LicenseList = (props) => {
  const translate = useTranslate();
  const root = 'resources.devices.fields';
  return (
    <List
      actions={false}
      pagination={
        <Pagination actions={TablePaginationActions} rowsPerPageOptions={[]} />
      }
      empty={<Empty actions={LicenseTopActions} />}
      {...props}
    >
      <>
        <ListCreator actions={LicenseTopActions} />
        <ListToolbar
          actions={
            <Filter
              filters={[
                {
                  label: translate(root + '.License'),
                  source: 'LicenseId',
                  type: 'reference',
                  reference: 'licenses',
                },
                {
                  label: translate(root + '.hardwareVersion'),
                  source: 'hardwareVersion__iLike',
                  type: 'text',
                },
                {
                  label: translate(root + '.softwareVersion'),
                  source: 'softwareVersion__iLike',
                  type: 'text',
                },
                {
                  label: translate(root + '.dateFirstActivation__gte'),
                  source: 'dateFirstActivation__gte',
                  type: 'date',
                },
                {
                  label: translate(root + '.dateFirstActivation__lte'),
                  source: 'dateFirstActivation__lte',
                  type: 'date',
                },
              ]}
            />
          }
        />
        <Datagrid>
          <TextField source="name" />
          <TextField source="hardwareVersion" />
          <TextField source="softwareVersion" />
          <ReferenceField source="LicenseId" reference="licenses">
            <TextField source="id" />
          </ReferenceField>
          <DateField source="dateFirstActivation" />
          <UrlField
            source="id"
            urlPrefix="devices"
            text={translate(root + '.viewDetails')}
            isButton
            sortable={false}
          />
        </Datagrid>
      </>
    </List>
  );
};

export default LicenseList;
