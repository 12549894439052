import { useState } from 'react';
import {
  useTranslate,
  useNotify,
  usePermissions,
  useRedirect,
} from 'react-admin';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { httpClient } from '../../providers/dataProvider';
import { Typography } from '@mui/material';
import { TypographyTranslated } from '../../components/common';
import ApiKeyDialog from './ApiKeyDialog';
import checkPermission from '../../utils/checkPermission';

export default function ResetApiKeyButton({ id }) {
  const [open, setOpen] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [data, setData] = useState(false);
  const translate = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();

  const { permissions } = usePermissions();
  const apiKeyAccess = checkPermission({
    method: 'PUT',
    url: 'apikeys',
    permissions,
  });

  const handleClose = () => setOpenConfirmation(false);

  const resetApiKey = async () => {
    try {
      const response = await httpClient(
        `${process.env.REACT_APP_API}/apikeys/${id}/reset`,
        {
          method: 'PUT',
        }
      );
      setData(response.json);
      setOpenConfirmation(false);
      setOpen(true);
    } catch (error) {
      notify(translate('resources.apikeys.error.create'), { type: 'warning' });
    }
  };

  const handleClick = () => {
    setOpenConfirmation(true);
  };

  const onContinue = () => {
    notify(translate('resources.apikeys.success'));
    redirect('/apikeys');
  };

  return (
    <>
      {apiKeyAccess && (
        <Button
          disableElevation
          onClick={handleClick}
          variant="contained"
          color="secondary"
        >
          {translate('resources.apikeys.button')}
        </Button>
      )}

      <Dialog onClose={handleClose} open={openConfirmation}>
        <DialogTitle disableTypography>
          <TypographyTranslated
            style={{ textAlign: 'center', paddingTop: 24 }}
            variant="h5"
            label="resources.apikeys.button"
          />
        </DialogTitle>
        <DialogContent>
          <Typography>{translate('resources.apikeys.reset')}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClose}
            disableElevation
          >
            {translate('buttons.cancel')}
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={resetApiKey}
            disableElevation
          >
            {translate('buttons.continue')}
          </Button>
        </DialogActions>
      </Dialog>

      <ApiKeyDialog
        apiKey={data.apiKey}
        open={open}
        setOpen={setOpen}
        onContinue={onContinue}
      />
    </>
  );
}
