import {
  Create,
  required,
  email,
  SelectInput,
  TextInput,
  useTranslate,
  ReferenceInput,
} from 'react-admin';
import { FormContainer } from '../../components/common';
import TitleViews from '../../components/common/TitleViews';
import { CreateForm } from '../../components/create';

const validateUserCreation = (values) => {
  const errors = {};
  if (values.password !== values.confirmPassword) {
    errors.password = 'errors.passwordNotMatch';
    errors.confirmPassword = 'errors.passwordNotMatch';
  }
  return errors;
};

const UserCreate = (props) => {
  const translate = useTranslate();

  return (
    <>
      <TitleViews
        title={`${translate('labels.create')} ${translate(
          'resources.adminusers.title'
        )}`}
        sectionTitle={translate('resources.adminusers.breadcrumb.section')}
        subsectionTitle={translate(
          'resources.adminusers.breadcrumb.createTitle'
        )}
      />
      <Create
        component={FormContainer}
        // eslint-disable-next-line no-unused-vars
        transform={({ confirmPassword, ...data }) => data}
        {...props}
      >
        <CreateForm variant="outlined" validate={validateUserCreation}>
          <TextInput
            source="email"
            fullWidth
            validate={[required(), email()]}
          />
          <TextInput source="firstName" validate={required()} />
          <TextInput source="lastName" validate={required()} />
          <SelectInput
            source="status"
            choices={[
              { id: 'ACTIVE', name: 'choices.active' },
              { id: 'BLOCKED', name: 'choices.blocked' },
            ]}
            validate={[required()]}
          />
          <ReferenceInput source="rolesIds" reference="roles">
            <SelectInput optionText="name" validate={required()} />
          </ReferenceInput>
        </CreateForm>
      </Create>
    </>
  );
};
export default UserCreate;
