import { TextField as MuiTextField, styled } from '@material-ui/core';

export const TextField = styled(MuiTextField)(({ theme }) => ({
  '& label.Mui-focused': {
    color: theme.palette.primary.main,
  },
  '& label.Mui-error': {
    color: theme.palette.error.main,
  },
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
      borderWidth: '1px',
    },
    '&.Mui-error fieldset': {
      borderColor: theme.palette.error.main,
    },
  },
  '& .Mui-error': {
    borderColor: theme.palette.error.main,
  },
}));
