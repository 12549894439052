import {
  List,
  TextField,
  Pagination,
  useTranslate,
  SelectField,
} from 'react-admin';
import {
  Datagrid,
  ListCreator,
  CreateButton,
  ListToolbar,
  Empty,
  BoxStatus,
} from '../../components/list';
import Filter from '../../components/filter';
import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions';
import UrlField from '../../components/common/UrlField';
// eslint-disable-next-line react/jsx-key
const SurveyTopActions = [<CreateButton />];

const SurveyTemplateList = (props) => {
  const translate = useTranslate();
  const root = 'resources.clients.fields';

  return (
    <List
      actions={false}
      pagination={
        <Pagination actions={TablePaginationActions} rowsPerPageOptions={[]} />
      }
      empty={<Empty actions={SurveyTopActions} />}
      {...props}
    >
      <>
        <ListCreator actions={SurveyTopActions} title="Survey Templates" />
        <ListToolbar
          actions={
            <Filter
              filters={[
                {
                  label: translate(root + '.status'),
                  source: 'status',
                  type: 'select',
                  choices: [
                    {
                      id: 'DRAFT',
                      label: translate('choices.draf'),
                    },
                    {
                      id: 'PUBLISHED',
                      label: translate('choices.published'),
                    },
                  ],
                },
              ]}
            />
          }
        />
        <Datagrid>
          <UrlField source="id" urlPrefix="surveytemplates" />
          <TextField source="name" label="Name" />
          <SelectField
            source="status"
            choices={[
              {
                id: 'DRAFT',
                name: translate('choices.draf'),
                variant: 'warning',
              },
              {
                id: 'PUBLISHED',
                name: translate('choices.published'),
              },
            ]}
            optionText={<BoxStatus />}
          />
        </Datagrid>
      </>
    </List>
  );
};

export default SurveyTemplateList;
