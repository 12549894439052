import { Toolbar as RaToolbar, SaveButton, DeleteButton } from 'react-admin';
import { CancelButton } from '../common';
import { styled } from '@material-ui/styles';
import { cloneElement, Fragment } from 'react';

const ToolBar = styled(RaToolbar)({
  backgroundColor: 'inherit',
  columnGap: 16,
  paddingLeft: 16,
});

const EditToolbar = ({
  onCancel,
  loading,
  transform,
  saveButton,
  withoutDelete,
  withoutCancel,
  cancelRedirect = true,
  customButton,
  cancelButtonProps,
  ...props
}) => (
  <ToolBar {...props}>
    {!withoutDelete && <DeleteButton color="secondary" size="medium" />}
    {!withoutCancel && (
      <CancelButton
        redirect={cancelRedirect}
        style={{ width: 74 }}
        size="medium"
        onCancel={onCancel}
        {...cancelButtonProps}
      />
    )}
    {customButton}
    {cloneElement(saveButton, {
      color: 'primary',
      style: {
        width: 82,
      },
      transform,
      disabled: loading,
      icon: <Fragment />,
      variant: 'contained',
    })}
  </ToolBar>
);

EditToolbar.defaultProps = {
  saveButton: <SaveButton />,
};
export default EditToolbar;
