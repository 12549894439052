import {
  Create,
  DateInput,
  ReferenceInput,
  required,
  SelectInput,
  useTranslate,
  NumberInput,
  BooleanInput,
  minValue,
} from 'react-admin';
import { FormContainer, InputContainer } from '../../components/common';
import TitleViews from '../../components/common/TitleViews';
import { CreateForm } from '../../components/create';
import { ClientSelect } from './ClientSelect';

const LicenseCreate = (props) => {
  const translate = useTranslate();
  return (
    <>
      <TitleViews
        title={`${translate('labels.create')} ${translate(
          'resources.licenses.title'
        )}`}
        subsectionTitle={translate('resources.licenses.breadcrumb.createTitle')}
      />
      <Create component={FormContainer} {...props}>
        <CreateForm variant="outlined" resettable>
          <ClientSelect />
          <InputContainer fullWidth={false}>
            <DateInput source="validFrom" />
            <DateInput source="validUntil" />
          </InputContainer>
          <SelectInput
            source="status"
            choices={[
              { id: 'ACTIVE', name: 'Active' },
              { id: 'EXPIRED', name: 'Expired' },
              { id: 'PENDING', name: 'Pending' },
            ]}
            validate={[required()]}
          />
          <ReferenceInput source="LicenseTypeId" reference="licenseTypes">
            <SelectInput optionText="name" validate={required()} />
          </ReferenceInput>
          <NumberInput
            source="lockCapacity"
            min={0}
            validate={[required(), minValue(0)]}
          />
          <BooleanInput source="isOnPremise" defaultValue={false} />
        </CreateForm>
      </Create>
    </>
  );
};

export default LicenseCreate;
