import {
  SelectInput,
  useTranslate,
  NumberInput,
  TextInput,
  useGetIdentity,
  SaveButton,
} from 'react-admin';
import { CustomEdit, EditForm } from '../../components/edit';

const SurveyEdit = (props) => {
  const translate = useTranslate();
  const { identity } = useGetIdentity();
  const role = identity?.role;
  const isAdmin = role === 'Admin' || role === 'Super Admin';
  const transform = (data) => {
    let newData = {
      id: data.id,
    };

    if (data.AdminUsers) {
      newData.users = data.AdminUsers.map((user) => {
        return {
          id: user.id,
        };
      });
    }
    return newData;
  };

  return (
    <>
      <CustomEdit mutationMode="pessimistic" transform={transform} {...props}>
        <EditForm
          variant="outlined"
          ressetable
          titleProps={{
            subsectionTitle: translate(
              'resources.surveys.breadcrumb.editTitle'
            ),
          }}
          toolbarProps={{
            withoutDelete: !isAdmin,
            saveButton: isAdmin ? <SaveButton /> : <></>,
          }}
          type="surveyEdit"
        >
          <TextInput
            source="Location.Client.companyName"
            label="Company name"
            disabled
          />
          <TextInput source="Location.name" label="Location name" disabled />
          <NumberInput disabled source="progress" label="Progress (%)" />
          <SelectInput
            disabled
            source="status"
            label="Status"
            choices={[
              { id: 'CREATED', name: 'Created' },
              { id: 'INPROGRESS', name: 'In Progress' },
              { id: 'COMPLETED', name: 'Completed' },
            ]}
          />
        </EditForm>
      </CustomEdit>
    </>
  );
};

export default SurveyEdit;
