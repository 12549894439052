import { Children, cloneElement, isValidElement, useState } from 'react';
import { useGetIdentity } from 'ra-core';
import { Menu, Button, Avatar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useTranslate } from 'ra-core';

const useStyles = makeStyles(
  () => ({
    user: {},
    userButton: {
      textTransform: 'none',
    },
    avatar: {
      width: 40,
      height: 40,
    },
  }),
  { name: 'RaUserMenu' }
);

const AnchorOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
};

const TransformOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

const UserMenu = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { identity, loaded } = useGetIdentity();
  const classes = useStyles(props);
  const translate = useTranslate();
  const { children, logout } = props;
  const shouldHaveMenu = children || logout;
  const open = Boolean(anchorEl);

  const handleMenu = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  let avatar = null;
  let firstName = '';

  if (identity) {
    avatar = identity.avatar;
    firstName = identity.firstName;
  }

  return (
    <div className={classes.user}>
      {loaded && (
        <Button
          className={classes.userButton}
          color="inherit"
          startIcon={
            avatar ? (
              <Avatar className={classes.avatar} src={avatar} alt={firstName} />
            ) : (
              <AccountCircle className={classes.avatar} color="primary" />
            )
          }
          endIcon={
            shouldHaveMenu ? (
              open ? (
                <ArrowUpIcon color="action" />
              ) : (
                <ArrowDownIcon color="action" />
              )
            ) : null
          }
          onClick={handleMenu}
        >
          <Typography variant="button" color="textPrimary">
            {`${translate('appBar.greetings')} ${firstName}!`}
          </Typography>
        </Button>
      )}
      {shouldHaveMenu && (
        <Menu
          id="menu-appbar"
          disableScrollLock
          anchorEl={anchorEl}
          anchorOrigin={AnchorOrigin}
          transformOrigin={TransformOrigin}
          getContentAnchorEl={null}
          open={open}
          onClose={handleClose}
        >
          {Children.map(children, (menuItem) =>
            isValidElement(menuItem)
              ? cloneElement(menuItem, {
                  onClick: handleClose,
                })
              : null
          )}
          {logout}
        </Menu>
      )}
    </div>
  );
};

export default UserMenu;
