import { EditButton as RaEditButton } from 'react-admin';
import { EditOutlined } from '@mui/icons-material';

const EditButton = ({ basePath, record }) => (
  <RaEditButton
    icon={<EditOutlined />}
    color="inherit"
    basePath={basePath}
    label=""
    record={record}
  />
);

export default EditButton;
