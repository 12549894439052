import { required, SelectInput, useTranslate, TextInput } from 'react-admin';
import { CustomEdit, EditForm } from '../../components/edit';

const SurveyTemplateEdit = (props) => {
  const translate = useTranslate();

  const transform = (data) => {
    console.log('data--->', data);
    return {
      ...data,
    };
  };
  return (
    <>
      <CustomEdit mutationMode="pessimistic" transform={transform} {...props}>
        <EditForm
          variant="outlined"
          ressetable
          titleProps={{
            subsectionTitle: translate(
              'resources.surveys.breadcrumb.editTitle'
            ),
          }}
          type="surveyTemplateEdit"
        >
          <TextInput
            source="name"
            label="Company Name"
            validate={[required()]}
          />
          <SelectInput
            source="status"
            label="Status"
            choices={[
              { id: 'DRAFT', name: 'Draft' },
              { id: 'PUBLISHED', name: 'Published' },
            ]}
            validate={[required()]}
          />
        </EditForm>
      </CustomEdit>
    </>
  );
};

export default SurveyTemplateEdit;
