import { useRecordContext, Link, useTranslate } from 'react-admin';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';

export default function ClientListExpand() {
  const translate = useTranslate();
  const record = useRecordContext();
  const data = record?.Locations?.map((location) => location);

  return (
    <TableContainer style={{ marginTop: '-6px', marginBottom: '-6px' }}>
      <Table>
        {record?.Locations?.length > 0 && (
          <TableHead>
            <TableRow>
              <TableCell>Location Name</TableCell>
              <TableCell>Country</TableCell>
              <TableCell>State</TableCell>
              <TableCell>City</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {record?.Locations?.length === 0 ? (
            <TableRow
              hover
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">No locations available</TableCell>
            </TableRow>
          ) : (
            data?.map((row) => (
              <TableRow
                key={row.id}
                hover
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.country}</TableCell>
                <TableCell>{row.state}</TableCell>
                <TableCell>{row.city}</TableCell>
                <TableCell>
                  <Link
                    to={
                      row?.Surveys?.length > 0
                        ? {
                            pathname: `surveys/${row?.Surveys[0].id}/show`,
                          }
                        : {
                            pathname: 'surveys/create',
                            state: {
                              locationId: row.id,
                              locationName: row.name,
                              clientId: record.id,
                            },
                          }
                    }
                  >
                    {row?.Surveys?.length > 0
                      ? translate('resources.surveys.viewSurvey')
                      : translate('resources.surveys.createSurvey')}
                  </Link>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
