import { Box, makeStyles, Typography } from '@material-ui/core';
import classnames from 'classnames';
const useStyles = makeStyles((theme) => ({
  negative: {
    backgroundColor: theme.palette.red.lighten[0],
    color: theme.palette.red.main,
  },
  positive: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  },
  info1: {
    backgroundColor: theme.palette.primary.lighten[1],
    color: theme.palette.primary.main,
  },
  info2: {
    backgroundColor: theme.palette.primary.lighten[2],
    color: theme.palette.primary.main,
  },
  info3: {
    backgroundColor: theme.palette.primary.lighten[3],
    color: theme.palette.primary.contrastText,
  },
  info4: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  warning: {
    backgroundColor: theme.palette.alert.lighten[0],
    color: theme.palette.alert.main,
  },
  box: {
    boxSizing: 'border-box',
    height: 'fit-content',
    width: 'fit-content',
    borderRadius: 4,
    padding: 10,
  },
}));

export default function Status({ record }) {
  const classes = useStyles();

  return (
    <Box
      className={classnames(classes.box, classes[record.variant || 'positive'])}
    >
      <Typography variant="body1">{record.name}</Typography>
    </Box>
  );
}

export function TextStatus({ children, isPositive }) {
  const classes = useStyles();
  return (
    <Box className={classnames(classes[isPositive ? 'positive' : 'negative'])}>
      <Typography variant="body1">{children}</Typography>
    </Box>
  );
}
