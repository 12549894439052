import { styled } from '@material-ui/styles';
import { Divider } from '@material-ui/core';
import { Children, cloneElement } from 'react';

export const InputContainer = styled('div')({
  display: 'grid',
  width: '100%',
  gridTemplateColumns: '1fr 1fr',
  columnGap: 24,
  '& .breakRow + *': {
    gridColumnStart: 1,
  },
  '& .fullWidth': {
    gridColumn: '1 / -1',
  },
});

const repeat = (str, n) => {
  return new Array(n + 1).join(str);
};

const InputContainerComponent = ({
  children,
  withEndDivider,
  fullWidth = true,
  columns = 2,
  ...rest
}) => {
  return (
    <>
      <InputContainer
        className={fullWidth && 'fullWidth'}
        style={{ gridTemplateColumns: repeat('1fr ', columns) }}
      >
        {Children.map(
          children,
          (child) =>
            child &&
            cloneElement(child, {
              ...rest,
              variant: 'outlined',
              ...child.props,
            })
        )}
      </InputContainer>
      {withEndDivider && (
        <Divider className="fullWidth" style={{ marginBottom: 26 }} />
      )}
    </>
  );
};

export default InputContainerComponent;
