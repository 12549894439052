import React, { useState } from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Typography from '@mui/material/Typography';
import { withStyles } from '@material-ui/core/styles';
import { useStyles } from './styles';
import { LogoWithTitle } from '../common';
import { useTranslate, useAuthProvider, useNotify } from 'react-admin';
import {
  Box,
  Button,
  ThemeProvider,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import customTheme from '../../themes/customTheme';
import { TextField } from './common';
import ShowPasswordInput from './ShowPasswordInput';
import Notification from '../layout/notification';

const steps = [
  {
    label: 'step1',
    fields: ['username', 'firstName', 'lastName', 'companyName', 'email'],
  },
  {
    label: 'step2',
    fields: ['country', 'state', 'city', 'address', 'zipcode'],
  },
  {
    label: 'step3',
    fields: ['password', 'confirmPassword'],
  },
  {
    label: 'finish',
  },
];

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#98E9FF',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#98E9FF',
    },
  },
  circle: {
    backgroundColor: 'currentColor',
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 1,
    borderRadius: 1,
  },
})(StepConnector);

function Input({
  name,
  formData = {},
  errors = {},
  handleFormChange,
  ...props
}) {
  const translate = useTranslate();
  return (
    <TextField
      id={name}
      name={name}
      label={translate(`register.fields.${name}`)}
      variant="outlined"
      error={!!errors[name]}
      helperText={errors[name]}
      value={formData[name] || ''}
      onChange={(e) => handleFormChange(e, name)}
      {...props}
    />
  );
}

function StepIcon(props) {
  const classes = useStyles();
  const { completed, icon, active } = props;

  return (
    <div
      className={`${classes.stepIcon} ${
        completed || active ? classes.stepIconCompleted : ''
      }`}
    >
      {icon}
    </div>
  );
}

const strongPassword =
  /(?=[^_]*\d)(?=[^_]*[a-z])(?=[^_]*[A-Z])(?=.*[@$!%*#?&^_-]).{8,20}/;

const translateErrors = {
  'Username already taken': 'username_taken',
};

function Register() {
  const translate = useTranslate();
  const authProvider = useAuthProvider();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const notify = useNotify();
  const theme = useTheme();
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    username: '',
    firstName: '',
    lastName: '',
    companyName: '',
    email: '',
    country: '',
    state: '',
    city: '',
    address: '',
    zipcode: '',
    password: '',
    confirmPassword: '',
  });
  const handleNext = () => {
    const newErrors = {};
    if (activeStep === 0) {
      const { username, firstName, lastName, companyName, email } = formData;
      !username && (newErrors.username = translate('register.error.username'));
      !firstName &&
        (newErrors.firstName = translate('register.error.firstName'));
      !lastName && (newErrors.lastName = translate('register.error.lastName'));
      !companyName &&
        (newErrors.companyName = translate('register.error.companyName'));
      email &&
        !email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i) &&
        (newErrors.email = translate('register.error.email_invalid'));
      !email && (newErrors.email = translate('register.error.email'));
    }
    if (activeStep === 1) {
      const { country, state, city, address, zipcode } = formData;
      !country && (newErrors.country = translate('register.error.country'));
      !state && (newErrors.state = translate('register.error.state'));
      !city && (newErrors.city = translate('register.error.city'));
      !address && (newErrors.address = translate('register.error.address'));
      !zipcode && (newErrors.zipcode = translate('register.error.zipcode'));
    }
    if (activeStep === 2) {
      const { password, confirmPassword, ...data } = formData;
      if (!password.match(strongPassword)) {
        newErrors.password = translate('register.error.password_invalid');
      }
      if (password !== confirmPassword) {
        newErrors.confirmPassword = translate('register.error.password_match');
        newErrors.password = translate('register.error.password_match');
      }
      if (!password) newErrors.password = translate('register.error.password');
      if (!confirmPassword)
        newErrors.confirmPassword = translate('register.error.confirmPassword');
      if (Object.keys(newErrors).length !== 0) {
        setErrors(newErrors);
        return;
      }
      setLoading(true);
      authProvider
        .register({ ...data, password })
        .then(() => setActiveStep(3))
        .catch((err) => {
          setLoading(false);
          const root = 'register.error.';
          notify(root + (translateErrors[err.message] || 'default'), 'error');
          if (err.message === 'Username already taken') {
            setErrors({ username: translate(root + 'username_taken') });
            setActiveStep(0);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    if (activeStep !== 2) setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleFormChange = (e, field) => {
    setFormData({ ...formData, [field]: e.target.value });
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.formContainer}>
        <Box sx={{ py: 6, height: 'fit-content' }}>
          <LogoWithTitle showTitle />
          <Typography variant="h3">{translate('register.title')}</Typography>
          <Box sx={{ width: '100%' }}>
            <Stepper
              activeStep={activeStep}
              connector={<QontoConnector />}
              className={classes.stepper}
            >
              {steps.map((label) => {
                return (
                  <Step key={label} style={{ paddingRight: 0, paddingLeft: 0 }}>
                    <StepLabel
                      style={{ paddingRight: 0 }}
                      StepIconComponent={StepIcon}
                    ></StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === 3 ? (
              <Box display="flex" flexDirection="column">
                <img
                  src="check.svg"
                  alt="check"
                  style={{ width: '122px', alignSelf: 'center' }}
                />
                <Typography sx={{ mt: 2, mb: 1, textAlign: 'center' }}>
                  {translate('register.createdAccount')}
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Button
                    fullWidth={true}
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={handleReset}
                    href="/#/customerLogin"
                  >
                    {translate('register.goToLogin')}
                  </Button>
                </Box>
              </Box>
            ) : (
              <form className={classes.form} noValidate>
                {steps[activeStep].fields.map((field) =>
                  field === 'password' || field === 'confirmPassword' ? (
                    <ShowPasswordInput
                      key={field}
                      name={field}
                      onChange={(e) => handleFormChange(e, field)}
                      value={formData[field]}
                      error={!!errors[field]}
                      helperText={errors[field]}
                      label={translate(`register.fields.${field}`)}
                    />
                  ) : (
                    <Input
                      key={field}
                      name={field}
                      formData={formData}
                      errors={errors}
                      handleFormChange={handleFormChange}
                    />
                  )
                )}

                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 24 }}>
                  <Button
                    color="secondary.main"
                    style={{
                      backgroundColor: theme.palette.primary?.lighten?.[1],
                    }}
                    href="/#/customerLogin"
                  >
                    {translate('buttons.cancel')}
                  </Button>
                  <Button
                    color="secondary.main"
                    style={{
                      backgroundColor: theme.palette.primary?.lighten?.[1],
                      display: activeStep === 0 ? 'none' : 'inherit',
                    }}
                    disabled={activeStep === 0}
                    onClick={handleBack}
                  >
                    {translate('buttons.back')}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    disabled={loading}
                    onClick={handleNext}
                  >
                    {translate('buttons.continue')}
                  </Button>
                </Box>
                <Notification contained />
              </form>
            )}
          </Box>
        </Box>
      </Box>
      {!isSmall && (
        <Box className={classes.imgContainer}>
          <img src="CustomerLogin.png" alt="login" />
        </Box>
      )}
    </Box>
  );
}

export default function RegisterWithLayout() {
  return (
    <ThemeProvider theme={customTheme}>
      <Register />
    </ThemeProvider>
  );
}
