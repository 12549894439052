import { Sidebar } from 'react-admin';
import { styled, useMediaQuery } from '@material-ui/core';
import { LogoWithTitle } from '../common';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'left',
  marginLeft: '1.7rem',
  ...theme.mixins.toolbar,
}));

const MySidebar = (props) => {
  const { open } = props.children._owner.pendingProps;
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  return (
    <Sidebar {...props} variant={isXSmall ? 'temporary' : 'permanent'}>
      <DrawerHeader>
        <LogoWithTitle showTitle={open} />
      </DrawerHeader>
      {props.children}
    </Sidebar>
  );
};

export default MySidebar;
