import {
  List,
  Pagination,
  DateField,
  SelectField,
  useTranslate,
} from 'react-admin';
import {
  Datagrid,
  ListCreator,
  ListToolbar,
  Empty,
  BoxStatus,
} from '../../components/list';
import Filter from '../../components/filter';
import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions';
import UrlField from '../../components/common/UrlField';
import EditProfileButton from './EditProfileButton';

const LicenseTopActions = [<EditProfileButton key={1} />];

const LicenseList = (props) => {
  const translate = useTranslate();
  const root = 'resources.licenses.fields';
  return (
    <List
      actions={false}
      pagination={
        <Pagination actions={TablePaginationActions} rowsPerPageOptions={[]} />
      }
      empty={
        <Empty
          title={translate('resources.licenses.fields.myLicenses')}
          actions={LicenseTopActions}
        />
      }
      className="margin-x-20"
      {...props}
    >
      <>
        <ListCreator
          actions={LicenseTopActions}
          toolbarProps={{ style: { paddingLeft: 24 } }}
          title={translate('resources.licenses.fields.myLicenses')}
        />
        <ListToolbar
          actions={
            <Filter
              filters={[
                {
                  label: 'Status',
                  source: 'status',
                  type: 'select',
                  choices: [
                    {
                      id: 'ACTIVE',
                      label: translate('choices.active'),
                    },
                    {
                      id: 'PENDING',
                      label: translate('choices.pending'),
                    },
                    {
                      id: 'EXPIRED',
                      label: translate('choices.expired'),
                    },
                  ],
                },
                {
                  label: translate(root + '.validFrom'),
                  source: 'validFrom__gte',
                  type: 'date',
                },
                {
                  label: translate(root + '.validUntil'),
                  source: 'validUntil__lte',
                  type: 'date',
                },
              ]}
            />
          }
        />
        <Datagrid>
          <UrlField source="id" urlPrefix="myLicenses" />
          <DateField source="validFrom" />
          <DateField source="validUntil" />
          <SelectField
            source="status"
            choices={[
              { id: 'ACTIVE', name: 'Active' },
              { id: 'EXPIRED', name: 'Expired', variant: 'negative' },
              { id: 'PENDING', name: 'Pending', variant: 'warning' },
            ]}
            optionText={<BoxStatus />}
          />
        </Datagrid>
      </>
    </List>
  );
};

export default LicenseList;
