import { Children, cloneElement, isValidElement } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { useInput, useTranslate } from 'ra-core';
import { Labeled, sanitizeInputRestProps } from 'react-admin';
import FileInputPreview from './FileInputPreview';

const useStyles = makeStyles(
  () => ({
    dropZone: {
      background: 'none !important',
      flex: 1,
      height: '100%',
      boxSizing: 'border-box',
      padding: 16,
    },
    root: {
      '& > div': {
        boxSizing: 'border-box',
        padding: 0,
        width: '100%',
        border: '#ABABAB 1px dashed',
        borderRadius: 4,
      },
    },
    label: {
      transform: 'scale(1)',
    },
    preview: {},
    removeButton: {},
  }),
  { name: 'RaFileInput' }
);

const FileInput = (props) => {
  const {
    accept,
    children,
    className,

    classes: classesOverride, // eslint-disable-line no-unused-vars
    format,
    label,
    labelMultiple = 'ra.input.file.upload_several',
    labelSingle = 'ra.input.file.upload_single',
    maxSize,
    minSize,
    multiple = false,
    options: { inputProps: inputPropsOptions, ...options } = {},
    parse,
    placeholder,
    resource,
    source,
    validate,
    ...rest
  } = props;
  const translate = useTranslate();
  const classes = useStyles(props);

  // turn a browser dropped file structure into expected structure
  const transformFile = (file) => {
    if (!(file instanceof File)) {
      return file;
    }

    const { source, title } = Children.only(children).props;

    const preview = URL.createObjectURL(file);
    const transformedFile = {
      rawFile: file,
      [source]: preview,
    };

    if (title) {
      transformedFile[title] = file.name;
    }

    return transformedFile;
  };

  const transformFiles = (files) => {
    if (!files) {
      return multiple ? [] : null;
    }

    if (Array.isArray(files)) {
      return files.map(transformFile);
    }

    return transformFile(files);
  };

  const {
    id,
    input: { onChange, value, ...inputProps },
    meta,
    isRequired,
  } = useInput({
    format: format || transformFiles,
    parse: parse || transformFiles,
    source,
    type: 'file',
    validate,
    ...rest,
  });
  const files = value ? (Array.isArray(value) ? value : [value]) : [];

  const onDrop = (newFiles, rejectedFiles, event) => {
    const updatedFiles = multiple ? [...files, ...newFiles] : [...newFiles];

    if (multiple) {
      onChange(updatedFiles);
    } else {
      onChange(updatedFiles[0]);
    }

    if (options.onDrop) {
      options.onDrop(newFiles, rejectedFiles, event);
    }
  };

  const onRemove = (file) => () => {
    if (multiple) {
      const filteredFiles = files.filter(
        (stateFile) => !shallowEqual(stateFile, file)
      );
      onChange(filteredFiles);
    } else {
      onChange(null);
    }

    if (options.onRemove) {
      options.onRemove(file);
    }
  };

  const childrenElement =
    children && isValidElement(Children.only(children))
      ? Children.only(children)
      : undefined;

  const { getRootProps, getInputProps } = useDropzone({
    ...options,
    accept,
    maxSize,
    minSize,
    multiple,
    onDrop,
  });

  return (
    <Labeled
      id={id}
      label={label}
      className={classnames(classes.root, className)}
      source={source}
      resource={resource}
      isRequired={isRequired}
      meta={meta}
      classes={{ label: classes.label }}
      {...sanitizeInputRestProps(rest)}
    >
      <>
        <div
          data-testid="dropzone"
          className={classes.dropZone}
          {...getRootProps()}
        >
          <input
            id={id}
            {...getInputProps({
              ...inputProps,
              ...inputPropsOptions,
            })}
          />
          {placeholder ? (
            placeholder
          ) : multiple ? (
            <p>{translate(labelMultiple)}</p>
          ) : (
            <p>{translate(labelSingle)}</p>
          )}
        </div>
        {children && (
          <div className="previews">
            {files.map((file, index) => (
              <FileInputPreview
                key={index}
                file={file}
                onRemove={onRemove(file)}
                className={classes.removeButton}
              >
                {cloneElement(childrenElement, {
                  record: file,
                  className: classes.preview,
                })}
              </FileInputPreview>
            ))}
          </div>
        )}
      </>
    </Labeled>
  );
};

FileInput.propTypes = {
  accept: PropTypes.string,
  children: PropTypes.element,
  classes: PropTypes.object,
  className: PropTypes.string,
  id: PropTypes.string,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  labelMultiple: PropTypes.string,
  labelSingle: PropTypes.string,
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  multiple: PropTypes.bool,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
  placeholder: PropTypes.node,
};

export default FileInput;
