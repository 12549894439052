import {
  Create,
  ReferenceArrayInput,
  required,
  SelectArrayInput,
  TextInput,
  useTranslate,
} from 'react-admin';
import { FormContainer } from '../../components/common';
import TitleViews from '../../components/common/TitleViews';
import { CreateForm } from '../../components/create';

const LicenseTypeCreate = (props) => {
  const translate = useTranslate();
  const root = 'resources.licenseTypes';
  return (
    <>
      <TitleViews
        subsectionTitle={translate(`${root}.breadcrumb.createTitle`)}
        title={`${translate('labels.create')} ${translate(`${root}.title`)}`}
      />
      <Create component={FormContainer} {...props}>
        <CreateForm variant="outlined" resettable>
          <TextInput source="name" validate={[required()]} fullWidth />
          <TextInput
            variant="outlined"
            source="description"
            options={{ multiline: true, minRows: 4 }}
            fullWidth={true}
            validate={[required()]}
            resettable
          />
          <ReferenceArrayInput source="productIds" reference="products">
            <SelectArrayInput optionText="name" validate={required()} />
          </ReferenceArrayInput>
          <SelectArrayInput
            choices={[
              {
                id: 'ONLINE_MODE',
                name: translate(`${root}.features.ONLINE_MODE`),
              },
              {
                id: 'MOBILE_KEYS',
                name: translate(`${root}.features.MOBILE_KEYS`),
              },
              {
                id: 'SERVICE_REQUEST',
                name: translate(`${root}.features.SERVICE_REQUEST`),
              },
              {
                id: 'APPLE_WALLET',
                name: translate(`${root}.features.APPLE_WALLET`),
              },
            ]}
            source="features"
          />
        </CreateForm>
      </Create>
    </>
  );
};

export default LicenseTypeCreate;
