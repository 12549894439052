const MuiButton = (palette) => ({
  root: {
    borderRadius: '4px',
    height: '44px',
  },
  containedPrimary: {
    color: palette.primary.contrastText,
    backgroundColor: palette.primary.main,
    '&:hover': {
      backgroundColor: palette.primary.lighten[4],
    },
    '&:active': {
      backgroundColor: palette.primary.darken[0],
    },
    '&$disabled': {
      color: palette.primary.contrastText,
      backgroundColor: palette.black.lighten[1],
    },
  },
  containedSecondary: {
    color: palette.secondary.main,
    backgroundColor: palette.primary.lighten[1],
    '&:hover': {
      backgroundColor: palette.primary.lighten[2],
    },
    '&:active': {
      backgroundColor: palette.primary.lighten[3],
    },
    '&$disabled': {
      color: palette.primary.contrastText,
      backgroundColor: palette.black.lighten[1],
    },
  },
  textPrimary: {
    color: palette.primary.main,
    '&:hover': {
      backgroundColor: palette.primary.lighten[0],
    },
    '&:active': {
      backgroundColor: palette.primary.lighten[1],
    },
    '&$disabled': {
      color: palette.black.lighten[1],
    },
  },
  textSecondary: {
    color: palette.red.main,
    '&:hover': {
      backgroundColor: palette.red.lighten[0],
    },
    '&:active': {
      backgroundColor: palette.red.lighten[1],
    },
    '&$disabled': {
      color: palette.black.lighten[1],
    },
  },
  outlinedPrimary: {
    backgroundColor: 'white',
    color: palette.primary.main,
    border: `1px solid ${palette.primary.main}`,
    borderRadius: 4,
  },
});

export default MuiButton;
