import { Box, Typography, useTheme, Link, Button } from '@material-ui/core';
import {
  FormDataConsumer,
  required,
  SelectInput,
  TextInput,
  useTranslate,
  useDataProvider,
  useNotify,
} from 'react-admin';
import { InputContainer } from '../../components/common';
import { CustomEdit, EditForm } from '../../components/edit';
import { Link as ReactRouterLink } from 'react-router-dom';
import LocationCreateForm from './../../components/create/LocationCreateForm';
import { useForm, useFormState } from 'react-final-form';
import { useState } from 'react';

const SyncLocationsButton = () => {
  const dataProvider = useDataProvider();
  const { values } = useFormState();
  const [isLoading, setIsLoading] = useState(false);
  const notify = useNotify();
  const form = useForm();
  const translate = useTranslate();
  const root = 'resources.clients';

  const onClick = async () => {
    try {
      setIsLoading(true);
      const { data } = await dataProvider.getOne('clients/syncLocations', {
        id: values?.id,
      });
      form.change('Locations', data.Locations);
      notify(root + '.sync');
    } catch (error) {
      notify(error.message, 'error');
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Button
      color="primary"
      variant="contained"
      onClick={onClick}
      disabled={isLoading}
    >
      {translate(root + '.buttons.sync')}
    </Button>
  );
};

const ClientEdit = (props) => {
  const theme = useTheme();
  const translate = useTranslate();

  const transform = (data) => {
    console.log('data edit--->', data);
    return data;
  };
  return (
    <>
      <CustomEdit
        mutationMode="pessimistic"
        //transform={({ Licenses, ...data }) => data}
        transform={transform}
        {...props}
      >
        <EditForm
          variant="outlined"
          ressetable
          titleProps={{
            subsectionTitle: translate(
              'resources.clients.breadcrumb.editTitle'
            ),
          }}
        >
          <TextInput source="name" validate={[required()]} />
          <TextInput source="lastName" validate={[required()]} />
          <SelectInput
            source="status"
            choices={[
              { id: 'ACTIVE', name: 'Active' },
              { id: 'INACTIVE', name: 'Inactive' },
            ]}
            validate={[required()]}
          />
          <TextInput source="email" validate={[required()]} />
          <TextInput source="companyName" validate={[required()]} />
          <SyncLocationsButton />
          <InputContainer></InputContainer>
          <Box className="fullWidth">
            <LocationCreateForm {...props} type="clientEdit" />
          </Box>
          <FormDataConsumer>
            {({ formData }) => {
              const hasLicenses = formData.Licenses?.length > 0;

              if (!hasLicenses) return null;

              const latestValidUntilDate = formData.Licenses.reduce(
                (acc, curr) => {
                  if (new Date(curr.validUntil) > acc)
                    return new Date(curr.validUntil);
                  return acc;
                },
                new Date(0)
              );

              const validUntilDDMMYY = latestValidUntilDate.getTime()
                ? `${latestValidUntilDate.getDate()}/${
                    latestValidUntilDate.getMonth() + 1
                  }/${latestValidUntilDate.getFullYear()}`
                : translate('errors.notAvailable');

              return (
                <Box className="fullWidth">
                  <Typography
                    variant="body1"
                    style={{ fontWeight: 500, marginBottom: '1rem' }}
                  >
                    {translate('resources.licenses.title')}:
                  </Typography>
                  <Box
                    css={{
                      backgroundColor: theme.palette.primary.lighten[0],
                      padding: '1rem',
                      borderRadius: '0.5rem',
                      border: `1px solid ${theme.palette.black.lighten[1]}`,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    {translate('resources.licenses.fields.validUntil')}:{' '}
                    {validUntilDDMMYY.toString()}
                    <Link
                      component={ReactRouterLink}
                      to={`/licenses?clientId=${formData.id}`}
                    >
                      {translate('resources.licenses.viewLicenses')}
                    </Link>
                  </Box>
                </Box>
              );
            }}
          </FormDataConsumer>
        </EditForm>
      </CustomEdit>
    </>
  );
};

export default ClientEdit;
