import { useState } from 'react';

import { InputAdornment, IconButton } from '@material-ui/core';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import { TextField } from './common';

function ShowPasswordInput(props) {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <TextField
      type={showPassword ? 'text' : 'password'}
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() =>
                setShowPassword((currentShowPassword) => !currentShowPassword)
              }
              edge="end"
            >
              {showPassword ? (
                <VisibilityOffOutlinedIcon />
              ) : (
                <VisibilityOutlinedIcon />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
}

export default ShowPasswordInput;
