import { ReferenceInput, required, SelectInput } from 'react-admin';

export const ClientSelect = (props) => {
  const handleClientSelect = async (client) => {
    if (!client) return;
  };
  return (
    <ReferenceInput source="clientId" reference="clients" {...props}>
      <SelectInput
        optionText="name"
        validate={required()}
        inputProps={{ onChange: handleClientSelect }}
      />
    </ReferenceInput>
  );
};
