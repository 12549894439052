import { SimpleForm } from 'ra-ui-materialui';
import { InputContainer } from '../common';
import Toolbar from './Toolbar';
import LocationCreateForm from './LocationCreateForm';
import SurveyTemplateTab from '../../components/surveyTemplate/SurveyTemplateTab';
import AssignedUsersForm from '../survey/AssignedUsersForm';

const CreateForm = ({ children, toolbarProps, ...props }) => (
  <SimpleForm {...props} toolbar={<Toolbar {...toolbarProps} />}>
    <InputContainer>{children}</InputContainer>
    {props.type === 'client' && <LocationCreateForm />}
    {props.type === 'surveyTemplate' && <SurveyTemplateTab />}
    {props.type === 'survey' && <AssignedUsersForm />}
  </SimpleForm>
);

export default CreateForm;
