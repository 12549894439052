import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  useLogin,
  useNotify,
  Notification,
  useSetLocale,
  useTranslate,
} from 'react-admin';

import { Typography } from '@mui/material';
import { Box, Button, ThemeProvider, useMediaQuery } from '@material-ui/core';

import { useStyles } from './styles';
import { LogoWithTitle } from '../common';
import { login as loginAuth0 } from '../../utils/auth0';

const translateErrors = {
  'No User': 'incorrect',
  'User or password incorrect': 'incorrect',
  'Password Expired': 'password_expired',
  'Max attempts reach': 'max_attempts',
};

function LoginPage() {
  const translate = useTranslate();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const setLocale = useSetLocale();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const login = useLogin();
  const notify = useNotify();
  const search = new URLSearchParams(window.location.search);
  const code = search.get('code');

  useEffect(() => {
    if (code) {
      window.history.replaceState(null, null, `/#/login?code${code}`);
      setLoading(true);
      login(code)
        .then((res) => {
          setLocale(res.user.language);
          const role = res.user?.role;
          if (role === 'Customer' || role === 'Survey Contractor') {
            history.push('/surveys');
          } else {
            history.push('/clients');
          }
        })
        .catch((err) => {
          const root = 'login.error.';
          notify(root + (translateErrors[err.message] || 'default'), 'warning');
          if (err.message === 'Password Expired') {
            history.push('/resetPassword');
          } else {
            history.push('/login');
          }
          setLoading(false);
        });
    }
  }, [code]);

  const submit = (e) => {
    e.preventDefault();
    setLoading(true);
    loginAuth0();
  };

  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.formContainer}>
        <form className={classes.form} onSubmit={submit}>
          <LogoWithTitle showTitle />
          <Typography sx={{ mt: 8 }} variant="h3">
            {translate('login.title')}
          </Typography>
          <Typography variant="body1" className={classes.subtitle}>
            {translate('login.subtitle')}
          </Typography>
          <Button
            sx={{ mt: 2 }}
            variant="contained"
            color="primary"
            disableElevation
            disabled={loading}
            onClick={submit}
          >
            {loading ? '...Loading' : 'Log in'}
          </Button>
        </form>
      </Box>
      {!isSmall && (
        <Box className={classes.imgContainer}>
          <img src="AdminLogin.png" alt="login" />
        </Box>
      )}
      <Notification />
    </Box>
  );
}

function LoginWithTheme(props) {
  return (
    <ThemeProvider theme={props.theme}>
      <LoginPage {...props} />
    </ThemeProvider>
  );
}

export default LoginWithTheme;
