import {
  Edit,
  FormTab,
  TabbedForm,
  TabbedFormTabs,
  useEditController,
  useNotify,
} from 'react-admin';
import { InputContainer } from '../common';
import { Question, questionTypes } from './Question';
import { EditToolbar } from '../edit/';
import objectToString from '../../utils/objectToString';
import { useEffect, useRef, useState } from 'react';
import InvisibleSaveButton from '../common/SaveButton';
import { NextTabButton, PreviousTabButton } from './TabButtons';

const blobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64 = reader.result?.toString();
      if (base64) {
        resolve(base64);
      } else {
        reject('Error');
      }
    };
    reader.onerror = (err) => reject(err);
    reader.readAsDataURL(blob);
  });
};

const answerToBase64 = async (answer) => {
  const res = await fetch(answer.src);
  const blob = await res.blob();
  const base64 = await blobToBase64(blob);
  answer.src = base64;
};

export default function FillSurvey(props) {
  const notify = useNotify();
  return (
    <>
      <Edit
        resource="surveys"
        basePath="/surveys"
        id={props.match?.params?.id}
        transform={transform}
        mutationMode="pessimistic"
        onSuccess={() => {
          notify('resources.surveys.updatedSurvey', 'success');
        }}
        {...props}
      >
        <CustomForm
          id={props.match?.params?.id}
          onSuccess={() => {
            notify('resources.surveys.updatedSurvey', 'success');
          }}
          {...props}
        />
      </Edit>
    </>
  );
}

const transform = async (data) => {
  const answers = data?.answers ?? {};
  const answersToBase64 = [];

  for (const answer of Object.values(answers)) {
    if (answer?.src?.startsWith('blob:')) {
      answersToBase64.push(answerToBase64(answer));
    }
    if (typeof answer === 'object') {
      if (Array.isArray(answer)) {
        for (const item of answer) {
          for (const key in item) {
            if (item[key]?.src?.startsWith('blob:')) {
              answersToBase64.push(answerToBase64(item[key]));
            }
          }
        }
      } else {
        for (const key in answer) {
          if (answer[key]?.src?.startsWith('blob:')) {
            answersToBase64.push(answerToBase64(answer[key]));
          }
        }
      }
    }
  }

  try {
    await Promise.allSettled(answersToBase64);
  } catch (err) {
    console.log({ err });
    //notify error
  }

  return { answers, surveyTemplateId: data.surveyTemplateId };
};

function CustomForm({ record, id, ...props }) {
  const notify = useNotify();
  const [enabledTabs, setEnabledTabs] = useState(0);
  const { save, saving } = useEditController({ ...props, id });
  const saveButtonRef = useRef();

  const tabTemplates = record?.SurveyTemplate?.SurveyTabTemplates ?? [];
  useEffect(() => {
    if (record.answers) {
      const evalString = objectToEvalConsts(record?.answers);
      // eslint-disable-next-line no-eval
      window.eval(evalString);
    }
  }, [record]);

  if (tabTemplates?.length < 1) {
    return <p>loading...</p>;
  }

  const updateAllSurvey = () => {
    saveButtonRef.current?.click();
  };
  const updateQuestion = async (e, code) => {
    await save(
      {
        answers: {
          [code]: e.target.value,
        },
      },
      false,
      {
        onSuccess: () => {
          notify('resources.surveys.updatedSurvey', 'success');
        },
      }
    );
  };
  return (
    <TabbedForm
      toolbar={
        <EditToolbar
          withoutDelete
          withoutCancel
          saveButton={
            <InvisibleSaveButton
              ref={saveButtonRef}
              mutationMode="pessimistic"
              isSaving={saving}
            />
          }
          customButton={
            <>
              <PreviousTabButton enabledTabs={enabledTabs} />
              <NextTabButton
                enabledTabs={enabledTabs}
                numberOfTabs={tabTemplates.length}
              />
            </>
          }
        />
      }
      tabs={<TabbedFormTabs scrollButtons="auto" variant="scrollable" />}
      {...props}
    >
      {tabTemplates.map((tab, i) => (
        <FormTab key={tab.id} label={tab.name} disabled={i > enabledTabs}>
          <Tab
            tab={tab}
            onBlur={updateQuestion}
            updateAllSurvey={updateAllSurvey}
            record={record}
            setEnabledTabs={setEnabledTabs}
            index={i}
            shouldShow={i <= enabledTabs}
          />
        </FormTab>
      ))}
    </TabbedForm>
  );
}

const Tab = ({
  tab,
  onBlur,
  record,
  updateAllSurvey,
  setEnabledTabs,
  index,
  shouldShow,
}) => {
  if (!shouldShow) return null;
  const tabQuestions = tab?.SurveyQuestionTemplates || [];

  const showConditionally = (index) => {
    const currentQuestion = tabQuestions[index];
    let previousQuestion = tabQuestions[index - 1];
    // Show the fisrt question always
    if (!previousQuestion) previousQuestion = { renderNext: true };
    const currentAnswer = record?.answers?.[currentQuestion.code];
    let previousAnswerIsFilled = false;

    const condition = currentQuestion?.condition;
    const type = currentQuestion?.type;

    window.isConditionSatisfied = true;
    const setCondition = `
      try {
        window.isConditionSatisfied = ${condition};
      } catch(e) {
        window.isConditionSatisfied = false;
      }
  `;
    if (condition) {
      try {
        // eslint-disable-next-line no-eval
        eval(setCondition);
      } catch (e) {
        console.log({ e, condition, code: currentQuestion.code });
      }
    }

    const isFillableType = type in questionTypes && type !== 'BUTTON';
    const previousAnswer = record?.answers[previousQuestion.code];
    if (typeof previousAnswer === 'object') {
      if (previousAnswer == null) {
        currentQuestion.renderNext = !isFillableType;
        previousAnswerIsFilled = false;
      } else {
        const subAnswers = Object.values(previousAnswer);
        previousAnswerIsFilled = subAnswers.some((answer) => answer !== '');
      }
    } else {
      previousAnswerIsFilled = !!previousAnswer;
    }
    const hasTurn = previousQuestion?.renderNext || previousAnswerIsFilled;
    let hasAnswer = false;
    if (typeof currentAnswer === 'object') {
      if (currentAnswer == null) {
        hasAnswer = false;
      } else {
        const subAnswers = Object.values(currentAnswer);
        hasAnswer = subAnswers.some((answer) => answer !== '');
      }
    } else {
      hasAnswer = !!currentAnswer;
    }

    currentQuestion.renderNext =
      hasAnswer ||
      (hasTurn && (!window.isConditionSatisfied || !isFillableType));
    return (
      hasAnswer ||
      ((hasTurn || previousQuestion?.typeProperties?.isOptional) &&
        window.isConditionSatisfied)
    );
  };

  const questionsToShow = tabQuestions.filter((_, index) =>
    showConditionally(index)
  );

  const lastFillableQuestion = questionsToShow
    .reverse()
    .find(
      (question) => question.type in questionTypes && question.type !== 'BUTTON'
    );

  const lastFillableQuestionAnswer =
    record?.answers?.[lastFillableQuestion?.code] ||
    lastFillableQuestion.typeProperties?.isOptional;
  if (lastFillableQuestionAnswer) {
    setEnabledTabs((tabs) => {
      if (index < tabs) return tabs;
      return index + 1;
    });
  } else {
    setEnabledTabs((tabs) => {
      if (index > tabs) return tabs;
      return index;
    });
  }

  questionsToShow.reverse();

  return (
    <InputContainer columns={1}>
      {questionsToShow?.map((question) => (
        <Question
          key={question.code}
          {...question}
          record={record}
          onBlur={onBlur}
          tabQuestions={tabQuestions}
          updateAllSurvey={updateAllSurvey}
        />
      ))}
    </InputContainer>
  );
};

function objectToEvalConsts(obj = {}) {
  if (Object.keys(obj).length === 0) return '';
  let evalString = 'var ';
  for (const [key, value] of Object.entries(obj)) {
    switch (typeof value) {
      case 'string':
        evalString += `${key}='${value}',`;
        break;
      case 'number':
      case 'boolean':
        evalString += `${key}=${value},`;
        break;
      case 'object':
        // eslint-disable-next-line no-case-declarations
        const stringObj = objectToString(value);
        evalString += `${key}=${stringObj},`;
        break;
      default:
        break;
    }
  }
  if (evalString === 'const ') return '';
  return evalString.slice(0, -1);
}
