import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

export const StyledInput = styled(InputBase)(({ theme }) => ({
  padding: 10,
  width: '100%',
  borderBottom: `1px solid ${
    theme.palette.mode === 'light' ? '#eaecef' : '#30363d'
  }`,
  '& input': {
    borderRadius: 4,
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#0d1117',
    padding: 8,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    border: `1px solid ${
      theme.palette.mode === 'light' ? '#eaecef' : '#30363d'
    }`,
    fontSize: 14,
    '&:focus': {
      boxShadow: `0px 0px 0px 3px ${
        theme.palette.mode === 'light'
          ? 'rgba(3, 102, 214, 0.3)'
          : 'rgb(12, 45, 107)'
      }`,
      borderColor: theme.palette.mode === 'light' ? '#0366d6' : '#388bfd',
    },
  },
}));

export const ContainerBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  borderBottom: 'solid 1px #DEDEDE',
  padding: '4px',
  minHeight: '40px',
  marginBottom: '8px',
  boxSizing: 'border-box',
  alignItems: 'center',
  zIndex: 100,
  justifyContent: 'space-between',
  '&:hover': {
    borderColor: '#000',
  },
}));

export const FilterBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  minHeight: '35px',
  margin: '12px 0',
  boxSizing: 'border-box',
  alignItems: 'center',
}));

export const FilterItem = styled(Typography)(() => ({
  borderRadius: '37px',
  display: 'flex',
  padding: '4px',
  background: '#ECFBFF',
  fontSize: '16px',
  marginRight: '12px',
}));

export const SearchText = styled(Typography)(() => ({
  margin: '4px',
  padding: ' 4px 12px',
  paddingLeft: 0,
  fontSize: '16px',
  color: '#363636',
  zIndex: 1000,
  display: 'flex',
  alignItems: 'center',
}));

export const Icon = styled(IconButton)(() => ({
  padding: 0,
  margin: 0,
  marginLeft: '4px',
}));

export const CloseIconButton = (props) => (
  <Icon {...props} size="small">
    <CloseIcon fontSize="inherit" />
  </Icon>
);
