import { useState } from 'react';
import {
  SimpleForm,
  useAuthProvider,
  useTranslate,
  Toolbar,
  useNotify,
  useRedirect,
} from 'react-admin';
import customTheme from '../../themes/customTheme';
import CustomAppBar from './CustomAppbar';
import { ThemeProvider, Box, Button } from '@material-ui/core';
import { CancelButton, InputContainer, TitleViews } from '../common';
import ShowPasswordInput from '../licensesLogin/ShowPasswordInput';

const strongPassword =
  /(?=[^_]*\d)(?=[^_]*[a-z])(?=[^_]*[A-Z])(?=.*[@$!%*#?&^_-]).{8,20}/;

export default function ChangePassword(props) {
  return (
    <ThemeProvider theme={customTheme}>
      <CustomAppBar />
      <Profile {...props} id={1} basePath="myLicenses" resource="profile" />
    </ThemeProvider>
  );
}

function Profile(props) {
  const { changePassword } = useAuthProvider();
  const translate = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();
  const [formData, setFormData] = useState({
    oldPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const handleFormChange = (field) => (e) => {
    if (e.target.value) {
      setErrors({ ...errors, [field]: '' });
    }
    setFormData({ ...formData, [field]: e.target.value });
  };

  const validate = () => {
    const newErrors = {};
    const { oldPassword, newPassword, newPasswordConfirm } = formData;
    if (formData.newPassword !== formData.newPasswordConfirm) {
      newErrors.newPasswordConfirm = 'Passwords do not match';
    }
    if (!newPassword.match(strongPassword))
      newErrors.newPassword = translate('profile.error.password_invalid');
    if (newPassword !== newPasswordConfirm) {
      newErrors.newPasswordConfirm = translate('profile.error.password_match');
      newErrors.newPassword = translate('profile.error.password_match');
    }
    if (!newPasswordConfirm) {
      newErrors.newPasswordConfirm = translate('profile.error.confirmPassword');
    }
    if (!newPassword) {
      newErrors.newPassword = translate('profile.error.password');
    }
    if (!oldPassword) {
      newErrors.oldPassword = translate('profile.error.oldPassword');
    }
    return newErrors;
  };
  const save = async () => {
    const errors = validate();
    setErrors(errors);
    if (Object.keys(errors).length > 0) return;
    setLoading(true);
    try {
      await changePassword({
        newPassword: formData.newPassword,
        oldPassword: formData.oldPassword,
      });
      setLoading(false);
      notify(translate('profile.notification.password_changed'));
      redirect('/myLicenses');
    } catch (e) {
      setLoading(false);
      setErrors({ oldPassword: translate('profile.error.invalidOldPassword') });
    }
  };
  return (
    <SimpleForm
      variant="outlined"
      handleSubmit={save}
      handleSubmitWithRedirect={save}
      toolbar={<></>}
      save={save}
      style={{
        padding: 10,
      }}
      {...props}
    >
      <TitleViews
        title={translate('profile.change_password')}
        subTitle={translate('profile.subtitle')}
        resource="profile"
        withoutBreadcrumb
      />
      <Box
        style={{
          padding: 25,
          background: 'white',
          borderRadius: '5px',
          boxSizing: 'border-box',
        }}
      >
        <InputContainer columns={3}>
          <ShowPasswordInput
            value={formData.oldPassword}
            onChange={handleFormChange('oldPassword')}
            label={translate('profile.fields.oldPassword')}
            error={errors.oldPassword}
            helperText={errors.oldPassword}
          />
          <ShowPasswordInput
            value={formData.newPassword}
            onChange={handleFormChange('newPassword')}
            label={translate('profile.fields.newPassword')}
            error={errors.newPassword}
            helperText={errors.newPassword}
          />
          <ShowPasswordInput
            value={formData.newPasswordConfirm}
            onChange={handleFormChange('newPasswordConfirm')}
            label={translate('profile.fields.newPasswordConfirm')}
            error={errors.newPasswordConfirm}
            helperText={errors.newPasswordConfirm}
          />
        </InputContainer>
        <Toolbar style={{ background: 'white', padding: 0, gap: 15 }}>
          <CancelButton redirect basePath="myLicenses" />
          <Button
            variant="contained"
            disableElevation
            color="secondary"
            href="/#/profile"
            title={translate('buttons.back')}
          >
            {translate('buttons.back')}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={save}
            disabled={loading}
            title={translate('buttons.save')}
          >
            {translate('buttons.save')}
          </Button>
        </Toolbar>
      </Box>
    </SimpleForm>
  );
}
