import { useEffect, useState, useMemo } from 'react';

import Grid from '@mui/material/Grid';
import SurveyTemplateQuestionsCustom from './SurveyTemplateQuestionsCustom';

import { useTranslate } from 'react-admin';
import { Button } from '@material-ui/core';

import AddIcon from '@mui/icons-material/Add';

import Pagination from './Pagination/Pagination';

let PageSize = 2;

const SurveyTemplateQuestionsEdit = ({ scopedFormData }) => {
  const [questions, setQuestions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setQuestions(scopedFormData?.SurveyQuestionTemplates);
  }, []);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return questions.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, questions]);

  const translate = useTranslate();

  const deleteQuestion = (id) => {
    const newQuestions = questions.filter((question) => question.id !== id);
    setQuestions(newQuestions);
  };

  const handleOnChange = (value, inputName, questionId) => {
    const newQuestions = questions.map((question) => {
      if (question.id === questionId) {
        question[inputName] = value;
      }
      return question;
    });
    setQuestions(newQuestions);
  };

  const addQuestion = () => {
    const newQuestions = questions;
    newQuestions.push({
      code: null,
      condition: null,
      order: 0,
      question: null,
      status: 'PUBLISHED',
      surveyTabTemplateId: 8,
      tooltip: null,
      type: null,
      typeProperties: null,
    });
    setQuestions(newQuestions);
    setCurrentPage(newQuestions.length / PageSize);
  };
  return (
    <Grid
      justifyContent="flex-end"
      container
      sx={{
        marginLeft: 3,
        backgroundColor: 'white',
        borderColor: '#DCDCDC',
        borderWidth: 'thin',
        borderRadius: 1,
        marginBottom: 2,
      }}
    >
      <Grid item xs={12} sx={tabsTitleStyle}>
        {translate('resources.surveytemplates.questionsTitle')}
      </Grid>
      <Grid item xs={3} sx={{ padding: 1, paddingTop: 2 }}>
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={questions.length}
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </Grid>
      {currentTableData?.map((question) => (
        <SurveyTemplateQuestionsCustom
          question={question}
          deleteQuestion={deleteQuestion}
          handleOnChange={handleOnChange}
          key={question.key}
        />
      ))}
      <Grid
        item
        xs={12}
        sx={{
          padding: 2,
          borderTopStyle: 'solid',
          borderColor: '#DCDCDC',
          borderWidth: 'thin',
          marginTop: 2,
        }}
      >
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          size="small"
          inline="false"
          disableElevation
          color="secondary"
          onClick={() => addQuestion()}
        >
          Add Question
        </Button>
      </Grid>
    </Grid>
  );
};

export default SurveyTemplateQuestionsEdit;

const tabsTitleStyle = {
  padding: 2,
  borderBottomStyle: 'solid',
  borderWidth: 'thin',
  borderColor: '#DCDCDC',
  fontWeight: 'bold',
};
