import { useState } from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import StaticDatePicker from '@mui/lab/StaticDatePicker';
import Popover from '@mui/material/Popover';
import TextField from '@mui/material/TextField';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';

import { SearchText } from './styles';

const DateFilter = ({ filter, onFilter }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState('');

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const onSearch = (date) => {
    const myDate = new Date(date);
    setValue(myDate.toDateString());
    onFilter(filter.source, myDate.toString());
    handlePopoverClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <SearchText id={id} onClick={handlePopoverOpen}>
        {filter.label} <CalendarTodayOutlinedIcon style={{ height: 18 }} />
      </SearchText>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            value={value}
            onChange={(newValue) => onSearch(newValue)}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Popover>
    </div>
  );
};

export default DateFilter;
