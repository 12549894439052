import { Box } from '@material-ui/core';

export default function LogoWithTitle({ showTitle, height, ...props }) {
  const Title = showTitle && (
    <img
      style={{ maxWidth: 'fit-content', maxHeight: 64 }}
      src="Title.svg"
      alt="entryready logo"
    />
  );
  const src = 'Logo.svg';

  return (
    <Box
      style={{
        display: 'flex',
        gap: 10,
        height: height || 80,
        alignItems: 'center',
      }}
    >
      {src === 'Logo.svg' ? (
        <>
          <img
            src={src}
            style={{
              maxWidth: 64,
              maxHeight: 64,
            }}
            alt="entryready logo"
            {...props}
          />
          {Title}
        </>
      ) : (
        showTitle && (
          <img
            src={src}
            style={{
              maxWidth: 190,
              maxHeight: 80,
              objectFit: 'contain',
            }}
            alt="entryready logo"
            {...props}
          />
        )
      )}
    </Box>
  );
}
