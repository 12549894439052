import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { Button } from '@material-ui/core';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import {
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer,
  useTranslate,
  useGetList,
  useGetIdentity,
} from 'react-admin';

const TransitionProps = {
  classNames: '',
  addEndListener: () => undefined,
};

const SelectUSers = ({ ...props }) => {
  return (
    <Grid
      container
      sx={{
        paddingLeft: 2,
        paddingTop: 2,
      }}
    >
      <SelectInput
        {...props}
        source={props.getSource('id')}
        label="User"
        choices={props?.users}
        optionText={(choice) => choice.name}
        variant="outlined"
        fullWidth
      />
    </Grid>
  );
};

export default function AssignedUsersForm(props) {
  const [users, setUsers] = useState([]);
  const translate = useTranslate();
  const { data, ids, isLoading, error } = useGetList('users');
  const { identity } = useGetIdentity();
  const role = identity?.role;
  const isAdmin = role === 'Admin' || role === 'Super Admin';
  const { record } = props;
  useEffect(() => {
    const newUsers = ids.map((id) => {
      return {
        id,
        name: data[id].name,
        role: data[id].role,
      };
    });
    const recordUsers =
      record?.AdminUsers?.map((user) => {
        return {
          id: user.id,
          name: user.name,
          role: user.role,
        };
      }) || [];
    const users =
      newUsers?.filter((user) => user.role === 'Survey Contractor') || [];
    const usersWithoutDuplicates = users.filter((user) => {
      return !recordUsers.find((recordUser) => recordUser.id === user.id);
    });
    setUsers([...usersWithoutDuplicates, ...recordUsers]);
  }, [ids, data, record]);

  if (isLoading) {
    return <p>Loading...</p>;
  }
  if (error) {
    return null;
  }
  return (
    <Grid container sx={gridContainerStyles}>
      <Grid item xs={12} sx={gridTitleStyles}>
        {translate('resources.surveys.assignedUsers')}
      </Grid>
      <ArrayInput
        source={props.type === 'surveyEdit' ? 'AdminUsers' : 'users'}
        label=""
        className="class-array-input"
      >
        <SimpleFormIterator
          TransitionProps={TransitionProps}
          className="class-array-input"
          getItemLabel={(index) => `${index + 1}. link`}
          removeButton={
            isAdmin ? (
              <div
                style={{
                  height: '85%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <DeleteOutlineOutlinedIcon
                  sx={{
                    marginRight: 1,
                    marginLeft: 1,
                  }}
                />
              </div>
            ) : (
              <></>
            )
          }
          addButton={
            isAdmin ? (
              <div className="class-user-assign-add" style={{ margin: 10 }}>
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  size="small"
                  inline="false"
                  disableElevation
                  color="secondary"
                >
                  {translate('resources.surveys.addUser')}
                </Button>
              </div>
            ) : (
              <></>
            )
          }
        >
          <FormDataConsumer>
            {({ getSource }) => {
              return (
                <SelectUSers
                  getSource={getSource}
                  users={users}
                  disabled={!isAdmin}
                />
              );
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </Grid>
  );
}

const gridContainerStyles = {
  backgroundColor: '#FAFAFA',
  borderRadius: 2,
};

const gridTitleStyles = {
  borderBottomStyle: 'solid',
  borderWidth: 'thin',
  borderColor: '#DCDCDC',
  padding: 1,
};
