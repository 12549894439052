import { useState } from 'react';
import { useAuthProvider, useNotify, useTranslate } from 'react-admin';

import {
  useTheme,
  Typography,
  Box,
  Button,
  ThemeProvider,
  useMediaQuery,
  Dialog,
} from '@material-ui/core';
import Notification from '../layout/notification';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';

import { useStyles } from './styles';
import { LogoWithTitle } from '../common';
import customTheme from '../../themes/customTheme';
import { TextField } from './common';

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const translateErrors = {
  'No User': 'incorrect',
  'User or password incorrect': 'incorrect',
  'Password Expired': 'password_expired',
  'Max attempts reach': 'max_attempts',
};

function ResetPasswordRequestPage() {
  const translate = useTranslate();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [showModal, setSuccess] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
  });
  const [errors, setErrors] = useState({});
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const authProvider = useAuthProvider();
  const notify = useNotify();
  const submit = async (e) => {
    e.preventDefault();
    const { email } = formData;
    const newErrors = {};
    if (!email.match(emailRegex)) {
      newErrors.email = translate('resetPassword.fields.emailInvalid');
    }
    if (!email) {
      newErrors.email = translate('ra.validation.required');
    }
    if (newErrors.email) {
      setErrors(newErrors);
      return;
    }
    setLoading(true);
    authProvider
      .resetPasswordRequest({ email })
      .catch((err) => {
        const root = 'login.error.';
        notify(root + (translateErrors[err.message] || 'default'), 'error');
      })
      .finally(() => {
        setLoading(false);
        setSuccess(true);
      });
  };

  const handleFormChange = (field) => (e) => {
    if (e.target.value) {
      setErrors({ ...errors, [field]: '' });
    }
    setFormData({ ...formData, [field]: e.target.value });
  };

  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Dialog className={classes.dialog} open={showModal}>
        <CheckCircleOutline className={classes.checkIcon} />
        <Typography variant="h6">
          {translate('resetPassword.successMessage')} <br />
          {translate('resetPassword.checkEmail')}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          href="/#/customerLogin"
          style={{ marginTop: 32, padding: '23px 12px' }}
        >
          {translate('resetPassword.fields.goToLogin')}
        </Button>
      </Dialog>
      <Box className={classes.formContainer}>
        <form className={classes.form} onSubmit={submit}>
          <LogoWithTitle showTitle />
          <Typography variant="h4">
            {translate('resetPassword.title')}
          </Typography>
          <Typography variant="body1" className={classes.subtitle}>
            {translate('resetPassword.subtitle')}
          </Typography>
          <TextField
            id="email"
            name="email"
            label={translate('resetPassword.fields.email')}
            variant="outlined"
            error={!!errors.email}
            helperText={errors.email}
            value={formData.email}
            onChange={handleFormChange('email')}
            autoFocus
          />
          <Button
            variant="contained"
            color="primary"
            disableElevation
            disabled={loading}
            onClick={submit}
            style={{
              padding: 25,
            }}
          >
            {translate('resetPassword.fields.recoverPassword')}
          </Button>
          <Button
            style={{
              backgroundColor: theme.palette.primary?.lighten?.[1],
              padding: 25,
            }}
            href="/#/customerLogin"
          >
            {translate('resetPassword.fields.cancel')}
          </Button>
          <Notification contained />
        </form>
      </Box>
      {!isSmall && (
        <Box className={classes.imgContainer}>
          <img src="CustomerLogin.png" alt="login" />
        </Box>
      )}
    </Box>
  );
}

function LoginWithTheme(props) {
  return (
    <ThemeProvider theme={customTheme}>
      <ResetPasswordRequestPage {...props} />
    </ThemeProvider>
  );
}

export default LoginWithTheme;
