import { useState } from 'react';
import Popover from '@mui/material/Popover';
import TextField from '@mui/material/TextField';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { SearchText } from './styles';

const SearchFilter = ({ filter, onFilter }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <SearchText id={id} onClick={handlePopoverOpen}>
        {filter.label}
        <KeyboardArrowDownIcon style={{ paddingLeft: '16px' }} />
      </SearchText>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <TextField
          style={{ margin: '8px' }}
          type="number"
          size="small"
          variant="outlined"
          onChange={(e) => onFilter(filter.source, e.target.value)}
        />
      </Popover>
    </div>
  );
};

export default SearchFilter;
