import {
  Button,
  DateInput,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
  useTranslate,
  NumberInput,
  BooleanInput,
  minValue,
} from 'react-admin';
import { InputContainer } from '../../components/common';
import { CustomEdit, EditForm } from '../../components/edit';
import { ClientSelect } from './ClientSelect';

const LicensesEdit = (props) => {
  const translate = useTranslate();
  const download = async () => {
    try {
      const result = await fetch(
        `${process.env.REACT_APP_API}/licenses/download/${props.id}`,
        {
          method: 'GET',
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        }
      );
      const data = await result.json();
      alert(
        `This is your license key to activate your installation file, please save it, this will not show again: \n\n${data.fileKey}`
      );
      const element = document.createElement('a');
      const file = new Blob([data.cryptData], {
        type: 'text/plain',
      });
      element.href = URL.createObjectURL(file);
      element.download = 'license.txt';
      document.body.appendChild(element);
      element.click();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <CustomEdit
        mutationMode="pessimistic"
        // eslint-disable-next-line no-unused-vars
        transform={({ files, ...data }) => data}
        {...props}
      >
        <EditForm
          variant="outlined"
          ressetable
          titleProps={{
            subsectionTitle: translate(
              'resources.licenses.breadcrumb.editTitle'
            ),
          }}
        >
          <ClientSelect />
          <InputContainer fullWidth={false}>
            <DateInput source="validFrom" />
            <DateInput source="validUntil" />
          </InputContainer>
          <SelectInput
            source="status"
            choices={[
              { id: 'ACTIVE', name: 'Active' },
              { id: 'EXPIRED', name: 'Expired' },
              { id: 'PENDING', name: 'Pending' },
            ]}
            validate={[required()]}
          />
          <TextInput source="sysCode" disabled />
          <TextInput source="registerCode" disabled />
          <TextInput source="ivCode" disabled />
          <ReferenceInput source="LicenseTypeId" reference="licenseTypes">
            <SelectInput optionText="name" validate={required()} />
          </ReferenceInput>
          <NumberInput
            source="lockCapacity"
            min={0}
            validate={[required(), minValue(0)]}
          />
          <BooleanInput source="isOnPremise" defaultValue={false} />
          <Button label="Download" onClick={download} />
        </EditForm>
      </CustomEdit>
    </>
  );
};

export default LicensesEdit;
