import ClientList from './list';
import ClientEdit from './edit';
import ClientCreate from './create';

const clientResource = {
  list: ClientList,
  edit: ClientEdit,
  create: ClientCreate,
};

export default clientResource;
