import { Toolbar as RaToolbar, SaveButton, useTranslate } from 'react-admin';
import { styled } from '@material-ui/styles';
import { Fragment } from 'react';
import { CancelButton } from '../common';

const ToolBar = styled(RaToolbar)({
  backgroundColor: 'inherit',
  paddingLeft: 16,
  '& > * + *': {
    marginLeft: 16,
  },
});

const CreateToolbar = ({
  saveLabel,
  onCancel,
  withoutCancel,
  redirect = true,
  transform,
  cancelRedirect = true,
  loading,
  onSave,
  ...props
}) => {
  const translate = useTranslate();
  return (
    <ToolBar {...props}>
      {!withoutCancel && (
        <CancelButton
          style={{ width: 74 }}
          redirect={cancelRedirect}
          onCancel={onCancel}
        />
      )}
      <SaveButton
        disabled={loading}
        transform={transform}
        redirect={redirect}
        onSave={onSave}
        disableElevation
        label={saveLabel || translate('buttons.create')}
        variant="contained"
        icon={<Fragment />}
        color="primary"
      />
    </ToolBar>
  );
};

export default CreateToolbar;
