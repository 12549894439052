import defaultMUITheme from '@material-ui/core/styles/defaultTheme';
const RaSidebar = (palette) => ({
  drawerPaper: {
    backgroundColor: palette.common.white,
    [defaultMUITheme.breakpoints.only('xs')]: {
      backgroundColor: palette.common.white,
    },
    color: palette.primary.main,
    height: '100%',
  },
  root: {
    height: 'auto',
  },
});

export default RaSidebar;
