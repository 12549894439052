import { fetchUtils, HttpError } from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';

export const httpClient = async (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchJson(url, options);
};

const fetchJson = (url, options = {}) => {
  const requestHeaders = fetchUtils.createHeadersFromOptions(options);
  return fetch(url.replace('|', '%7c'), { ...options, headers: requestHeaders })
    .then((response) =>
      response.text().then((text) => ({
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        body: text,
      }))
    )
    .then(({ status, statusText, headers, body }) => {
      let json;
      try {
        json = JSON.parse(body);
      } catch (e) {
        // not json, no big deal
      }
      if (status < 200 || status >= 300) {
        let errorMessage;
        if (json?.message) errorMessage = json.message;
        if (json?.errors?.[0]?.message) errorMessage = json.errors[0].message;

        return Promise.reject(
          new HttpError(errorMessage || statusText, status, json)
        );
      }
      return Promise.resolve({ status, headers, body, json });
    });
};

export default jsonServerProvider(process.env.REACT_APP_API, httpClient);
