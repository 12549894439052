import { useEffect, useState } from 'react';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

const choices = [
  { id: 'STRING', name: 'String' },
  { id: 'TEXT', name: 'Text' },
  { id: 'SELECTOR', name: 'Selector' },
  { id: 'NUMERIC', name: 'Numeric' },
  { id: 'GROUPED', name: 'Grouped' },
  { id: 'PICTURE', name: 'Picture' },
  { id: 'MULTIPLE PICTURES', name: 'Multiple Pictures' },
  { id: 'ATTACHMENT', name: 'Attachment' },
  {
    id: 'MULTIPLE ATTACHMENT',
    name: 'Multiple Attachment',
  },
  { id: 'CALCULATE VALUE', name: 'Calculate value' },
];

function SurveyTemplateQuestionsCustom({
  question,
  deleteQuestion,
  handleOnChange,
}) {
  const [type, setType] = useState('');

  useEffect(() => {
    setType(question.type);
  }, []);

  const dateParser = (v) => {
    const match = /(\d{4})-(\d{2})-(\d{2})/.exec(v);
    if (match === null) return;
    const d = new Date(match[1], parseInt(match[2], 10) - 1, match[3]);
    if (isNaN(d)) return;
    return d;
  };

  const handleChange = (event) => {
    setType(event.target.value);
    handleOnChange(event.target.value, 'type', question.id);
  };

  return (
    <TableContainer>
      <Table style={{ width: 'auto', tableLayout: 'auto' }}>
        <TableBody>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell>
              <Grid container key={question.id} sx={{ padding: 1 }}>
                <Grid item xs={7} sx={{ paddingRight: 1 }}>
                  <TextField
                    id="outlined-code-input"
                    label="Code"
                    defaultValue={question.code}
                    fullWidth
                    sx={{ padding: 1 }}
                    onChange={(event) =>
                      handleOnChange(event.target.value, 'code', question.id)
                    }
                  />
                </Grid>
                <Grid item xs={5} sx={{ paddingLeft: 1 }}>
                  <TextField
                    required
                    id="outlined-required"
                    label="Order"
                    defaultValue={question.order}
                    fullWidth
                    sx={{ padding: 1 }}
                    onChange={(event) =>
                      handleOnChange(event.target.value, 'order', question.id)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-condition-input"
                    label="Condition"
                    defaultValue={question.condition}
                    fullWidth
                    sx={{ padding: 1 }}
                    onChange={(event) =>
                      handleOnChange(
                        event.target.value,
                        'condition',
                        question.id
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-questions-input"
                    label="Questions (Rich Text field)"
                    defaultValue={question.question}
                    fullWidth
                    sx={{ padding: 1 }}
                    onChange={(event) =>
                      handleOnChange(
                        event.target.value,
                        'question',
                        question.id
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-tootip-input"
                    label="ToolTip"
                    defaultValue={question.tooltip}
                    fullWidth
                    sx={{ padding: 1 }}
                    onChange={(event) =>
                      handleOnChange(event.target.value, 'tooltip', question.id)
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth sx={{ padding: 1 }} size="small">
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={type}
                      defaultValue={type}
                      onChange={handleChange}
                      fullWidth
                      sx={{ padding: 1 }}
                    >
                      {choices.map((type) => (
                        <MenuItem value={type.id} key={type.id}>
                          {type.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-password-input"
                    label="Type Properties"
                    defaultValue={question.typeProperties}
                    fullWidth
                    sx={{ padding: 1 }}
                    parse={dateParser}
                    onChange={(event) =>
                      handleOnChange(
                        event.target.value,
                        'typeProperties',
                        question.id
                      )
                    }
                  />
                </Grid>
              </Grid>
            </TableCell>
            <TableCell
              sx={{
                padding: 0,
                width: '0px',
              }}
            >
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <DeleteOutlineOutlinedIcon
                  sx={{ padding: 1, paddingRight: 2.5, cursor: 'pointer' }}
                  onClick={() => deleteQuestion(question.id)}
                />
              </div>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default SurveyTemplateQuestionsCustom;
