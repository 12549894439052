import { List, TextField, Pagination, useTranslate } from 'react-admin';
import {
  Datagrid,
  ListCreator,
  CreateButton,
  ListToolbar,
  Empty,
} from '../../components/list';
import Filter from '../../components/filter';
import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions';
import UrlField from '../../components/common/UrlField';

const VersionActions = [<CreateButton key={1} />];

const VersionList = (props) => {
  const translate = useTranslate();
  const root = 'resources.versions';
  return (
    <List
      actions={false}
      pagination={
        <Pagination actions={TablePaginationActions} rowsPerPageOptions={[]} />
      }
      empty={
        <Empty actions={VersionActions} title={translate(`${root}.title`)} />
      }
      {...props}
    >
      <>
        <ListCreator
          actions={VersionActions}
          title={translate(`${root}.title`)}
        />
        <ListToolbar actions={<Filter />} />
        <Datagrid>
          <UrlField source="id" urlPrefix="versions" />
          <TextField
            source="name"
            style={{ margin: '10px 0px', display: 'inline-block' }}
          />{' '}
          {/* Trick to make the row have the correct height */}
          <TextField source="description" />
        </Datagrid>
      </>
    </List>
  );
};

export default VersionList;
