import SurveyTemplateList from './list';
import SurveyTemplateCreate from './create';
import SurveyTemplateEdit from './edit';

const surveyTemplateResource = {
  list: SurveyTemplateList,
  create: SurveyTemplateCreate,
  edit: SurveyTemplateEdit,
};

export default surveyTemplateResource;
