import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import IconButton from '@material-ui/core/IconButton';
import { useTranslate } from 'ra-core';

const useStyles = makeStyles(
  (theme) => ({
    removeButton: {
      width: 36,
      height: 36,
      backgroundColor: theme.palette.red.main,
      '&:hover': { backgroundColor: theme.palette.red.main },
    },
    removeIcon: {
      color: 'white',
      width: 36,
      height: 36,
    },
  }),
  { name: 'RaFileInputPreview' }
);

const FileInputPreview = (props) => {
  const {
    children,
    classes: classesOverride, // eslint-disable-line no-unused-vars
    className,
    onRemove,
    file,
    ...rest
  } = props;
  const classes = useStyles(props);
  const translate = useTranslate();

  useEffect(() => {
    return () => {
      const preview = file.rawFile ? file.rawFile.preview : file.preview;

      if (preview) {
        window.URL.revokeObjectURL(preview);
      }
    };
  }, [file]);

  if (!file?.rawFile && !file?.preview) return null;

  return (
    <div className={className} {...rest}>
      <IconButton
        className={classes.removeButton}
        onClick={onRemove}
        aria-label={translate('ra.action.delete')}
        title={translate('ra.action.delete')}
      >
        <DeleteOutlineIcon className={classes.removeIcon} />
      </IconButton>
      {children}
    </div>
  );
};

FileInputPreview.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  file: PropTypes.object,
  onRemove: PropTypes.func.isRequired,
};

FileInputPreview.defaultProps = {
  file: undefined,
};

export default FileInputPreview;
