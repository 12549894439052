import { Grid } from '@mui/material';
import { Button, makeStyles } from '@material-ui/core';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import LocationFormInputs from '../location/LocationFormInputs';

import { ArrayInput, SimpleFormIterator, FormDataConsumer } from 'react-admin';

const useStyles = makeStyles({
  root: {
    margin: 0,
    padding: 0,
  },
});

const TransitionProps = {
  classNames: '',
  addEndListener: () => undefined,
};

export default function LocationCreateForm({ ...props }) {
  const classes = useStyles();
  return (
    <Grid container style={gridContainerStyles}>
      <Grid item xs={12} style={gridTitleStyles}>
        Locations
      </Grid>
      <Grid item xs={12} style={{ paddingLeft: 16, paddingRight: 16 }}>
        <ArrayInput
          style={{ marginTop: '0px', marginBottom: '16px' }}
          source={props.type === 'clientEdit' ? 'Locations' : 'locations'}
          label=""
        >
          <SimpleFormIterator
            classes={{
              root: classes.root,
            }}
            TransitionProps={TransitionProps}
            removeButton={
              <DeleteOutlineOutlinedIcon
                sx={{ marginTop: 13, marginLeft: 1 }}
              />
            }
            addButton={
              <span>
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  size="small"
                  inline="false"
                  disableElevation
                  color="secondary"
                  style={{ marginTop: '8px' }}
                >
                  Add location
                </Button>
              </span>
            }
          >
            <FormDataConsumer>
              {({ getSource, scopedFormData }) => {
                return (
                  <LocationFormInputs
                    getSource={getSource}
                    scopedFormData={scopedFormData}
                    {...props}
                    type={props.type}
                  />
                );
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </Grid>
    </Grid>
  );
}

const gridContainerStyles = {
  backgroundColor: '#FAFAFA',
  borderRadius: 5,
  //padding: 15,
};

const gridTitleStyles = {
  borderBottomStyle: 'solid',
  borderWidth: 'thin',
  borderColor: '#DCDCDC',
  padding: 16,
};
