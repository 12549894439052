import { CreateButton } from 'react-admin';

export default function Create() {
  return (
    <CreateButton
      variant="contained"
      color="primary"
      size="medium"
      disableElevation
    />
  );
}
