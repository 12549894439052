const RaMenuItemLink = (palette) => ({
  root: {
    borderRadius: '4px',
    height: '3.5rem',
    color: palette.text.subtitle,
  },
  icon: {
    color: 'inherit',
  },
  active: {
    borderLeftStyle: 'none',
    borderRightStyle: 'none',
    color: palette.primary.main,
  },
});

export default RaMenuItemLink;
