import {
  required,
  SelectInput,
  TextInput,
  useTranslate,
  ReferenceInput,
  email,
} from 'react-admin';
import { CustomEdit, EditForm } from '../../components/edit';
import ResetPasswordButton from './resetPasswordButton';

const editTransform = (data) => {
  const { email, firstName, lastName, status, roleId, initialRoleId } = data;
  return {
    email,
    firstName,
    lastName,
    status,
    roleId,
    initialRoleId,
  };
};

const UserEdit = (props) => {
  const translate = useTranslate();
  return (
    <>
      <CustomEdit
        mutationMode="pessimistic"
        transform={editTransform}
        {...props}
      >
        <EditForm
          variant="outlined"
          ressetable
          toolbarProps={{
            customButton: <ResetPasswordButton {...props} />,
          }}
          titleProps={{
            subsectionTitle: translate(
              'resources.adminusers.breadcrumb.editTitle'
            ),
            sectionTitle: translate('resources.adminusers.breadcrumb.section'),
            title: translate('resources.adminusers.breadcrumb.editTitle'),
          }}
        >
          <TextInput
            source="email"
            fullWidth
            validate={[required(), email()]}
          />
          <TextInput source="firstName" validate={required()} />
          <TextInput source="lastName" validate={required()} />
          <SelectInput
            source="status"
            choices={[
              { id: 'ACTIVE', name: 'choices.active' },
              { id: 'BLOCKED', name: 'choices.blocked' },
            ]}
            validate={[required()]}
          />
          <ReferenceInput source="roleId" reference="roles">
            <SelectInput optionText="name" validate={required()} />
          </ReferenceInput>
        </EditForm>
      </CustomEdit>
    </>
  );
};

export default UserEdit;
