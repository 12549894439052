import { Typography, Box, useTheme, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useResourceContext, useTranslate, useRedirect } from 'react-admin';

const useStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: theme.palette.background.default,
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
}));

export default function TitleViews({
  resource: resourceProp,
  redirect: redirectTo = 'list',
  basePath,
  isCreate = '',
  title = '',
  subTitle = '',
  withoutBreadcrumb = false,
  sectionTitle,
  subsectionTitle,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const translate = useTranslate();
  const resourceContext = useResourceContext();
  const redirect = useRedirect();
  const resource = resourceProp ?? resourceContext;
  if (!resource) return null;

  const section =
    sectionTitle ||
    withoutBreadcrumb ||
    translate(`resources.${resource}.breadcrumb.section`);
  const subsection =
    subsectionTitle ||
    withoutBreadcrumb ||
    translate(`resources.${resource}.breadcrumb.subSection`);
  const titleShow =
    title ||
    translate(
      `resources.${resource}.breadcrumb.${isCreate ? 'create' : 'edit'}Title`
    );

  const handleArrowClick = () =>
    redirect(redirectTo, basePath || `/${resource}`);
  return (
    <Box className={classes.box}>
      {!withoutBreadcrumb ? (
        <Box style={{ display: 'flex', gap: 8 }}>
          <IconButton
            style={{
              padding: 8,
            }}
            onClick={handleArrowClick}
          >
            <ArrowBackIcon />
          </IconButton>

          <Box style={{ display: 'flex' }}>
            <Typography
              style={{
                alignSelf: 'center',
                color: theme.palette.black.lighten[3],
              }}
              variant="subtitle2"
            >{`${section}`}</Typography>
            <Typography
              style={{
                alignSelf: 'center',
              }}
              variant="subtitle2"
            >
              &nbsp;{` / ${subsection}`}
            </Typography>
          </Box>
        </Box>
      ) : (
        <Typography variant="subtitle2">{subTitle}</Typography>
      )}
      <Typography variant="h5">{titleShow}</Typography>
    </Box>
  );
}
