/* eslint-disable no-unused-vars */
import { Button } from '@material-ui/core';
import { useRedirect } from 'ra-core';
import { useTranslate } from 'react-admin';

export default function ResetPasswordButton(props) {
  const { basePath, redirect, id, ...rest } = sanitizeProps(props);
  const translate = useTranslate();
  const redirectTo = useRedirect();
  const handleClick = (e) => {
    e.preventDefault();
    redirectTo(basePath + '/resetPassword/' + id);
  };
  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={handleClick}
      disableElevation
      {...rest}
    >
      {translate('buttons.changePassword')}
    </Button>
  );
}

const sanitizeProps = ({
  handleSubmit,
  submitOnEnter,
  handleSubmitWithRedirect,
  onSave,
  invalid,
  saving,
  pristine,
  ...rest
}) => rest;
