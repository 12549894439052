import MuiAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {
  Box,
  IconButton,
  makeStyles,
  useMediaQuery,
  styled,
} from '@material-ui/core';
import { toggleSidebar } from 'ra-core';
import { useDispatch } from 'react-redux';
import MenuIcon from '@material-ui/icons/Menu';
import MyUserMenu from './userMenu';

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const useStyles = makeStyles(
  (theme) => ({
    appBarOpen: {
      marginLeft: theme.sidebar.width,
      width: `calc(100% - ${theme.sidebar.width}px)`,
    },
    appBarClose: {
      marginLeft: theme.sidebar.closedWidth,
      width: `calc(100% - ${theme.sidebar.closedWidth}px)`,
    },
    toolbar: {
      paddingRight: 24,
      flex: 1,
      display: 'flex',
      justifyContent: 'space-between',
    },
    menuContainer: {
      display: 'flex',
    },
    Button: {
      marginLeft: '0.2em',
      marginRight: '0.2em',
    },
    menuButtonIconClosed: {
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: 'rotate(0deg)',
    },
    menuButtonIconOpen: {
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: 'rotate(180deg)',
    },
  }),
  { name: 'RaAppBar' }
);

const MyAppBar = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { open, logout } = props;

  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  return (
    <AppBar
      {...props}
      className={open ? classes.appBarOpen : classes.appBarClose}
    >
      <Toolbar
        disableGutters
        variant={isXSmall ? 'regular' : 'dense'}
        className={classes.toolbar}
      >
        <Box className={classes.menuContainer}>
          <IconButton
            onClick={() => dispatch(toggleSidebar())}
            className={classes.Button}
          >
            <MenuIcon
              classes={{
                root: open
                  ? classes.menuButtonIconOpen
                  : classes.menuButtonIconClosed,
              }}
              color="primary"
            />
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <MyUserMenu logout={logout} />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default MyAppBar;
