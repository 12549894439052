import {
  AutocompleteInput,
  Create,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
  useDataProvider,
  useTranslate,
} from 'react-admin';
import { FormContainer } from '../../components/common';
import TitleViews from '../../components/common/TitleViews';
import { CreateForm } from '../../components/create';
import { useForm } from 'react-final-form';

const ClientCreate = (props) => {
  const translate = useTranslate();

  return (
    <>
      <TitleViews
        title={`${translate('labels.create')} ${translate(
          'resources.clients.title'
        )}`}
        subsectionTitle={translate('resources.clients.breadcrumb.createTitle')}
      />
      <Create component={FormContainer} {...props}>
        <CreateForm variant="outlined" resettable type="client">
          <TextInput source="name" label="First Name" validate={[required()]} />
          <TextInput source="lastName" validate={[required()]} />
          <CompanySelect />
          <TextInput source="email" validate={[required()]} />
          <SelectInput
            source="status"
            choices={[
              { id: 'ACTIVE', name: 'Active' },
              { id: 'INACTIVE', name: 'Inactive' },
            ]}
            validate={[required()]}
          />
        </CreateForm>
      </Create>
    </>
  );
};

const CompanySelect = (props) => {
  const form = useForm();
  const dataProvider = useDataProvider();
  const handleCompanySelect = async (company) => {
    try {
      if (!company) return;
      form.change('companyName', company.name);
      form.change(
        'status',
        company?.status?.name === 'Active' ? 'ACTIVE' : 'INACTIVE'
      );
      form.change('externalId', company?.id);
      const { data: companyData } = await dataProvider.getOne(
        'clients/external',
        {
          id: company?.id,
        }
      );
      const defaultContact = companyData?.defaultContact;
      const sites = companyData?.sites;
      const formatSites = sites.map((site) => ({
        name: site?.name,
        address: site?.addressLine1,
        city: site?.city,
        state: site?.stateReference?.identifier,
        zipcode: site?.zip,
        country: site?.country?.identifier,
        externalId: site?.id,
      }));
      form.change('locations', formatSites);
      if (defaultContact) {
        form.change('name', defaultContact?.firstName);
        form.change('lastName', defaultContact?.lastName);
        form.change('email', defaultContact?.email);
      }
    } catch (error) {
      console.log('error', error);
    }
  };
  return (
    <ReferenceInput source="externalId" reference="clients/external" {...props}>
      <AutocompleteInput
        optionText="identifier"
        onSelect={handleCompanySelect}
      />
    </ReferenceInput>
  );
};

export default ClientCreate;
