import Grid from '@mui/material/Grid';

import {
  ArrayInput,
  useTranslate,
  SimpleFormIterator,
  FormDataConsumer,
  SelectInput,
  TextInput,
  required,
  NumberInput,
  regex,
} from 'react-admin';
import { Button } from '@material-ui/core';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

const SurveyTemplateQuestions = ({ ...props }) => {
  const translate = useTranslate();
  return (
    <Grid
      container
      sx={{
        borderStyle: 'solid',
        marginLeft: 3,
        backgroundColor: 'white',
        borderColor: '#DCDCDC',
        borderWidth: 'thin',
        borderRadius: 1,
        marginBottom: 2,
      }}
    >
      <Grid item xs={12} sx={tabsTitleStyle}>
        {translate('resources.surveytemplates.questionsTitle')}
      </Grid>
      <ArrayInput
        source={
          props.type === 'surveyTemplateEdit'
            ? props.getsource('SurveyQuestionTemplates')
            : props.getsource('questions')
        }
        label=""
      >
        <SimpleFormIterator
          removeButton={
            <div
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <DeleteOutlineOutlinedIcon
                sx={{
                  marginRight: 1,
                  marginLeft: 1,
                }}
              />
            </div>
          }
          addButton={
            <span style={{ margin: 15 }}>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                size="small"
                inline="false"
                color="secondary"
                disableElevation="true"
              >
                {translate('resources.surveytemplates.addQuestion')}
              </Button>
            </span>
          }
        >
          <FormDataConsumer>
            {({ getSource }) => {
              return (
                <Grid container>
                  <Grid item xs={8} sx={tabsRigthStyles}>
                    <TextInput
                      source={getSource('code')}
                      label="Code"
                      validate={[
                        required(),
                        regex(
                          /^[a-zA-Z_$]+[a-zA-Z0-9_$]*/,
                          'Must be a valid variable name'
                        ),
                      ]}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4} sx={tabsLeftStyles}>
                    <NumberInput
                      source={getSource('order')}
                      label="Order"
                      validate={[required()]}
                      {...props}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sx={fullWInputsStyle}>
                    <TextInput
                      source={getSource('condition')}
                      label="Condition"
                      {...props}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sx={fullWInputsStyle}>
                    <TextInput
                      source={getSource('question')}
                      label="Questions (Rich Text field)"
                      validate={[required()]}
                      {...props}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sx={fullWInputsStyle}>
                    <TextInput
                      source={getSource('tooltip')}
                      label="Tooltip"
                      {...props}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sx={fullWInputsStyle}>
                    <SelectInput
                      source={getSource('status')}
                      label="Status"
                      choices={[
                        { id: 'DRAFT', name: 'Draft' },
                        { id: 'PUBLISHED', name: 'Published' },
                      ]}
                      validate={[required()]}
                      {...props}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sx={fullWInputsStyle}>
                    <SelectInput
                      source={getSource('type')}
                      label="Type"
                      choices={[
                        { id: 'STRING', name: 'String' },
                        { id: 'TEXT', name: 'Text' },
                        { id: 'SELECTOR', name: 'Selector' },
                        { id: 'NUMERIC', name: 'Numeric' },
                        { id: 'GROUPED', name: 'Grouped' },
                        { id: 'PICTURE', name: 'Picture' },
                        { id: 'MULTIPLE PICTURES', name: 'Multiple Pictures' },
                        { id: 'ATTACHMENT', name: 'Attachment' },
                        {
                          id: 'MULTIPLE ATTACHMENT',
                          name: 'Multiple Attachment',
                        },
                        { id: 'CALCULATE VALUE', name: 'Calculate value' },
                      ]}
                      validate={[required()]}
                      {...props}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sx={fullWInputsStyle}>
                    <TextInput
                      source={getSource('typeProperties')}
                      label="Type Properties"
                      {...props}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              );
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </Grid>
  );
};

export default SurveyTemplateQuestions;

const tabsLeftStyles = {
  paddingLeft: 2,
  paddingRigth: 3,
};
const tabsRigthStyles = {
  paddingLeft: 3,
  paddingRigth: 2,
};

const tabsTitleStyle = {
  padding: 2,
  borderBottomStyle: 'solid',
  borderWidth: 'thin',
  borderColor: '#DCDCDC',
  fontWeight: 'bold',
};

const fullWInputsStyle = {
  paddingLeft: 3,
};
