import {
  ArrayInput,
  required,
  SimpleFormIterator,
  TextInput,
  useTranslate,
} from 'react-admin';
import { CustomEdit, EditForm } from '../../components/edit';
import { RemoveButton, AddButton } from '../../components/common';

const transform = (data) => {
  if (!data.files) {
    data.files = [];
  }
  return data;
};

const VersionEdit = (props) => {
  const translate = useTranslate();
  const root = 'resources.versions';
  return (
    <>
      <CustomEdit mutationMode="pessimistic" transform={transform} {...props}>
        <EditForm
          variant="outlined"
          ressetable
          titleProps={{
            subsectionTitle: translate(`${root}.breadcrumb.editTitle`),
          }}
        >
          <TextInput source="name" validate={[required()]} fullWidth />
          <TextInput
            variant="outlined"
            source="description"
            options={{ multiline: true, minRows: 4 }}
            fullWidth={true}
            resettable
          />
          <ArrayInput source="files">
            <SimpleFormIterator
              disableReordering
              getItemLabel={() => ''}
              addButton={<AddButton />}
              removeButton={<RemoveButton />}
            >
              <TextInput source="fileName" />
              <TextInput source="url" />
              <TextInput source="size" type="number" />
              <TextInput source="checkSum" />
            </SimpleFormIterator>
          </ArrayInput>
        </EditForm>
      </CustomEdit>
    </>
  );
};

export default VersionEdit;
