const MuiAlert = (palette) => ({
  message: {
    alignSelf: 'center',
  },
  standardError: {
    backgroundColor: palette.red.lighten[0],
    '& .MuiAlert-icon': {
      color: palette.red.main,
    },
  },
  standardInfo: {
    backgroundColor: palette.primary.lighten[1],
    '& .MuiAlert-icon': {
      color: palette.primary.main,
    },
  },
  standardSuccess: {
    backgroundColor: palette.green.lighten[4],
    '& .MuiAlert-icon': {
      color: palette.green.main,
    },
  },
  standardWarning: {
    backgroundColor: palette.alert.lighten[0],
    '& .MuiAlert-icon': {
      color: palette.alert.main,
    },
  },
});

export default MuiAlert;
