import Autocomplete from '@mui/material/Autocomplete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { SearchText } from './styles';

const SelectFilter = ({ filter, onFilter }) => (
  <Autocomplete
    options={filter.choices}
    onChange={(_, n) => onFilter(filter.source, n.id)}
    renderInput={(props) => (
      <div ref={props.InputProps.ref}>
        <SearchText {...props.inputProps}>
          {filter.label}{' '}
          <KeyboardArrowDownIcon style={{ paddingLeft: '16px' }} />
        </SearchText>
      </div>
    )}
  />
);

export default SelectFilter;
