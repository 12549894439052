import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useTranslate } from 'react-admin';

export default function EditProfileButton() {
  const translate = useTranslate();
  return (
    <Button
      component={Link}
      to="/profile"
      label="Edit Profile"
      variant="contained"
      color="primary"
      size="medium"
      disableElevation
    >
      {translate('buttons.editProfile')}
    </Button>
  );
}
