export const typography = {
  h1: {
    fontWeight: 400,
    fontSize: 57,
    letterSpacing: '0.5px',
    lineHeight: '60px',
  },
  h2: {
    fontSize: 21.3,
    fontWeight: 500,
    lineHeight: '18.75px',
    letterSpacing: '0.01px',
  },
  h3: {
    fontWeight: 400,
    fontSize: '3rem',
    lineHeight: '50px',
  },
  h4: {
    fontWeight: 500,
    fontStyle: 'normal',
    fontSize: 34,
    letterSpacing: '0.25px',
    lineHeight: '32px',
  },
  h5: {
    fontWeight: 700,
    fontSize: 24,
    letterSpacing: '0.01px',
  },
  h6: {
    fontWeight: 400,
    fontSize: 20,
    fontStyle: 'normal',
    letterSpacing: '0.25px',
    lineHeight: '32px',
  },
  button: {
    fontWeight: 500,
    fontSize: 16,
    textTransform: 'inherit',
  },
  overline: {
    fontWeight: 500,
  },
  body1: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '18.75px',
    letterSpacing: '0.01px',
  },
  body2: {
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    color: '#474747',
    letterSpacing: '0.25px',
    textAlign: 'left',
  },
  caption: {
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 400,
    letterSpacing: '0.4px',
  },
  subtitle2: {
    fontWeight: 400,
    fontSize: 14,
    letterSpacing: '0.01px',
  },
  thead: {
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '18.75px',
    letterSpacing: '0.01px',
    textAlign: 'left',
    color: '#474747',
  },
};
