import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Notification as RaNotification } from 'react-admin';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import {
  getNotification,
  undo,
  undoableEventEmitter,
  useTranslate,
} from 'ra-core';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  undo: {
    color: theme.palette.black.lighten[4],
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

const Notification = (props) => {
  const styles = useStyles(props);
  const translate = useTranslate();
  const notification = useSelector(getNotification);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleUndo = useCallback(() => {
    dispatch(undo());
    undoableEventEmitter.emit('end', { isUndo: true });
  }, [dispatch]);

  useEffect(() => {
    setOpen(!!notification);
  }, [notification]);

  const handleRequestClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  if (!notification) return null;

  const Action = notification.undoable ? (
    <Button
      color="primary"
      className={styles.undo}
      variant="text"
      size="medium"
      onClick={handleUndo}
    >
      {translate('ra.action.undo')}
    </Button>
  ) : null;
  const typeStyle = notification.type || props.type;

  return (
    <RaNotification
      {...props}
      action={Action}
      open={open}
      onClose={handleRequestClose}
      style={
        props.contained
          ? {
              left: 'inherit',
              right: 'inherit',
              transform: 'none',
            }
          : {}
      }
    >
      <Alert
        action={Action}
        onClose={handleRequestClose}
        iconMapping={{
          error: <ErrorOutlineIcon />,
          info: <InfoOutlinedIcon />,
          success: <CheckIcon />,
          warning: <ReportProblemOutlinedIcon />,
        }}
        severity={typeStyle}
      >
        <Typography variant="body2">
          {notification.message &&
            translate(notification.message, notification.messageArgs)}
        </Typography>
      </Alert>
    </RaNotification>
  );
};

Notification.propTypes = {
  type: PropTypes.string,
  autoHideDuration: PropTypes.number,
  multiLine: PropTypes.bool,
};

Notification.defaultProps = {
  type: 'info',
  autoHideDuration: 4000,
  multiLine: false,
};

export default Notification;
