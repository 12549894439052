import { useTranslate } from 'react-admin';
import { Button, IconButton, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';

const useStyles = makeStyles((theme) => ({
  deleteIcon: {
    color: theme.palette.black.main,
  },
}));

export const AddButton = ({ label = 'ra.action.add', ...props }) => {
  const translate = useTranslate();
  const theme = useTheme();
  return (
    <Button
      startIcon={<AddIcon />}
      style={{
        color: theme.palette.secondary.main,
        background: theme.palette.primary.lighten[1],
      }}
      {...props}
    >
      {translate(label)}
    </Button>
  );
};

export const RemoveButton = (props) => {
  const classes = useStyles();
  return (
    <IconButton {...props} size="medium" className={classes.deleteIcon}>
      {' '}
      <DeleteIcon />{' '}
    </IconButton>
  );
};
