import { useEffect } from 'react';
import { Admin, Resource } from 'react-admin';
import { authProvider, dataProvider, i18nProvider } from './providers';
import customTheme from './themes/customTheme';
import { raLayout } from './components/layout';
import LoginPage from './components/login';
import clientResource from './resources/clients';
import surveyResource from './resources/surveys';
import licenseResource from './resources/licenses';
import licenseTypeResource from './resources/licenseTypes';
import adminuserResource from './resources/adminusers';
import productResource from './resources/products';
import versionsResource from './resources/versions';
import devicesResource from './resources/devices';
import surveysResource from './resources/surveys';
import customRoutes from './customRoutes';
import surveyTemplateResource from './resources/surveyTemplates';
import apiKeyResource from './resources/apiKeys';
import { logout as logoutAuth0 } from './utils/auth0';

function App() {
  useEffect(() => {
    const logout = () => {
      localStorage.clear();
      logoutAuth0();
    };

    const interval = setInterval(() => {
      const token = localStorage.getItem('token');
      if (token) {
        const isTokenExpired = () =>
          Date.now() >= JSON.parse(atob(token.split('.')[1])).exp * 1000;
        isTokenExpired() && logout();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Admin
      layout={raLayout}
      theme={customTheme}
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      loginPage={LoginPage}
      customRoutes={customRoutes}
    >
      <Resource
        name="surveys"
        list={surveyResource.list}
        edit={surveyResource.edit}
        create={surveyResource.create}
      />
      <Resource
        name="licenses"
        list={licenseResource.list}
        edit={licenseResource.edit}
        create={licenseResource.create}
      />

      <Resource
        name="licenseTypes"
        list={licenseTypeResource.list}
        edit={licenseTypeResource.edit}
        create={licenseTypeResource.create}
      />

      <Resource
        name="clients"
        list={clientResource.list}
        edit={clientResource.edit}
        create={clientResource.create}
      />

      <Resource name="locations" />
      <Resource name="clients/external" />

      <Resource
        name="surveytemplates"
        list={surveyTemplateResource.list}
        create={surveyTemplateResource.create}
        edit={surveyTemplateResource.edit}
      />

      <Resource
        name="users"
        list={adminuserResource.list}
        edit={adminuserResource.edit}
        create={adminuserResource.create}
      />

      <Resource
        name="products"
        list={productResource.list}
        edit={productResource.edit}
        create={productResource.create}
      />

      <Resource
        name="versions"
        list={versionsResource.list}
        edit={versionsResource.edit}
        create={versionsResource.create}
      />

      <Resource
        name="devices"
        list={devicesResource.list}
        edit={devicesResource.edit}
      />

      <Resource name="surveys" list={surveysResource.list} />

      <Resource
        name="apikeys"
        list={apiKeyResource.list}
        create={apiKeyResource.create}
        edit={apiKeyResource.edit}
      />

      <Resource name="roles" />
    </Admin>
  );
}

export default App;
