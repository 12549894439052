import { Link as ReactRouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Button,
  sanitizeFieldRestProps,
  useRecordContext,
  Link,
} from 'react-admin';

const UrlField = (props) => {
  const {
    source,
    urlPrefix,
    queryParam,
    text,
    isButton,
    transformTo,
    ...rest
  } = props;
  const record = useRecordContext();
  const value = text || (record && record[source]);
  const id = record && record.id;
  const linkHref = queryParam
    ? `/${urlPrefix}?${queryParam}=${record?.[source]}`
    : `/${urlPrefix}/${id}`;
  if (value == null) {
    return null;
  }

  const stopPropagation = (e) => e.stopPropagation();
  if (isButton)
    return (
      <Button
        component={ReactRouterLink}
        to={transformTo ? transformTo(record) : linkHref}
        onClick={stopPropagation}
        {...rest}
      >
        <>{value}</>
      </Button>
    );
  return (
    <Link
      to={transformTo ? transformTo(record) : linkHref}
      {...sanitizeFieldRestProps(rest)}
    >
      {value}
    </Link>
  );
};

UrlField.defaultProps = {
  addLabel: true,
  urlPrefix: '',
};

UrlField.propTypes = {
  addLabel: PropTypes.bool,
  sortBy: PropTypes.string,
  sortByOrder: PropTypes.oneOf(['ASC', 'DESC']),
  source: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  sortable: PropTypes.bool,
  className: PropTypes.string,
  cellClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  textAlign: PropTypes.oneOf(['inherit', 'left', 'center', 'right', 'justify']),
  emptyText: PropTypes.string,
  urlPrefix: PropTypes.string,
  queryParam: PropTypes.string,
  text: PropTypes.string,
};
UrlField.displayName = 'UrlField';

export default UrlField;
