import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {
  ArrayInput,
  FormDataConsumer,
  SimpleFormIterator,
  useTranslate,
} from 'react-admin';
import { Divider } from '../list';
import { Button, IconButton } from '@material-ui/core';
import { InputContainer } from '../common';
import { Question, questionTypes } from './Question';
import _ from 'lodash';

export default function AddDoorsButton(props) {
  const translate = useTranslate();
  if (props.disabled) return null;
  const { tabQuestions, typeProperties } = props;

  const questionsToShow = tabQuestions?.filter(
    (question) =>
      question?.typeProperties?.noSecondary !== true &&
      question?.code !== props.code
  );

  const questionCodes = tabQuestions?.map((question) => question?.code);

  const showConditionally = (index, currentSource) => {
    const currentQuestion = questionsToShow[index];
    const previousQuestion = questionsToShow[index - 1];
    // Show the fisrt question always
    if (!previousQuestion) return true;
    const currentAnswer = _.get(
      props.record,
      currentSource + currentQuestion.code
    );
    const previousAnswer = _.get(
      props.record,
      currentSource + previousQuestion.code
    );
    let previousAnswerIsFilled = false;
    const source = currentSource.replace('answers.', '').replace('.', '?.');
    const condition = currentQuestion?.condition;
    // Replace the question codes with the current source + the question code
    const formattedCondition = condition?.replace(
      new RegExp(questionCodes.join('|'), 'g'),
      (match) => `${source}${match}`
    );

    const type = currentQuestion?.type;

    window.isConditionSatisfied = true;
    const setCondition = `
      try {
        window.isConditionSatisfied = ${formattedCondition};
      } catch(e) {
        window.isConditionSatisfied = false;
      }
  `;
    if (formattedCondition) {
      try {
        // eslint-disable-next-line no-eval
        eval(setCondition);
      } catch (e) {
        console.log({ e, condition, code: currentQuestion.code });
      }
    }

    const isFillableType = type in questionTypes;
    if (window.isConditionSatisfied) {
      if (typeof currentAnswer === 'object') {
        if (currentAnswer === null) {
          return false;
        }
        const subAnswers = Object.values(currentAnswer);
        return subAnswers.some((answer) => answer !== '');
      }
      if (currentAnswer) return true;
    }
    if (typeof previousAnswer === 'object') {
      if (previousAnswer === null) {
        return true;
      }
      const subAnswers = Object.values(previousAnswer);
      previousAnswerIsFilled = subAnswers.some((answer) => answer !== '');
    } else {
      previousAnswerIsFilled = !!previousAnswer;
    }
    currentQuestion.renderNext =
      (previousAnswerIsFilled || previousQuestion.renderNext) &&
      (!window.isConditionSatisfied || !isFillableType);
    if (previousQuestion.renderNext && window.isConditionSatisfied) return true;
    return previousAnswerIsFilled && window.isConditionSatisfied;
  };

  return (
    <>
      <Divider style={{ marginBottom: 0 }} />
      <ArrayInput
        source={`answers.${props.code}`}
        label=""
        style={{ marginTop: '-10px' }}
      >
        <SimpleFormIterator
          disableReordering={true}
          addButton={
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              size="small"
              inline="false"
              color="secondary"
              disableElevation="true"
            >
              {typeProperties?.isBuilding
                ? translate('resources.surveys.addBuilding')
                : translate('resources.surveys.addNonstandardDoor')}
            </Button>
          }
          removeButton={
            <div
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <IconButton
                variant="contained"
                size="small"
                inline="false"
                color="secondary"
                onClick={() => setTimeout(() => props.updateAllSurvey(), 3000)}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          }
        >
          <InputContainer columns={1}>
            <FormDataConsumer>
              {({ getSource }) =>
                questionsToShow.map(
                  (answer, index) =>
                    showConditionally(index, getSource('')) && (
                      <Question
                        {...props}
                        {...answer}
                        code={getSource(answer.code).replace('answers.', '')}
                        key={answer.code}
                        saveGlobally
                      />
                    )
                )
              }
            </FormDataConsumer>
          </InputContainer>
        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
}
