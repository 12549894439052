import { makeStyles } from '@material-ui/core/styles';

import FileInput from './FileInput';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      '& > div': {
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
        overflow: 'hidden',
        alignItems: 'center',
        marginBottom: 20,
      },
    },
    dropZone: {
      background: theme.palette.background.default,
      cursor: 'pointer',
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.getContrastText(theme.palette.background.default),
    },
    preview: {
      display: 'inline-block',
    },
    removeButton: {
      display: 'inline-block',
      position: 'relative',
      float: 'left',
      '& button': {
        position: 'absolute',
        top: 14,
        right: -5,
        minWidth: theme.spacing(2),
      },
    },
  }),
  { name: 'RaImageInput' }
);

const ImageInput = (props) => {
  const classes = useStyles(props);
  const propClasses = props.classes || {};
  return (
    <FileInput
      labelMultiple="ra.input.image.upload_several"
      labelSingle="ra.input.image.upload_single"
      {...props}
      classes={{ ...classes, ...propClasses }}
    />
  );
};

export default ImageInput;
