import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';

import { TextInput, required, AutocompleteInput } from 'react-admin';
import { Country, State, City } from 'country-state-city';

const LocationFormInputs = ({ ...props }) => {
  const [countryCode, setCountryCode] = useState();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState();
  const [cities, setCities] = useState();

  useEffect(() => {
    const newCountries = Country.getAllCountries();
    setCountries(newCountries);
    if (props.scopedFormData?.country) {
      const states = State.getStatesOfCountry(props.scopedFormData?.country);
      setStates(states);
      setCountryCode(props.scopedFormData?.country);
      if (props.scopedFormData.state) {
        setCities(
          City.getCitiesOfState(
            props.scopedFormData?.country,
            props.scopedFormData?.state
          )
        );
      }
    }
  }, [props.scopedFormData]);

  const getStatesByCountry = (code) => {
    console.log('name--->', code);
    setStates(State.getStatesOfCountry(code));
    setCountryCode(code);
  };

  const getCitiesByStatesAndCountry = (name) => {
    console.log(
      'get state by code and country-->',
      State.getStateByCodeAndCountry(name, countryCode)
    );
    setCities(City.getCitiesOfState(countryCode, name));
  };

  return (
    <Grid
      container
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      rowSpacing={1}
      style={{ marginTop: '8px' }}
    >
      <Grid item xs={6}>
        <TextInput
          source={props.getSource('name')}
          label="Location Name"
          validate={[required()]}
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <AutocompleteInput
          source={props.getSource('country')}
          label="Country"
          optionValue="isoCode"
          choices={countries}
          validate={[required()]}
          onChange={(event) => getStatesByCountry(event)}
          //{...props}
          variant="outlined"
          defaultValue=""
          fullWidth
          suggestionLimit={10}
        />
      </Grid>
      <Grid item xs={6}>
        <AutocompleteInput
          source={props.getSource('state')}
          label="State"
          optionValue="isoCode"
          choices={states}
          validate={[required()]}
          onChange={(event) => getCitiesByStatesAndCountry(event)}
          variant="outlined"
          defaultValue=""
          fullWidth
          suggestionLimit={10}
        />
      </Grid>
      <Grid item xs={6}>
        <AutocompleteInput
          source={props.getSource('city')}
          label="City"
          choices={cities}
          validate={[required()]}
          optionValue="name"
          variant="outlined"
          defaultValue=""
          fullWidth
          suggestionLimit={10}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          source={props.getSource('address')}
          label="Address"
          validate={[required()]}
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          label="Zip Code"
          validate={[required()]}
          source={props.getSource('zipcode')}
          variant="outlined"
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export default LocationFormInputs;
