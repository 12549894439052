import { Card } from '@material-ui/core';
import { Children, cloneElement } from 'react';

const FormContainer = ({ children, ...props }) => (
  <Card {...props} elevation={0}>
    {Children.map(children, (child) =>
      cloneElement(child, { ...child.props, variant: 'outlined' })
    )}
  </Card>
);
export default FormContainer;
