const enLocaleTranslation = {
  action: {
    unselect_all: 'Unselect all',
    select_all: 'Select all',
  },
  login: {
    error: {
      default: 'An error occurred',
      password_expired: 'Your password has expired. Please reset it.',
      incorrect: 'Invalid username or password. Try again.',
      max_attempts: 'Maximum number of attempts reached',
    },
    customer: {
      title: 'Welcome to our customer portal!',
      subtitle: 'Log in to your account.',
    },
    title: 'Welcome to license module',
    subtitle: 'Please login to continue',
    forgotPassword: 'Forgot password?',
    noAccount: 'Don\'t have an account?',
    signUp: 'Sign up',
    fields: {
      username: {
        label: 'Username',
      },
      password: {
        label: 'Password',
      },
    },
  },
  profile: {
    change_password: 'Change password',
    edit: 'Edit Profile',
    subtitle: 'My Licenses',
    fields: {
      oldPassword: 'Old Password',
      newPassword: 'New Password',
      newPasswordConfirm: 'Confirm New Password',
    },
    error: {
      password: 'Password is required',
      confirmPassword: 'Confirm password is required',
      password_invalid:
        'Password must be at least 8 characters long, contain at least one number, one letter and one special character',
      password_match: 'Passwords do not match',
      oldPassword: 'Old password is required',
      invalidOldPassword: 'Invalid old password',
    },
    notification: {
      password_changed: 'Password changed successfully',
    },
  },
  register: {
    error: {
      default: 'An error occurred',
      password_expired: 'Your password has expired. Please reset it.',
      incorrect: 'Invalid username or password. Try again.',
      username_taken: 'Username is already taken',
      username: 'Username is required',
      password: 'Password is required',
      confirmPassword: 'Confirm password is required',
      password_invalid:
        'Password must be at least 8 characters long, contain at least one number, one letter and one special character',
      password_match: 'Passwords do not match',
      email: 'Email is required',
      email_invalid: 'Email is invalid',
      email_taken: 'Email is already taken',
      firstName: 'First name is required',
      lastName: 'Last name is required',
      companyName: 'Company name is required',
      address: 'Address is required',
      city: 'City is required',
      state: 'State is required',
      zipcode: 'Zip Code is required',
      country: 'Country is required',
    },
    title: 'Create account',
    goToLogin: 'Go to login',
    createdAccount: 'Account created succesfully',
    signUp: 'Sign up',
    fields: {
      name: 'Name',
      username: 'Username',
      password: 'Password',
      confirmPassword: 'Confirm password',
      email: 'Email',
      firstName: 'First name',
      lastName: 'Last name',
      phone: 'Phone',
      company: 'Company',
      address: 'Address',
      companyName: 'Company Name',
      zipcode: 'Zip Code',
      state: 'State',
      city: 'City',
      country: 'Country',
    },
  },
  resetPassword: {
    title: 'Recover password',
    subtitle:
      'Enter the email you used to create your account to receive the instruction to recover your password.',
    resetSubtitle: 'Enter your new password',
    successMessage: 'Password succesfully recovered!',
    checkEmail: 'Check your email for more instructions.',
    updatePassword: 'Update password',
    errors: {
      default: 'An error occurred',
      invalid_code: 'Invalid code',
    },
    fields: {
      email: 'Email',
      recoverPassword: 'Recover password',
      cancel: 'Cancel',
      success: 'Password succesfully recovered!',
      checkEmail: 'Check your email for more instructions.',
      goToLogin: 'Go to login',
      password: 'Password',
      confirmPassword: 'Confirm Password',
      emailInvalid: 'Invalid email address',
    },
  },
  createPassword: {
    title: 'Create password',
    subtitle: 'Enter your new password',
    successMessage: 'Password succesfully recovered!',
    checkEmail: 'Check your email for more instructions.',
    errors: {
      default: 'An error occurred',
      invalid_code: 'Invalid code',
    },
    fields: {
      cancel: 'Cancel',
      success: 'Password succesfully recovered!',
      checkEmail: 'Check your email for more instructions.',
      goToLogin: 'Go to login',
      password: 'Password',
      confirmPassword: 'Confirm Password',
      emailInvalid: 'Invalid email address',
    },
  },
  empty: {
    noData: 'Sorry, no data yet',
    clickButtons: 'Click on the buttons at the top right to create new data',
  },
  resources: {
    surveys: {
      title: 'Surveys',
      fields: {
        id: 'Id',
        progress: 'Progress',
        status: 'Status',
        company: 'Company',
        companyName: 'Company name',
        locationName: 'Location Name',
        startSurvey: 'Start Survey',
        continueSurvey: 'Continue Survey',
        viewSurvey: 'View Survey',
      },
      viewSurveys: 'View Surveys',
      viewSurvey: 'View Survey',
      assignedUsers: 'Assigned users',
      addUser: 'Add User',
      createSurvey: 'Create survey',
      updatedSurvey: 'Survey updated!',
      addNonstandardDoor: 'Add Non-standard door',
      addBuilding: 'Add Building',
      noAvailable: 'You don’t have surveys available.',
      breadcrumb: {
        editTitle: 'Survey',
        createTitle: 'Create Survey',
        section: 'Surveys',
      },
    },
    clients: {
      title: 'Client',
      fields: {
        id: 'Id',
        name: 'Name',
        lastName: 'Last Name',
        city: 'City',
        country: 'Country',
        state: 'State',
        address: 'Address',
        zipcode: 'Zip Code',
        externalId: 'Company',
        companyName: 'Company Name',
        email: 'Email',
        status: 'Status',
        viewLicenses: 'View Licenses',
      },
      breadcrumb: {
        editTitle: 'Edit Client',
        createTitle: 'Create Client',
        section: 'Clients',
      },
      sync: 'Client locations synchronized successfully',
      buttons: {
        sync: 'Sync locations',
      },
    },
    locations: {
      title: 'Locations',
      viewLocations: 'View Locations',
      viewLocation: 'View Location',
    },
    surveytemplates: {
      title: 'Survey Templates',
      viewSurveys: 'View Survey Templates',
      viewSurvey: 'View Survey Template',
      createSurvey: 'Create Survey Template',
      tabsTitle: 'Tabs',
      addTab: 'Add Tab',
      addQuestion: 'Add Question',
      questionsTitle: 'Questions',
      breadcrumb: {
        editTitle: 'Edit Survey Template',
        createTitle: 'Create Survey Template',
        section: 'Survey Templates',
      },
    },
    licenses: {
      title: 'Licenses',
      viewLicenses: 'View Licenses',
      viewLicense: 'View License',
      noAvailable: 'You don’t have licenses available',
      fields: {
        id: 'Id',
        clientId: 'Client',
        validFrom: 'Valid From',
        validUntil: 'Valid Until',
        status: 'Status',
        sysCode: 'Sys Code',
        ivCode: 'Key B',
        registerCode: 'Key A',
        myLicenses: 'My Licenses',
        download: 'Download',
        serverLicenseKey: 'Server License Key',
        serverInstaller: 'Server Installer',
        encoderInstaller: 'Encoder Installer',
        encoderDrivers: 'Encoder Drivers',
        viewDevices: 'View Devices',
        lockCapacity: 'Lock Capacity',
        isOnPremise: 'Is On Premise?',
      },
      breadcrumb: {
        editTitle: 'Edit License',
        createTitle: 'Create License',
        viewTitle: 'View License',
        section: 'Licenses',
      },
    },
    licenseTypes: {
      title: 'License Types',
      breadcrumb: {
        section: 'License Types',
        createTitle: 'Create License Type',
        editTitle: 'Edit License Type',
        viewTitle: 'View License Type',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        description: 'Description',
        productIds: 'Products',
      },
      features: {
        ONLINE_MODE: 'Online Mode',
        MOBILE_KEYS: 'Mobile Keys',
        SERVICE_REQUEST: 'Service Requests',
        APPLE_WALLET: 'Apple Wallet',
      },
    },
    devices: {
      title: 'Devices',
      fields: {
        id: '',
        name: 'Unique Identifier',
        hardwareVersion: 'Hardware Version',
        softwareVersion: 'Software Version',
        dateFirstActivation: 'Date First Activation',
        LicenseId: 'License Number',
        viewDetails: 'View Details',
        dateFirstActivation__lte: 'Until',
        dateFirstActivation__gte: 'From',
        License: 'License',
        InstalationDetails: 'Installation Details',
        dateActivation: 'Date Activation',
        instalationName: 'Installation Name',
        location: 'Location',
        clientId: 'Client',
        type: 'Type',
      },
      breadcrumb: {
        viewTitle: 'View Device',
        section: 'Devices',
      },
    },
    products: {
      title: 'Products',
      breadcrumb: {
        section: 'Products',
        createTitle: 'Create Product',
        editTitle: 'Edit Product',
        viewTitle: 'View Product',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        description: 'Description',
        latestVersion: 'Latest Version',
      },
    },
    versions: {
      title: 'Versions',
      breadcrumb: {
        section: 'Versions',
        createTitle: 'Create Version',
        editTitle: 'Edit Version',
        viewTitle: 'View Version',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        description: 'Description',
        fileName: 'File Name',
        url: 'Url',
        size: 'Expected size',
        checkSum: 'CheckSum',
        date: 'Date',
      },
    },
    settings: {
      title: 'Settings',
    },
    adminusers: {
      title: 'Admin Users',
      fields: {
        id: 'Id',
        username: 'Username',
        firstName: 'First Name',
        lastName: 'Last Name',
        status: 'Status',
        rolesIds: 'Roles',
      },
      breadcrumb: {
        editTitle: 'Edit Admin User',
        createTitle: 'Create Admin User',
        section: 'Admin Users',
      },
    },
    apikeys: {
      title: 'API Keys',
      breadcrumb: {
        section: 'API Keys',
        createTitle: 'Create API Key',
        editTitle: 'Edit API Key',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        roleId: 'Role',
        apikey: 'API Key',
        actions: 'Actions',
      },
      button: 'Reset API Key',
      success: 'API Key reseted successfully',
      show: 'Your API Key is:',
      copy: 'API Key copied',
      reset: 'You will overwrite the API key. Are you sure?',
      error: {
        create: 'Error creating API Key',
        exist: 'API Key already exists, please choose another name',
      },
    },
  },
  choices: {
    available: 'Available',
    unavailable: 'Unavailable',
    assigned: 'Assigned',
    active: 'Active',
    inactive: 'Inactive',
    outOfService: 'Out of Service',
    occupied: 'Occupied',
    standard: 'Standard',
    office: 'Office',
    toggle: 'Toggle',
    created: 'Created',
    checkedIn: 'Checked-In',
    checkedOut: 'Checked-Out',
    cancelled: 'Cancelled',
    opened: 'Opened',
    closed: 'Closed',
    openedFromCard: 'Opened from card',
    openedRemotely: 'Opened remotely',
    access: 'Access',
    accessDenied: 'Access denied',
    english: 'English',
    spanish: 'Spanish',
    gateway: 'Gateway',
    encoder: 'Card Encoder',
    backup: 'Backup',
    auditsPurge: 'Audits Purge',
    scheduled: 'Scheduled',
    stopped: 'Stopped',
    days: 'Days',
    hours: 'Hours',
    mobile: 'Mobile',
    card: 'Card',
    both: 'Both',
    blocked: 'Blocked',
    expired: 'Expired',
    pending: 'Pending',
    admin: 'Admin',
    superAdmin: 'Super Admin',
    customer: 'Customer',
    installation: 'Installation',
    uninstallation: 'Uninstallation',
    inProgress: 'In progress',
    completed: 'Completed',
    draf: 'Draft',
    published: 'Published',
    surveyContractor: 'Survey Contractor',
    connectWise: 'ConnectWise',
  },
  buttons: {
    noFilter: 'No filters applied',
    clearFilter: 'Clear filters',
    cancel: 'Cancel',
    create: 'Create',
    tryAgain: 'Try Again',
    close: 'Close',
    delete: 'Delete',
    no: 'No',
    changePassword: 'Change Password',
    continue: 'Continue',
    back: 'Back',
    editProfile: 'Edit Profile',
    save: 'Save',
    next: 'Next',
    previous: 'Previous',
  },
  labels: {
    edit: 'Edit',
    create: 'Create',
    save: 'Save',
  },
  errors: {
    notAvailable: 'Not available',
    noInfo: 'No Info Available',
    serverError: 'Server error, Please try again',
    passwordNotMatch: 'Password does not match',
  },
  appBar: {
    greetings: 'Hi',
  },
  notifications: {
    noNotifications: 'No Notifications',
  },
  file: {
    placeholder1: 'Drop your file to upload or',
    placeholder2: 'Select one',
  },
  tooltip: {
    defaultTitle: 'Click the info icon for additional information.',
    cancel: 'Cancel',
  },
};

export default enLocaleTranslation;
