import {
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
  useTranslate,
} from 'react-admin';
import { CustomEdit, EditForm } from '../../components/edit';

const VersionEdit = (props) => {
  const translate = useTranslate();
  const root = 'resources.versions';
  return (
    <>
      <CustomEdit mutationMode="pessimistic" {...props}>
        <EditForm
          variant="outlined"
          ressetable
          titleProps={{
            subsectionTitle: translate(`${root}.breadcrumb.editTitle`),
          }}
        >
          <TextInput source="name" validate={[required()]} />
          <ReferenceInput source="latestVersion" reference="versions">
            <SelectInput optionText="name" validate={required()} />
          </ReferenceInput>
          <TextInput
            variant="outlined"
            source="description"
            options={{ multiline: true, minRows: 4 }}
            fullWidth={true}
            resettable
          />
        </EditForm>
      </CustomEdit>
    </>
  );
};

export default VersionEdit;
