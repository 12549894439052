import { PasswordInput, required } from 'react-admin';
import { CustomEdit, EditForm } from '../../components/edit';
import { useParams } from 'react-router-dom';

const transform = (data) => {
  const { password } = data;
  return {
    password,
  };
};

const validatePassword = (values) => {
  const errors = {};
  if (values.password !== values.confirmPassword) {
    errors.password = 'errors.passwordNotMatch';
    errors.confirmPassword = 'errors.passwordNotMatch';
  }
  return errors;
};

const ResetPassword = (props) => {
  const { id } = useParams();
  return (
    <>
      <CustomEdit
        resource="users"
        basePath="/users"
        id={id}
        transform={transform}
        {...props}
      >
        <EditForm variant="outlined" ressetable validate={validatePassword}>
          <PasswordInput source="password" validate={required()} />
          <PasswordInput source="confirmPassword" validate={required()} />
        </EditForm>
      </CustomEdit>
    </>
  );
};

export default ResetPassword;
