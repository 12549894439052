import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      width: 436,
      height: 'auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 20,
      textAlign: 'center',
    },
  },
  checkIcon: {
    color: theme.palette.primary.main,
    width: 122,
    height: 122,
    padding: 30,
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100vh',
    background: 'white',
  },
  formContainer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    overflowY: 'auto',
  },
  subtitle: {
    color: theme.palette.text.subtitle,
  },
  form: {
    display: 'flex',
    width: '100%',
    maxWidth: '343px',
    flexDirection: 'column',
    gap: theme.spacing(2.5),
    '& > img': {
      objectFit: 'none',
      objectPosition: 'left',
    },
    justifyContent: 'center',
  },
  imgContainer: {
    height: '100vh',
    '& > img': {
      height: 'inherit',
    },
  },
  title: {
    textAlign: 'center',
    marginBottom: 16,
  },
  stepper: {
    padding: theme.spacing(3, 0),

    '& .MuiStepLabel-iconContainer': {
      paddingRight: 0,
    },

    '& circle': {
      color: 'white',
      fill: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
  },
  stepIcon: {
    color: theme.palette.primary.main,
    width: 40,
    height: 40,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${theme.palette.primary.main}`,
  },
  stepIconCompleted: {
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
  },
}));
