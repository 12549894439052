import UserList from './list';
import UserEdit from './edit';
import UserCreate from './create';
import ResetPassword from './resetPassword';

const userResource = {
  list: UserList,
  edit: UserEdit,
  create: UserCreate,
  resetPassword: ResetPassword,
};

export default userResource;
