import { List, TextField, Pagination, useTranslate } from 'react-admin';
import {
  Datagrid,
  ListCreator,
  CreateButton,
  ListToolbar,
  Empty,
} from '../../components/list';
import Filter from '../../components/filter';
import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions';
import UrlField from '../../components/common/UrlField';
import ClientListExpand from '../../components/list/ClientListExpand';

// eslint-disable-next-line react/jsx-key
const ClientTopActions = [<CreateButton />];

const ClientList = (props) => {
  const translate = useTranslate();
  const root = 'resources.clients.fields';
  return (
    <List
      actions={false}
      pagination={
        <Pagination actions={TablePaginationActions} rowsPerPageOptions={[]} />
      }
      empty={<Empty actions={ClientTopActions} />}
      {...props}
    >
      <>
        <ListCreator actions={ClientTopActions} />
        <ListToolbar
          actions={
            <Filter
              filters={[
                {
                  label: translate(root + '.country'),
                  source: 'country',
                  type: 'text',
                },
                {
                  label: translate(root + '.state'),
                  source: 'state',
                  type: 'text',
                },
                {
                  label: translate(root + '.city'),
                  source: 'city',
                  type: 'text',
                },
              ]}
            />
          }
        />

        <Datagrid expand={<ClientListExpand />}>
          <UrlField source="id" urlPrefix="clients" />
          <TextField source="companyName" label="Company Name" />

          <UrlField
            source="id"
            text={translate(root + '.viewLicenses')}
            label=""
            isButton
            transformTo={(record) => ({
              pathname: 'licenses',
              search: `filter=%7B"clientId"%3A${record.id}%7D`,
            })}
            sortable={false}
          />
        </Datagrid>
      </>
    </List>
  );
};

export default ClientList;
