import { Divider as MuiDivider } from '@material-ui/core';
import propTypes from 'prop-types';

export default function Divider({
  style = {},
  className = '',
  type = 'none',
  ...rest
}) {
  const styles =
    type === 'formBox'
      ? {
          marginBottom: 0,
          marginTop: '14px',
          marginLeft: '-14px',
          width: 'calc(100% + 28px)',
        }
      : {
          marginBottom: '14px',
        };

  return (
    <MuiDivider
      className={'fullWidth ' + className}
      variant="fullWidth"
      style={{ ...styles, ...style }}
      {...rest}
    />
  );
}

Divider.propTypes = {
  style: propTypes.object,
  className: propTypes.string,
  type: propTypes.oneOf(['formBox', 'none']),
};
