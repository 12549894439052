import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions';
import { List, Pagination, TextField, useTranslate } from 'react-admin';

import Filter from '../../components/filter';
import {
  CreateButton,
  Datagrid,
  EditButton,
  ListCreator,
  ListToolbar,
} from '../../components/list';
import Empty from '../../components/list/Empty';
import checkPermission from '../../utils/checkPermission';

const ApiKeyTopActions = (post) => [
  post ? <CreateButton label="Api Key" key={1} /> : <></>,
];

const ApiKeyList = (props) => {
  const translate = useTranslate();
  const editAccess = checkPermission({
    method: 'PUT',
    url: 'apikeys',
    permissions: JSON.parse(localStorage.getItem('permissions')),
  });

  return (
    <>
      <List
        actions={false}
        pagination={
          <Pagination
            actions={TablePaginationActions}
            rowsPerPageOptions={[]}
          />
        }
        empty={
          <Empty
            actions={ApiKeyTopActions(
              checkPermission({
                method: 'POST',
                url: 'apikeys',
                permissions: JSON.parse(localStorage.getItem('permissions')),
              })
            )}
            title={translate('resources.apikeys.title')}
          />
        }
        {...props}
      >
        <>
          <ListCreator
            actions={ApiKeyTopActions(
              checkPermission({
                method: 'POST',
                url: 'apikeys',
                permissions: JSON.parse(localStorage.getItem('permissions')),
              })
            )}
            title={translate('resources.apikeys.title')}
          />
          <ListToolbar actions={<Filter filters={[]} />} />
          <Datagrid>
            <TextField
              source="name"
              label={translate('resources.apikeys.fields.name')}
            />
            <TextField
              source="role"
              label={translate('resources.apikeys.fields.roleId')}
            />
            {editAccess && (
              <EditButton
                label={translate('resources.apikeys.fields.actions')}
                basePath="/apikeys"
              />
            )}
          </Datagrid>
        </>
      </List>
    </>
  );
};

export default ApiKeyList;
