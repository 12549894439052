import { ResourceContextProvider, WithPermissions } from 'react-admin';
import LicenseList from './licensesList';
import customTheme from '../../themes/customTheme';
import CustomAppBar from './CustomAppbar';
import { ThemeProvider } from '@material-ui/core';
import Notification from '../layout/notification';

export default function myLicenses() {
  return (
    <ResourceContextProvider value="licenses">
      <WithPermissions
        component={LicenseListWithLayout}
        basePath="/myLicenses"
        resource="licenses"
        syncWithLocation
      />
    </ResourceContextProvider>
  );
}

function LicenseListWithLayout(props) {
  return (
    <ThemeProvider theme={customTheme}>
      <CustomAppBar />
      <LicenseList {...props} />
      <Notification />
    </ThemeProvider>
  );
}
